/* ==========================================================================
   # ADD TO CART COMPONENTS
   ========================================================================== */

$add-to-cart-spacing-small: $spacing-small !default;
$add-to-cart-dialog-header-border: 1px solid $color-grey-light;
$add-to-cart-dialog-entries-header-border: 1px solid $color-grey-light;

app-efa-add-to-cart {
  @extend %cx-add-to-cart;

  .quantity-hint {
    color: var(--cx-color-success);
    margin-top: $add-to-cart-spacing-small;
  }

  .c-btn-add-to-cart {
    width: 100%;
    text-transform: none;
  }
}

app-add-to-cart-dialog.cx-dialog-popover-center {
  .modal-container {
    background-color: $color-white;

    .modal-header {
      max-height: 60px !important;
      justify-content: space-between;
      align-items: center;
      border-bottom: $add-to-cart-dialog-header-border;

      .modal-header-title {
        font-size: 22px;
        font-weight: $FONT-WEIGHT-BOLD;
      }

      .c-btn-close-text {
        font-size: 16px;
        font-weight: $FONT-WEIGHT-REGULAR;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    .modal-body {
      @include media-breakpoint-up(lg) {
        max-height: 200px !important;
        padding-bottom: 0;
        padding-top: 0;
      }

      .outer-container {
        @include media-breakpoint-up(lg) {
          min-width: 810px;
        }

        .table {
          th {
            font-size: 12px;

            @include media-breakpoint-up(lg) {
              padding-bottom: 5px;
              border-bottom: $add-to-cart-dialog-entries-header-border;
              padding: 12px 12px 5px 12px;
            }
          }
          @include media-breakpoint-up(lg) {
            tr{
              border-bottom: none;
            }
            td{
              padding: .75rem;
            }
            .cartItems-item{
              width: 42%;
            }
            .cartItems-total{
              width: 18%;
            }
            .cartItems-itemPriceRrp{
              width: 16%;
            }
          }
        }

        .table--responsive {
          @include media-breakpoint-down(md) {
            thead {
              display: none;
            }

            tbody {
              width: 100%;
            }

            tbody tr td {
              @include responsive-column;
            }
          }

          @include media-breakpoint-up(lg) {
            max-width: 750px;
          }
        }
      }

      @include media-breakpoint-down(md) {
        min-height: 500px;
        max-height: 100% !important;
      }

      .article-name {
        font-size: 18px;
        font-weight: $FONT-WEIGHT-BOLD;
        line-height: 26px;
      }

      .article-code {
        font-size: 16px;
        font-weight: $FONT-WEIGHT-SEMI-BOLD;
        line-height: 25px;
      }

      app-efa-item-counter.quantity-selector {
        max-height: 44px;
      }

      .price-dialog {
        font-size: 16px;
        font-weight: $FONT-WEIGHT-SEMI-BOLD;
        line-height: 25px;
      }
    }

    .modal-footer {
      border: 0;
      justify-content: right;

      .c-btn-add-to-cart {
        @include media-breakpoint-up(lg) {
          min-width: 175px;
        }

        min-width: 100%;
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      height: 100%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 830px;
    }
  }
}
