/*
 * You can add global styles to this file, and also import other style files
 * [1] import for the theme-colors, should be define before spartacus style import to avoid overwriting with root.
 * [2] import custom setting files before spartacus style to overwrite it.
 * [3] import spartacus style
 * [4] import custom style
*/

@import "styles/custom/08-theme/index"; // [1]
@import "styles/custom/01-settings/index"; // [2]

$styleVersion: 4.3;
@import '@spartacus/styles/scss/core';
@import 'styles/spartacus/app';
@import 'styles/spartacus/user';
@import '@spartacus/styles/scss/root';
@import '@spartacus/styles/scss/page-template';
@import '@spartacus/styles/scss/components';
@import '@spartacus/cart/index';
@import '@spartacus/order/index';

@import "styles/custom/index"; // [4]

// importing styling from bootstrap
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/carousel";