/* ==========================================================================
   # MINI CART COMPONENTS
   ========================================================================== */
$mini-cart-dialog-container-border: 1px solid $color-grey-light;
$mini-cart-dialog-mini-item-container-border: 1px solid $color-grey-light-super-semi;

app-efa-mini-cart {
  @extend %cx-mini-cart;

  color: var(--cx-color-primary);

  a {
    flex-direction: row;
    align-items: flex-end;
    gap: 0.75rem;
    background-color: var(--cx-color-inverse);
    font-size: $font-size-lg;
    color: currentColor;

    &:hover {
      cursor: pointer;
    }

    .cart-icon {
      display: block;
      position: relative;
      width: 1.5rem;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .count {
      position: absolute;
      top: 0;
      right: -0.5rem;

      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(1rem + 2px);
      height: calc(1rem + 2px);
      aspect-ratio: 1 / 1;

      font-size: 0.75rem;
      color: var(--cx-color-inverse);

      padding: 2px;
      border: 1px solid var(--cx-color-inverse);
      background-color: var(--cx-color-primary);
      border-radius: 50%;
    }

    cx-icon {
      &.mini-cart-dialog-toggle-icon {
        transform: rotate(180deg);
      }
    }

    @include media-breakpoint-down(md) {
      min-width: 150px;
      text-align: right;
    }

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }
  }

  .mini-cart-second-row {
    display: block;
    font-size: 1rem;
    line-height: 1.1;

    .total {
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

app-mini-cart-dialog {
  @include media-breakpoint-up(lg) {
    max-width: 570px;
    max-height: 830px;

    &.cx-dialog-popover {
      position: absolute;
      left: unset;
      margin-top: 50px;
      padding-right: 200px;
    }
  }

  .modal-container {
    background-color: $color-white;

    @include media-breakpoint-up(lg) {
      border: $mini-cart-dialog-container-border;
      min-height: 830px;
    }

    &.modal-container-flex-height {
      @include media-breakpoint-up(lg) {
        min-height: auto;

        .modal-body {
          height: auto;
        }
      }
    }

    .modal-header {
      max-height: 60px !important;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0;
      box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);

      .modal-header-title {
        font-size: 22px;
        font-weight: $FONT-WEIGHT-BOLD;
      }
    }

    .modal-body {
      scrollbar-width: thin;

      padding: 16px 15px 30px;

      ::-webkit-scrollbar {
        width: 5px;
        height: 20px;
      }

      ::-webkit-scrollbar-thumb {
        background: $color-grey-light;
      }

      @include media-breakpoint-up(lg) {
        height: 670px;
        padding-bottom: 0;
      }

      .outer-container {
        app-mini-cart-item-list {
          .text-empty-cart {
            font-weight: $FONT-WEIGHT-BOLD;
            font-size: $font-size-base;
            color: $color-black;
          }

          app-mini-cart-item {
            color: $color-black;

            .mini-cart-item-container {
              display: flex;
              flex-direction: column;
              gap: 12px;
              margin-bottom: 10px;
              padding-bottom: 20px;
              padding-right: 5px;
              border-bottom: $mini-cart-dialog-mini-item-container-border;

              .article-name {
                display: flex;
                font-size: 16px;
                font-weight: $FONT-WEIGHT-BOLD;
              }

              .article-details {
                display: flex;
                justify-content: space-between;
                padding-right: 10px;

                .manufacturerAID {
                  font-size: 14px;
                }

                .price {
                  font-size: 16px;
                  font-weight: $FONT-WEIGHT-BOLD;
                }
              }

              .mini-cart-item-actions {
                display: flex;
                justify-content: space-between;

                .actions-delete {
                  display: flex;
                  align-items: center;
                }

                app-efa-item-counter {
                  &.quantity-selector {
                    max-height: 44px;
                  }
                }
              }
            }
          }

          .mini-cart-item-list-actions {
            display: flex;
            justify-content: center;

            .btn-show-more {
              text-decoration: none;
              justify-content: center;
            }
          }
        }
      }
    }

    .modal-footer {
      border: 0;
      justify-content: center;
      max-height: fit-content;
    }
  }
}
