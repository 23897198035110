/* ==========================================================================
   # FORMS COMPONENT
   ========================================================================== */

cx-site-context-selector {
  select {
    @include media-breakpoint-up(lg) {
      background-color: $color-white;
    }

    color: var(--cx-color-text);
    cursor: pointer;
  }

  cx-icon {
    color: var(--cx-color-text);
  }
}

.form-control {
  &:focus {
    border-color: var(--cx-color-primary);
  }
}

.form-control:focus, input:focus {
  outline: none;
}

.form-group .label-content {
  display: block;
  margin-bottom: 0.375rem;
}

/* style our custom error validators like spartacus */

cx-form-errors {
  p {
    color: #FB4764;
  }
}

.c-form-errors {
  p {
    font-size: 14px;
    margin: 6px 0;
    padding-inline-start: 25px;
    position: relative;
    word-break: break-word;

    &::before,
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 20px;
    }

    &::before {
      content: '';
      background-color: var(--cx-color-danger);
      border-radius: 50%;
    }

    &::after {
      content: '!';
      color: var(--cx-color-inverse);
      font-weight: var(--cx-font-weight-bold);
      text-align: center;
      line-height: 20px;
    }

    &:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
}
