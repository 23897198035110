/* ==========================================================================
   # MODAL COMPONENT
   ========================================================================== */

$modal-max-width-md: 968px !important;
$modal-min-width-md: 968px !important;
$import-selection-modal-xl-height: 500px !important;
$import-modal-xl-height: 510px !important;
$delete-cart-modal-button-min-width: 100px;
$manufacturers-selection-manufacturer-group-tab-border: 1px solid $color-grey-light;
$manufacturers-selection-active-manufacturer-group-tab-border: 6px solid $color-blue-light;



.product-search-modal {
  .modal-xl {
    @include media-breakpoint-up(lg) {
      max-width: $modal-max-width-md;
      min-width: $modal-min-width-md;
    }
  }

  .table--responsive {
    @include media-breakpoint-down(md) {
      thead {
        display: none;
      }

      tbody {
        width: 100%;
      }

      tbody tr td {
        @include responsive-column;
      }
    }
  }
}

.cart-import-selection-modal {
  .modal-xl {
    .modal-body {
      height: $import-selection-modal-xl-height;
    }
  }
}


.cx-modal-container{
  margin: 1.75rem auto !important;
  height: auto !important;
  overflow-y: unset !important;
}

.cx-modal-content{
  height: 100%;
  .modal-body {
    overflow-y: auto;
  }
}

.cart-delete-modal {
  .modal-md {
    .modal-body {
      height: auto;
    }

    .modal-footer {
      button {
        min-width: $delete-cart-modal-button-min-width;
      }
    }
  }
}

.manufacturers-selection-modal {
  .header-actions {
    margin-left: 15px;

    .header-actions-text {
      font-size: 16px;
      padding-top: 2px;
    }
  }

  .outer-container {
    width: 100%;
    overflow-x: hidden;

    .manufactures-group-container {
      margin-bottom: 30px;

      .manufacturer-group-tab {
        padding-left: 0;
        padding-right: 0;

        .btn-tab {
          border: 0;
          border-radius: 0;
          border-bottom: $manufacturers-selection-manufacturer-group-tab-border;
          background-color: $color-white;
          font-weight: $FONT-WEIGHT-REGULAR;
          font-size: 14px;
          color: $color-black;

          &.btn-tab-active {
            border-bottom: $manufacturers-selection-active-manufacturer-group-tab-border;
            font-weight: $FONT-WEIGHT-SEMI-BOLD;
          }
        }
      }
    }
  }

  .form-check {
    margin-right: $grid-gutter-width;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;

    &.check-item {
      padding-left: 0;
      margin-bottom: 0;
      .check-item-text {
        max-width: 30ch;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include media-breakpoint-down(sm) {
          max-width: 20ch;
        }
      }
    }
  }

  .btn-select-all {
    min-width: 30%;
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: 5px;
  }

  .modal-body {
    @include media-breakpoint-down(sm) {
      height: 75vh;
    }

    padding-top: 0;
  }
}

.modal-body {
  height: 300px;

  input[type=checkbox] {
    position: relative;
    margin-top: 0;
    margin-inline-start: 0;
  }
}

.add-to-cart-modal-body {
  height: 150px;

  input[type=checkbox] {
    position: relative;
    margin-top: 0;
    margin-inline-start: 0;
  }

  @include media-breakpoint-up(md) {
    cx-spinner {
      .loader-container {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

.outer-container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal .close {
  align-self: center !important;

  &:focus {
    outline: none;
  }
}

/* styling for manufacturers-selection modal */
app-manufacturers-selection {
  .modal-header {
    .header-actions {
      margin-left: 15px;

      .header-actions-text {
        font-size: 16px;
        padding-top: 2px;
      }
    }

    border-bottom: 0;
    padding-bottom: 5px;
  }

  .outer-container {
    width: 100%;
    overflow-x: hidden;

    label {
      display: inline-block;
    }

    .manufactures-group-box {
      margin-bottom: 30px;

      .manufacturer-group-tab {
        padding-left: 0;
        padding-right: 0;

        .btn-tab {
          border: 0;
          border-radius: 0;
          border-bottom: $manufacturers-selection-manufacturer-group-tab-border;
          background-color: $color-white;
          font-weight: $FONT-WEIGHT-REGULAR;
          font-size: 14px;
          color: $color-black;

          &.btn-tab-active {
            border-bottom: $manufacturers-selection-active-manufacturer-group-tab-border;
            font-weight: $FONT-WEIGHT-SEMI-BOLD;
          }
        }
      }
    }
  }

  .btn-select-all {
    min-width: 30%;
  }

  .modal-body {
    padding-top: 0;

    @include media-breakpoint-down(sm) {
      height: 75vh;
    }
  }

  .form-check {
    margin-right: $grid-gutter-width;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;

    &.check-item {
      padding-left: 0;
      margin-bottom: 0;
      .check-item-text {
        max-width: 30ch;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include media-breakpoint-down(sm) {
          max-width: 20ch;
        }
      }
    }
  }
}

app-consent-management-dialog {
  .modal-body{
    height: auto;
    outline: 1px solid white;

  }
  .modal-footer{
    button {
      width: 110px;
    }
  }
}
app-cart-import{
  .modal-body {
     height: $import-modal-xl-height;
  }
}

app-cart-delete-dialog{
  .cart-delete-modal{
    justify-content: center;
  }
  .cx-modal-content{
    width:  $import-selection-modal-xl-height;
  }

  .cx-dialog-footer {
    .btn {
      min-width: 100px;
    }
  }

  .modal-body {
    height: auto;
 }
}

app-consent-management-dialog{
  .cx-modal-container{
    justify-content: center;
  }
  .cx-modal-content{
    width:  $import-selection-modal-xl-height;
  }
}

cx-anonymous-consent-dialog {
  .cx-dialog-body {  
    height: auto !important;
  }

  cx-consent-management-form {
    input[type='checkbox'] {
      position: absolute !important;
      margin-top: 0.3rem !important;
      margin-inline-start: -2.25rem !important;
    }
    
    input[type=checkbox][disabled] {
      background: gray !important;
      border: 1px solid transparent !important;
    }

    input[type='checkbox'][disabled] + .form-check-label {
      color: var(--cx-color-light) !important;
    }
  }
}
