/*==========================================================================
   # Video Component
========================================================================== */

cx-video {
  margin-bottom: 12px;

  .video-container {
    display: flex;
    justify-content: center;

    video {
      &:focus {
        outline: none;
      }
  
      @include media-breakpoint-up(lg) {
        max-width: var(--cx-page-width-max);
        padding-inline: 1.5rem;
      }

      @include media-breakpoint-down(md) {
        max-width: 90vw;
      }
    }
  }
}
