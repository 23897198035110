app-cart-actions {
  .dropdown-menu {
    border-radius: 4px;
    overflow: hidden;
  }

  .dropdown-item {
    color: $primary;
    padding-inline: 0.75rem;
    
    &:hover {
      color: $primary;
    }
    &:active {
      color: $white;
    }
  }

  .dropdown {
    &.show .dropdown-toggle::after {
      transform: rotate(180deg);
    }
  }


  .cart-actions__margin-bottom {
    margin-bottom: 20px;
  }
}

app-cart-calculate-mode {
  .cart-calculation-mode-hint {
    display: flex;
    gap: 0.25rem;

    cx-icon {
      color: $color-green-dark;
      align-self: flex-start;
      display: flex;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    p {
      max-width: 302px;
      font-size: 0.75rem;
      font-weight: 400;
      margin: 0;
    }
  }
}