/* ==========================================================================
   # ORDER DETAILS COMPONENT
   ========================================================================== */

$order-details-spacing-medium: $spacing-medium !default;
$order-details-summary-container-border: 1px solid $color-grey-light;
$order-details-items-container-border: 1px solid $color-grey-light;
$order-details-summary-container-bg-color: $color-white;
$order-details-items-header-border: 1px solid $color-grey-light-semi;

app-efa-order-overview {
  @extend %cx-order-overview;
  @extend %cx-card;

  .c-order-summary__container {
    @include content-box;
    background-color: $order-details-summary-container-bg-color;

    .c-order-overview-header {
      .c-order-overview-header-title,
      .c-order-overview-header-label {
        font-size: 22px;
        font-weight: 700;
      }
      margin-bottom: $order-details-spacing-medium;
    }

    .cx-card {
      margin-bottom: $order-details-spacing-medium;
    }

    .c-order__summary-card {
      padding-right: 0;
      padding-left: 0;

      cx-card .cx-card-body {
        .cx-card-label, .cx-card-label-bold {
          display: flex;
          align-items: center;
          line-height: 22px;
        }

        .cx-card-title {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          line-height: 22px;
        }
      }

      &--row {
        cx-card .cx-card-body {
          flex-direction: row;
        }

        &--start {
          @include media-breakpoint-up(md) {
            cx-card .cx-card-body {
              justify-content: flex-start;
              gap: 10px;
            }
          }

          @include media-breakpoint-down(sm) {
            cx-card .cx-card-body {
              flex-direction: column;
            }
          }
        }
      }

      &--order-type {
        cx-card .cx-card-body{
          .cx-card-label {
            max-width: 15ch;
            text-align: right;
            @include media-breakpoint-up(lg) {
              max-width: 20ch;
            }
            @include media-breakpoint-down(sm) {
              max-width: 25ch;
            }
          }
        }
      }

      .cx-card-label-bold {
        font-weight: $FONT-WEIGHT-BOLD;
      }
    }

    .c-order__summary-card {
      margin-bottom: $order-details-spacing-medium;
    }

    .c-order-tax-notice {
      padding: 0.5rem 0;
      margin-bottom: 0;
      font-size: 0.75rem;
    }

    @include media-breakpoint-up(lg) {
      border: $order-details-summary-container-border;
      border-bottom: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .label-content {
    font-size: 18px;
    font-weight: 700;
  }
}

app-efa-order-detail-totals {
  @extend %cx-order-summary;
  padding-inline-end: 0;
  padding-inline-start: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

app-efa-order-detail-items {
  @include media-breakpoint-up(lg) {
    .frame {
      border: $order-details-items-container-border;
      border-top: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-bottom: 30px;
      .cx-list {
        padding: $spacing-medium $spacing-medium 0;
        app-efa-cart-item {
          app-efa-item-counter.order-detail-items-counter input {
            background-color: $color-grey-light-super;
          }
        }
      }
    }
  }

  .c-list-items-header {
    .c-list-items-header-text {
      font-weight: $FONT-WEIGHT-BOLD;
      padding-bottom: 12px;
      border-bottom: $order-details-items-header-border;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 10px;
    }
  }
}

app-order-detail-actions {
  @include media-breakpoint-up(md) {
    .col-cancel-btn {
      max-width: 356px;
    }
  }
}
