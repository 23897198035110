/* ==========================================================================
   # GENERIC FONTS
   ========================================================================== */

@include font-face(
  $font-family: $PRIMARY-FONT,
  $font-name: $PRIMARY-FONT,
  $font-path: $FONT-PATH,
  $font-face: "Light",
  $font-weight: $FONT-WEIGHT-LIGHT
);

@include font-face(
  $font-family: $PRIMARY-FONT,
  $font-name: $PRIMARY-FONT,
  $font-path: $FONT-PATH,
  $font-face: "Regular",
  $font-weight: $FONT-WEIGHT-REGULAR
);

@include font-face(
  $font-family: $PRIMARY-FONT,
  $font-name: $PRIMARY-FONT,
  $font-path: $FONT-PATH,
  $font-face: "Semibold",
  $font-weight: $FONT-WEIGHT-SEMI-BOLD
);

@include font-face(
  $font-family: $PRIMARY-FONT,
  $font-name: $PRIMARY-FONT,
  $font-path: $FONT-PATH,
  $font-face: "Bold",
  $font-weight: $FONT-WEIGHT-BOLD
);

@include font-face(
  $font-family: $PRIMARY-FONT,
  $font-name: $PRIMARY-FONT,
  $font-path: $FONT-PATH,
  $font-face: "Black",
  $font-weight: $FONT-WEIGHT-BLACK
);
@include font-face(
  $font-family: $SECONDARY-FONT,
  $font-name: $SECONDARY-FONT,
  $font-path: $FONT-PATH,
  $font-face: "Black",
  $font-weight: $FONT-WEIGHT-BLACK
);
