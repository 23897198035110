/* ==========================================================================
   # Elements forms
   ========================================================================== */

.form-group {
  input[type='text']::placeholder {
    color: $input-placeholder-color;
  }

  input[type='text']:-ms-input-placeholder {
    color: $input-placeholder-color;
  }

  input[type='text']::-ms-input-placeholder {
    color: $input-placeholder-color;
  }
}

select {
  &.placeholder-text {
    color: $input-placeholder-color;
  }
}

input,
input[type='checkbox'],
input[type='radio'],
textarea,
select {
  &.ng-invalid {
    &.ng-dirty,
    &.ng-touched {
      border-color: var(--cx-color-danger);

      /* Chrome/Opera/Safari */
      &::-webkit-input-placeholder {
        color: var(--cx-color-inverse);
      }

      &::placeholder {
        color: var(--cx-color-inverse);
      }

      /* Internet Explorer 10-11 */
      &:-ms-input-placeholder {
        color: var(--cx-color-inverse);
      }

      /* Microsoft Edge */
      &::-ms-input-placeholder {
        color: var(--cx-color-inverse);
      }
    }
  }
}

input[type='file'] {
  &.ng-invalid {
    &.ng-dirty,
    &.ng-touched {
      + label {
        border-color: var(--cx-color-danger);
      }
    }
  }
}

.select-arrow{
  select {
    appearance: none;
    background-image: url(../../../assets/icons/arrow-expand-down.svg);
    background-position-x: calc(100% - 10px);
    background-position-y: center;
    background-repeat: no-repeat;
  }
}

