app-efa-footer-navigation {
  @extend %cx-footer-navigation;

  app-efa-navigation-ui {
    @extend cx-navigation-ui;

    background-color: var(--cx-color-background);
    display: flex;

    a {
      color: currentColor;
    }

    div {
      ul {
        padding-inline-start: 0;
      }
    }

    > nav {
      padding-right: 0;
      > ul {
        display: flex;
        padding-inline-start: 0;  

        li {
          display: block;
          padding-right: 20px;
        }
      }
    }

    .navItem {
      margin: 0;
      padding: 5px 0;

      font-size: var(--cx-font-small, 0.8rem);
      font-weight: 400;
      line-height: 1.6;
      text-transform: none;
    }

    @include media-breakpoint-up(lg) {
      background-color: black;
    }
  }
}
