/* ==========================================================================
   # LOGIN COMPONENTS
   ========================================================================== */

.SiteLogin {
  @include media-breakpoint-up(lg) {
    margin-left: auto;
  }

  .cx-login-greet {
    margin-bottom: 0.375rem;
    color: var(--cx-color-primary);
    @include media-breakpoint-up(lg) {
      max-width: 175px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  a {
    color: var(--cx-color-primary);
    font-weight: var(--cx-font-weight-semi);
  }
}

app-efa-login {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .user-greeting {
    @include media-breakpoint-down(md) {
      border-top: 1px solid $color-white;
      padding-bottom: 0;
    }

    
    .user-greeting-text {
      padding-left: 1.25rem;
      font-size: 14px;
      font-weight: $FONT-WEIGHT-REGULAR;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  .withoutTextTransform {
    text-transform: none;
  }

  > * {
    @include media-breakpoint-down(md) {
      padding: 0.5rem 1rem;
    }
  }
}

app-order-type-selection[myname="loginOrderTypeSelection"] {
  .form-group .label-content {
    margin-bottom: 0;
  }

  .ng-select {
    top: -2px;

    .ng-select-container {
      border: none;
      color: var(--cx-color-primary);
      font-weight: 900;
      font-size: 1rem;

      &:hover {
        box-shadow: none;
        cursor: pointer;
        outline-width: unset;
      }

      &:focus {
        outline: none;
        box-shadow: unset;
      }
    }
  }

  .ng-select .ng-select-container,
  .ng-select.ng-select-single .ng-select-container {
    padding: 0;
    line-height: 1.2222222222;
  }

  .ng-select .ng-select-container .ng-value-container {
    padding-left: 0;
  }

  .ng-select .ng-select-container .ng-value-container,
  .ng-select.ng-select-single .ng-select-container .ng-value-container {
    padding-inline-start: 0;
  }

  .ng-dropdown-panel.ng-select-bottom {
    top: 30px;
  }

  .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
  .ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: transparent;
    color: var(--cx-color-primary);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 5px 10px;

    &:first-child {
      padding-top: 15px;
    }

    &:last-child {
      padding-bottom: 15px;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-weight: var(--cx-font-weight-semi);
    color: var(--cx-color-primary);
  }

  .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow,
  .ng-select.ng-select-single .ng-arrow-wrapper .ng-arrow {
    border-width: 0;
    top: 0;
    font-family: "Font Awesome 5 Free";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;

    &::before {
      content: "\f107";
    }
  }
}

.efa-section-title {
  @extend .cx-section-title;
  text-transform: none;
  font-weight: normal;
}

