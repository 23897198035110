/* ==========================================================================
   # CUSTOM FILE COMPONENTS
   ========================================================================== */

.custom-file-input:hover {
  cursor: pointer;
}

.custom-file-label {
  color: var(--cx-color-light);

  &::after {
    content: attr(data-content) !important;
    width: 7rem;
    background-color: var(--cx-color-primary);
    color: var(--cx-color-inverse);
  }
}
