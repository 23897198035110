.btn-primary:disabled {
  background-color: var(--cx-color-primary);;
}
.btn-primary:focus{
  border-color: var(--cx-color-primary);
  outline: unset;
  box-shadow: unset;
}
.btn-primary:not(:disabled):not(.disabled):active{
  filter: unset;
}
.input-group-append{
  button{
    padding: 9px 1rem;
    &:focus{
      outline: unset;
      box-shadow: unset;
      border-color: var(--cx-color-primary);
    }
  }
}
