/* ==========================================================================
   # PAGE TEMPLATE ELEMENTS
   ========================================================================== */

main .LandingPage2Template {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 100vw;

  .Section3 {
    @extend .row;
  }

  .Section4 {
    display: flex;
    flex-direction: column;
  }
}

cx-page-layout {
  /* Cart page template */
  &.CartPageTemplate .CenterRightContentSlot {
    --cx-flex-basis: 100%;
    flex-direction: row;

    @include media-breakpoint-up(lg) {
      > * {
        flex: 1 1 50%;
      }
    }
  }

  &.ContentPage1Template {
    padding-top: 3rem;

    &:has(app-car-parts-promotion) {
      padding-top: 0rem;

      .Section3 {
        margin-bottom: 3rem;
        margin-top: 1rem;
        max-width: 65%;
      }

      .Section1 {
        max-width: 100%;
        padding: 0;
      }
    }
  }

  &.ContentPage1Template,
  &.MultiStepCheckoutSummaryPageTemplate,
  &.OrderConfirmationPageTemplate,
  &.CartPageTemplate,
  &.AccountPageTemplate,
  &.ErrorPageTemplate {
    display: flex;
    flex-direction: column !important;
    max-width: 100vw !important;
    padding-inline: 0 !important;
    padding-bottom: 0;
    > * {
      width: 100%;
      max-width: var(--cx-page-width-max);
      padding-inline: 1.5rem;
      margin-inline: auto;
    }

    cx-page-slot {
      &.TopFooter {
        width: 100%;
        max-width: 100%;
        padding: 0;
        > * {
          max-width: 100%;
        }
      }
    }
  }

  &.MultiStepCheckoutSummaryPageTemplate,
  &.OrderConfirmationPageTemplate {
    padding-top: 2rem;

    @include media-breakpoint-up(lg){
      cx-page-slot {
        &.BodyContent {
          padding-inline: 2.5rem;
          padding-block: 1.5rem;
          margin-bottom: 80PX;
          border: 1px solid $color-grey-light;
          border-radius: 4px;
        }
      }
    }
  }

  &.LoginPageTemplate {
    padding-bottom: 0;

    cx-page-slot {
      padding-top: 1rem;
      &.TopContentSlot {
        cx-paragraph {
          max-width: var(--cx-max-width, 60%);
          width: 100%;
          text-align: center;
        }
      }

      &.BodyContent {
        > * {
          max-width: var(--cx-page-width-max);
          width: 100%;
        }
      }

      &.WideBodyContent {
        max-width: 100%;
        padding-inline: 0;

        > * {
          max-width: 100%;
          width: 100%;
        }
      }

      &.TopFooter {
        max-width: 100%;
        width: 100%;
        padding: 0;
        > * {
          max-width: 100%;
        }
      }
    }
  }

  &.CartPageTemplate {
    cx-page-slot {
      &.TopContent,
      &.EmptyCartMiddleContent {
        app-efa-product-carousel {
          @include media-breakpoint-up(lg) {
            min-width: var(--cx-page-width-max);
            .transform-less
            {
              transform: translate(-0.5rem, 0);
            }
            .transform-more
            {
              transform: translate(-3rem, 0);
            }

            cx-carousel {
              h2 {
                margin-left: 3rem;
                margin-right: 3rem;
              }
            }
          }

          @include media-breakpoint-down(sm) {
            cx-carousel {
              cx-media {
                height: 216px;
                width: 216px;

                img {
                  height: 100%;
                  object-fit: contain;
                }

                &.is-missing {
                  min-width: 216px;
                  max-width: 216px;
                  max-height: 216px;
                  min-height: 216px;
                }
              }

              .carousel-item-container {
                max-width: 225px;
                min-width: 225px;
                max-height: 458px;
                min-height: 458px;

                .product-name {
                  max-width: 200px;
                }

                app-efa-cart-item-add-to-wish-list {
                  position: absolute;
                  text-align: end;
                  transform: translate(190px, 15px);
                  z-index: 10;
                }
              }
            }
          }
        }

        app-efa-banner {
          margin-top: 4rem;
          margin-bottom: 5rem;

          @include media-breakpoint-up(lg) {
            app-efa-generic-link {
              a {
                app-efa-media {
                  img {
                    &.u-img-fluid {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }

          @include media-breakpoint-down(md) {
            app-efa-generic-link {
              a {
                display: flex;
                justify-content: center;
              }
            }
          }
        }

        app-efa-product-carousel + app-efa-banner {
          z-index: 100;
        }
      }
    }
  }

  &.LandingPage2Template {
    cx-page-slot.TopFooter {
      width: 100%;
      max-width: 100%;
    }
  }
  &.footer {
    background-color: var(--cx-background-color, var(--cx-color-text));
  }

  &.AccountPageTemplate {
    cx-page-slot.BottomContent {
      width: 100%;
      max-width: 100%;
    }

    cx-page-slot.BodyContent {
      &:has(app-efa-tiles-cms-container) {
        app-efa-banner {
          @extend .row;
          justify-content: center;

          app-efa-generic-link {
            @extend .col-12;
            @extend .col-md-10;
          }
        }
      }
    }
  }

  &.ErrorPageTemplate {
    cx-page-slot {
      &.TopContent {
        padding-top: 2rem;

        app-efa-banner {
          margin-bottom: 2rem;
        }
      }

      &.MiddleContent {
        margin-bottom: 10rem;
      }
    }
  }

  &.navigation {
    @include media-breakpoint-down(md) {
      padding-top: 0;
    }
  }
}
.ProductDetailsPageTemplate {
  cx-add-to-wishlist {
    padding: 0;
  }

  cx-page-slot {
    &.Tabs {
      max-width: var(--cx-page-width-max);
      margin: auto;
    }

    &.UpSelling,
    &.CrossSelling {
      @include media-breakpoint-up(lg) {
        max-width: 1200px !important;
        min-width: 1200px !important;
      }
    }
  }
}

.SearchResultsGridPageTemplate,
.SearchResultsListPageTemplate {
  cx-page-slot {
    cx-paragraph {
      @include media-breakpoint-up(md) {
        max-width: 50%;
      }
    }
  }
}
