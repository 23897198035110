app-tires-warranty-form-component{
  #tiresWarrantyForm{
    margin-bottom: $spacing-wrapper;
    @include c-select-model;
  }
  .tires-warranty-header{
    width: 90%;
    margin: auto;
    margin-bottom: $spacing-wrapper;

    h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: $spacing-wrapper;
    }

    .header-box {
      display: flex;
      flex-direction:row-reverse;
      .image-box{
        @include media-breakpoint-down(md) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

      }
      .left-box {
        // width: 497px;
        margin-right: 65px;
        @include media-breakpoint-down(md) {
          margin-right: 0px;
        }
        .nav-items{
          @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .warranty{
              width: 90% !important;
            }
            .damage{
              width: 90% !important;
            }
          }
          margin-bottom: $spacing-large;
          .warranty, .damage {
            width: 224px;
            height: 48px;
            background: #FFFFFF;

            text-align: center;
            align-items: center;
            :hover {
              cursor: pointer;
            }

          }
          i{
            color:#003899 ;
          }
          .warranty{
            border: 1px solid #003899;
            border-radius: 30px;
          }
          a{
            text-decoration: none;
            display: inline-block;
            padding: 10px;
          }
        }
      }

    }

    @include media-breakpoint-down(md) {
      .header-box {
        display: block;
      }
    }
  }
  .warranty-submit-btn{
    width: 50%;
    @include media-breakpoint-down(sm) {
      width: 90%;
      height: auto;
      max-height:fit-content;
    }

  }
  .tires-warranty-footer{
    margin-top: $spacing-wrapper;
    h2 {
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      text-align: center;
    }
    .footer-box{
      margin: auto;
    }
    .register-btn{
      width: 50%;
      height: 48px;
      background: #FFFFFF;
      text-align: center;
      align-items: center;
      border: 1px solid #003899;
      border-radius: 30px;
      margin: 0px auto 0px auto;
      @include media-breakpoint-down(sm) {
        width: 90%;
      }
      a{
        width: 100%;
        text-decoration: none;
        display: inline-block;
        padding: 10px;
      }
      i{
        color:#003899 ;
      }
      :hover{
        cursor: pointer;
      }
    }
  }

  .form-header{
    display: flex;
    justify-content: center;
    margin-bottom: $spacing-huge;

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: 0px;
      color: #000000;
      flex: none;
      order: 4;
      flex-grow: 0;
      max-width: 100%;
    }
  }
  .line1{
   border: 0.5px solid #000000;
  //  margin-bottom:$spacing-small ;
  }
  form fieldset {
    margin-bottom: $spacing-wrapper;
  }
  legend {
    padding-top:$spacing-small ;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: 20px;
  }
  .custom-file-label::after {

position: absolute;
width: 122px;
height: 44px;
right: 0px;
top: 0px;
color: white;
background:$color-blue;
border-left: transparent;
border-radius: 0px 3px 3px 0px;
  }
  .terms-box{
    padding-left: 40px;
    #warrantyTerms:checked {
      accent-color: var(--cx-color-primary) !important;
    }
  }

  .btn-hover-transform.btn-link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
