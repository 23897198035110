app-topmotive-confirmation-dialog.cx-dialog-popover-center {
    .modal-container {
        background-color: $color-white;
        max-width: 800px;

        .modal-header {
            max-height: 35px !important;
            border: 0;
            padding-top: 10px;
            justify-content: end;
        }

        .modal-body {
            max-height: 600px !important;
            padding-bottom: 0;
            padding-top: 0;

            .outer-container {
                .topmotive-confirmation-message-title {
                    font-weight: $FONT-WEIGHT-BOLD;
                    font-size: 22px;
                    margin-bottom: 10px;
                    text-align: center;
                }

                .topmotive-confirmation-message-text {
                    font-weight: $FONT-WEIGHT-REGULAR;
                    font-size: 15px;
                    margin-bottom: 10px;
                    text-align: left;
                }
            }
        }

        .modal-footer {
            border: 0;
            justify-content: center;

            .btn-confirm, .btn-cancel {
                min-width: 208px;
            }
        }
    }
}

app-topmotive-catalog {
    .btn-back {
        width: 25%;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}