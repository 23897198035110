app-efa-address-book {
  @extend %cx-address-book;

  app-efa-card {
    @extend %cx-card;
    .card-header {
      background-color:#C4E3D4;
      color: $color-black;
      display: flex;
      justify-content: center;
      align-items: center;

      .header-icon {
        img{
          margin-right: 10px;
        }
        .cx-icon {
          svg {
            padding-top: 2px;
            margin-right: 2px;
          }
        }
      }
      .header-text {
        font-size: $small-font-size;
        font-weight: $FONT-WEIGHT-REGULAR;
      }
    }
    .cx-card-actions {
      a{
        font-size: $h5-font-size;
        font-weight: $FONT-WEIGHT-SEMI-BOLD;
      }
    }
  }

  .noAddresses {
    font-size: 1.50rem;
  }
}
