/* ==========================================================================
   # EFA GLOBAL THEME VARIABLES
   ========================================================================== */
@import "../../01-settings/settings.colors";

// change theme-colors here
$primary:                                 $color-blue !default;
$secondary:                               $color-white !default;
$success:                                 $color-green !default;
$danger:                                  $color-red !default;
$danger-light:                            $color-red-light !default;
$warning:                                 $color-yellow !default;
$info:                                    $color-purple !default;
$light:                                   $color-grey-light !default;
$lighter:                                 $color-grey-lighter !default;
$lightest:                                $color-grey-lightest !default;
$dark:                                    $color-grey-dark !default;
$background:                              $color-white !default;
$inverse:                                 $color-white !default;
$text:                                    $color-black !default;
$transparent:                             transparent !default;
$visual-focus:                            $color-purple-light !default;
$background-focus:                        rgba(80, 176, 244, 0.1);

// Color list for utility classes (utilities.colors)
// Add colors, which should be generated as util classes
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'danger': $danger,
  'danger-light': $danger-light,
  'warning': $warning,
  'info': $info,
  'light': $light,
  'lighter': $lighter,
  'lightest': $lightest,
  'dark': $dark,
  'background': $background,
  'inverse': $inverse,
  'text': $text,
  'transparent': $transparent,
  'visual-focus': $visual-focus,
  'background-focus': $background-focus,
);

// Percentile list for classes that could have variable percentile properties.
// Add percentiles which should be generated and used as percentile values.
$percentiles: (
  '5': 5%,
  '10': 10%,
  '15': 15%,
  '20': 20%,
  '25': 25%,
  '30': 30%,
  '35': 35%,
  '40': 40%,
  '45': 45%,
  '50': 50%,
  '55': 55%,
  '60': 60%,
  '65': 65%,
  '70': 70%,
  '75': 75%,
  '80': 80%,
  '85': 85%,
  '90': 90%,
  '95': 95%,
  '100': 100%,
);

$hamburger-layer-color: $primary;
$hamburger-active-layer-color: $inverse;

$custom-file-height: calc(1.5em + 0.75rem + 8px);
$custom-file-height-inner: calc(1.5em + 0.75rem + 8px);
$custom-file-padding-y: 0.5rem 0.75rem;
