/* ==========================================================================
   # UTILITIES TYPOGRAPHY
   ========================================================================== */

/**
  * Utility classes to put specific font weight values into elements. The below loop
  * will generate us a suite of classes like:
  *
  * .u-font-light {font-weight: 200;}
  * .u-font-regular {font-weight: 400;}
  * .u-font-semi {font-weight: 600;}
  * .u-font-bold {font-weight: 700;}
  * .u-font-black {font-weight: 900;}
 */

@each $weight, $value in $font-weights {
  $classString: u-font-#{$weight};
  .#{$classString} {
    font-weight: $value;
  }
}


.u-text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
