/* ==========================================================================
   # GLOBAL MESSAGE COMPONENT
   ========================================================================== */

app-efa-storefront {
  @extend %cx-storefront;
}

app-efa-global-message {
  @extend %cx-global-message;

  z-index: 3;

  .staticInfoMessage {
    display: flex;
    min-height: 36px;
    font-size: 14px;
    font-weight: 400;
    @include media-breakpoint-down(md) {
      padding: 10px 0px;
      display: block;
    }
    .alert-icon{
      font-size: 18px;
      margin-bottom: 2px;
    }
    a{
      color: $color-black;
      text-decoration: underline;
      @include media-breakpoint-down(md) {
        // display: block !important;
      }
    }
  }
}
