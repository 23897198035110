@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  header {
    width: 100%;

    cx-page-layout {
      display: table;
      max-width: 1140px;
      margin: 0 auto;
    }

    & .SiteContext,
    & .SiteLinks {
      display: inline-table;
      width: 50%;
      height: 40px;
    }

    & .NavigationBar {
      display: inline-table;
    }

    & .SiteLogo {
      vertical-align: top;
    }

    & .MiniCart,
    & .SiteLinks cx-link {
      float: right;
    }

    & .SearchBox {
      margin: 14px 100px;
    }
  }

  cx-page-layout {
    &.footer {
      background-color: #3C3C3C;
    }

    cx-page-slot {
      display: inline-table;
      vertical-align: middle;

      &.Section2A {
        display: block;
        padding-top: 3rem;
        padding-bottom: 3rem;
        text-align: center;
      }

      &.Footer {
        display: block;
        background-color: #3C3C3C;
        color: #ffffff;
      }
    }
  }

  cx-navigation-ui > nav > cx-generic-link > a {
    padding-right: 15px;
  }

  cx-site-context-selector select::-ms-expand {
    display: none;
  }
}



