/* ==========================================================================
   # SITE LINKS COMPONENTS
   ========================================================================== */

app-efa-link {
  @extend %cx-link;

  padding-inline-end: 0;

  a {
    display: block;
    color: $primary;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      padding: 20px 0;
      color: var(--cx-color-inverse);
      border-top: 1px solid var(--cx-color-inverse);
    }

    &:hover {
      text-decoration: none;
    }
  }
}

header {
  .SiteLinks cx-link {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .SiteLinks app-efa-link a {
    font-size: $small-font-size;
    font-weight: 700;
  }
}

cx-site-context-selector:not(:first-child) {
  @include media-breakpoint-down(md) {
    border-inline-start: none;
  }
}
