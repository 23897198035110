/* ==========================================================================
   # DIVIDER OBJECTS
   ========================================================================== */
.o-divider-horizontal {
    border: 0.5px solid $color-grey-light;

    &.o-divider-spacing-large {
        margin-top: $spacing-large;
        margin-bottom: $spacing-large;
    }

    &.o-divider-full-view-width {
        width: 100vw;
    }

    &.o-divider-light-black-box-shadow {
        border: 0;
        height: 10px;
        box-shadow: 0px 3px 5px 0px $color-black-light;
    }
}
