/* ==========================================================================
   # SPINNER COMPONENTS
   ========================================================================== */

cx-spinner {
    &.spinner-popover-backdrop {
        position: fixed;
        z-index: 1050;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        outline: 0;
        display: grid;
        place-items: center;
        background-color: rgba(0, 0, 0, 0.5);
    }
}