/* ==========================================================================
   # DISPLAYS UTILITIES
   ========================================================================== */

.u-dis-block {
  display: block !important;
}

.u-dis-none {
  display: none !important;
}

.u-white-space-nowrap {
  white-space: nowrap;
}

.u-focus-none {
  &:focus {
    outline: none;
  }
}
