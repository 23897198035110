/* ==========================================================================
   # ORDER CONFIRMATION COMPONENT
   ========================================================================== */

app-efa-order-confirmation-thank-you-message {
  //TODO: check !optional
  @extend %cx-order-confirmation-thank-you-message !optional;

  .cx-page-title {
    @include type('2');
    margin: unset;
    font-size: 22px;
  }
}

app-efa-order-overview {
  @extend %cx-order-overview;

  .c-review__title {
    color: var(--cx-color-success);
  }

  cx-card .cx-card-title {
    font-size: 18px;
  }
}

app-efa-order-confirmation-totals {
  .cx-order-summary {
    padding-bottom: 2rem;

    @include border-top;
  }

  .c-configurator-order-tax-notice-container {
    margin-bottom: 40px;

    .c-configurator-order-tax-notice {
      padding: 0;
      font-size: 0.75rem;
    }
  }
}
