app-image-text {
  width: 100%;
  overflow: hidden;
  margin-bottom: 1.5rem;

  h2.title {
    text-align: center;
    margin-bottom: 1.5rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 1.5rem;
    }
  }

  cx-media {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image-text-button {
    max-width: 356px;
    margin-top: 1.5rem;
  }

  hr {
    margin-top: 3.5rem;
  }

  .efa-app-text
  {
    color:white;
    display:flex;
    align-items:center;
    max-width: 450px;
    font-size: 22px;
  }

  .efa-app-image
  {
    vertical-align:middle;
    margin:10px;
  }
}

.WideBodyContent app-image-text hr {
  display: none;
}
.BodyContent app-image-text {
  .image-text-content {
    table{
      tr{
        display: flex;
        @include media-breakpoint-down(xs) {
          height: auto;
          min-height: 50px;
          td{
            width: 50%;
          }
        }
        td{
          display: flex;
          align-items: start;
          img{
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }
          strong{
            @include media-breakpoint-down(xs) {
              // word-break: break-all;
            }
          }
        }
      }
      tr:last-child{
        td:last-child{
          @include media-breakpoint-down(xs) {
            // word-break: break-all;
            display: block;
          }
        }
      }
    }
  }
}
