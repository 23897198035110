/* ==========================================================================
   # SEARCH BOX COMPONENTS
   ========================================================================== */

/* This part overriding spartacus searchbox body result with our new defined selector app-efa-searchbox.
   [1] our custom component selector
**/

$search-result-box-shadow:    $BOX-SHADOW-BASE !default;

app-efa-searchbox {
  @extend %cx-searchbox;
  @extend %cx-searchbox__body;

  .searchbox {
    font-size: smaller;
    color: $color-grey;
    input{
      color: $color-grey;
      padding: 10px;
    }
  }

  .searchbox.dirty{
    font-size: smaller;
    input{
      color: $color-black;
    }
  }
}

header {
  app-efa-searchbox {
    z-index: 1;
    font-size: $font-size-lg;
    border-radius: var(--cx-buttons-border-radius);


    @include media-breakpoint-up(md) {
      font-size: inherit;
    }

    .searchbox-wrapper{
      @include media-breakpoint-up(md) {
        border: none;
      }
    }

    label {
      padding: 0;
      border-radius: none;


      @include media-breakpoint-up(md) {
        border-radius: var(--cx-buttons-border-radius);
      }
      @include media-breakpoint-up(lg) {
        max-width: 300px;
      }

      @include media-breakpoint-down(sm) {
        background-color: var(--cx-color-inverse);
      }
      label.dirty{
        background-color:transparent ;
      }
      .search-icon{
        border-left: 1px solid var(--cx-color-light);
        border-radius: 0 var(--cx-buttons-border-radius) var(--cx-buttons-border-radius) 0 ;
        padding: 6px;
      }
      .search-icon.searchbox-empty {
        background-color: var(--cx-color-inverse);
      }
      .search-icon.searchbox-filled {
        background-color: var(--cx-color-primary);
        cursor: pointer;
      }
      cx-icon.searchbox-empty {
        color: var(--cx-color-primary);
        @include media-breakpoint-down(sm) {
          color: var(--cx-color-primary);
        }
      }
      cx-icon.searchbox-filled {
        color: var(--cx-color-inverse);
        @include media-breakpoint-down(sm) {
          color: var(--cx-color-inverse);
        }
      }
    }

    input {
      background-color: var(--cx-color-inverse) !important;
      padding: 10px;
      border-radius: var(--cx-buttons-border-radius) 0 0 var(--cx-buttons-border-radius);
      text-overflow: ellipsis;

      &.searchbox-is-not-active {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    .results {
      margin-top: 1px;
      box-shadow: $search-result-box-shadow;
      padding-top: 0px;

      .suggestions {
        padding-inline-start: 0;
        margin-bottom: 0;
      }
  
      .products {
        list-style: none;
        padding-inline-start: 0;
        margin-bottom: 0;
        display: block !important;
        
        a {
          display: grid;
          grid-column-gap: 16px;
  
          &.has-media {
            grid-template-columns: 50px 1fr;
          }
  
          border-top: solid 1px var(--cx-color-light);
  
          cx-media {
            grid-row: 1 / 4;
          }
        }
      }
    }
  }
}

%cx-searchbox {
  label {
    @include media-breakpoint-up(md) {
      max-width: 300px;
      // hide search icon when the input is dirty
      &.dirty div.search-icon {
        display: block;
      }
    }
}
}


%cx-searchbox__body {
  &.searchbox-is-active {
    &.has-searchbox-results {
      app-efa-searchbox { // [1]
        .results {
          display: block;
        }
      }

      header {
        position: relative;
        z-index: 100;
      }

      app-efa-storefront {
        &:before {
          content: '';
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  &:not(.searchbox-is-active) {
    @include media-breakpoint-down(sm) {
      app-efa-searchbox { // [1]
        input {
          width: 0;
          padding: 0;
        }
        button.reset {
          display: none;
        }
      }
    }
  }
}

.article-number {
  font-weight: normal;
}

body.searchbox-is-active.has-searchbox-results app-efa-searchbox .results {
  display: block
}
