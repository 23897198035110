/* ==========================================================================
   # DELIVERY NOTES DETAILS COMPONENT
   ========================================================================== */

$dn-details-bg-color:           $color-grey-lightest;
$dn-details-spacing-medium:     $spacing-medium;

app-return-details{
  .c-return-details {
    .return-header-and-table{
      border: 1px solid #B8BCC3;
      border-radius: 4px;
      padding: 2rem;
      margin-bottom: $spacing-huge;
      @include media-breakpoint-down(md) {
        border: 1px solid transparent;
        padding: 1rem;
        margin-bottom: 0rem;
      }
      .table-title{
        width: 100%;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        border-bottom: 1px solid #C3C5C8;
        padding: 10px 1rem;
        @include media-breakpoint-down(md) {
          // border-bottom: 1px solid transparent;
        }
      }
    }
    .c-review__container {
      // margin: 0 0 2rem 0;
      // padding: $dn-details-spacing-medium $dn-details-spacing-medium 0;
      align-items: center;
    }

    .c-review__summary-card{
      margin-bottom: $dn-details-spacing-medium;
      .return-number {
        .cx-card {
          .cx-card-body  {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 1rem 0 !important;
            .cx-card-title,.cx-card-label-container{
              font-size: 22px !important;
              font-weight: 700;
              margin: 0px !important;
              line-height: 20px !important;
            }
           h3.cx-card-title::after  {
                content: ':';
                position:absolute;
                top: 0px;
                right: -10px;
            }
            h3.cx-card-title{
              position: relative;
            }
            .cx-card-label-container{
              margin-left: 1rem !important;
            }
          }
        }

      }
      .date-section,.delivery-note-number-section{
        .cx-card {
          .cx-card-body  {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 1rem 0 !important;
            .cx-card-title{
              font-size: 18px !important;
              font-weight: 700;
              margin: 0px !important;
              line-height: 20px !important;
            }
            .cx-card-container{
              margin-left: 1rem !important;
              line-height: 20px !important;
            }
          }
        }
      }
    }

    .status-section{
      .cx-card {
        .cx-card-body  {
          display: flex;
          flex-direction: row;
          justify-content: start;
          padding: 1rem 0 !important;
          .cx-card-container{
            margin-left: 1rem;
          }
        }
      }
    }
  }

  .c-return-details__table {
    margin-top: 3em;
    border-bottom:  1px solid #B8BCC3;
    @include media-breakpoint-down(md) {
      border-bottom:  1px solid transparent;
    }
    thead th {
      text-align: left;
      border-bottom:  1px solid #B8BCC3;
      font-size: 12px;
      font-weight: 700;
      // width: 20%;
    }
    thead th:first-child{
      @include media-breakpoint-up(lg) {
        width: 15%;
      }

    }
    thead th:last-child{
      @include media-breakpoint-up(lg) {
        width: 20%;
      }
    }
    thead th.col-quantity{
      @include media-breakpoint-up(lg) {
        width: 20%;
      }
    }
    thead th.col-article{
      @include media-breakpoint-up(lg) {
        width: 20%;
      }
    }

    tbody td {
      text-align: left;

      @include media-breakpoint-down(md) {
        @include responsive-column;
      }

      p {
        margin-bottom: 0.5rem;
        span{
          font-weight: 700;
        }
      }
    }
    tbody tr{
      @include media-breakpoint-down(md) {
        border-bottom: 1px solid transparent;
      }
    }
  }

  .c-return-details__bottom {
    float: right;
    min-width: 35%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
