/* ==========================================================================
   # TABLE COMPONENTS
   ========================================================================== */

/* [1] Reset the spartacus text transformation of table thead  */

.table thead th,
cx-table table th {
  text-transform: capitalize; // [1]
  background-color: var(--cx-color-background);
  color: var(--cx-color-text);
}
