/* ==========================================================================
   # ORDER SUMMARY COMPONENTS
   ========================================================================== */

app-efa-order-summary {
  @extend %cx-order-summary;
  padding-top: 0;
  padding-bottom: 0;
  padding-inline-end: 0;
  padding-inline-start: 0;

  @include media-breakpoint-down(md) {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }

  .cx-summary-total {
    font-size: $h4-font-size;
  }
}

app-efa-checkout-order-summary {
  @extend %cx-order-summary;
  padding-inline-end: 0;
  padding-inline-start: 0;
  padding-bottom: 2rem;

  @include border-top;

  .btn-margin-top {
    @include media-breakpoint-down(sm) {
      margin-top: $spacing-small;
    }
  }

  .c-configurator-order-tax-notice-container {
    margin-bottom: 25px;

    .c-configurator-order-tax-notice {
      font-size: 0.75rem;
    }
  }
}

app-efa-place-order {
  @include media-breakpoint-between(md, lg) {
    .cx-place-order-form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .form-inline {
    @include media-breakpoint-between(md, lg) {
      display: flex;
      flex-flow: unset;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .c-btn-submit {
    width: 239px;
    float: right;
  }
}

app-order-limit-exceeded-confirmation-dialog.cx-dialog-popover-center,
app-cart-limit-exceeded-confirmation-dialog.cx-dialog-popover-center {
  .modal-container {
    background-color: $color-white;
    max-width: 800px;

    .modal-header {
      max-height: 35px !important;
      border: 0;
      padding-top: 10px;
      justify-content: end;
    }

    .modal-body {
      max-height: 160px !important;
      padding-bottom: 0;
      padding-top: 0;

      .outer-container {
        text-align: center;

        .limit-exceeded-confirmation-message-title {
          font-weight: $FONT-WEIGHT-BOLD;
          font-size: 22px;
          margin-bottom: 10px;
        }

        .limit-exceeded-confirmation-message-text {
          font-weight: $FONT-WEIGHT-REGULAR;
          font-size: 15px;
          margin-bottom: 10px;
        }
      }

      .limit-exceeded-confirmation-message-icon {
        cx-icon svg {
          color: $primary;
          height: 48px;
          width: 48px;
        }

        margin-bottom: 40px;
      }

      @include media-breakpoint-down(sm) {
        max-height: 600px !important;
      }
    }

    .modal-footer {
      border: 0;
      justify-content: center;

      .btn-confirm,
      .btn-cancel {
        min-width: 208px;
      }
    }
  }
}