/* ==========================================================================
   # Elements Page
   ========================================================================== */

$header-box-shadow: $BOX-SHADOW-BASE !default;
$site-context-basis: 105px;
$mini-cart-bases: 195px;

body {
  background-color: var(--cx-color-background);
  color: var(--cx-color-text);
}

header {
  background-color: var(--cx-color-background);
  color: var(--cx-color-primary);
  box-shadow: $header-box-shadow;
  z-index: $zindex-header;



  .navigation {
    background-color: white;
    padding-top: 25px;

    app-my-account-navigation-ui.flyout {
      span {
        border-bottom: none;
        white-space: break-spaces;
      }

      nav {
        @include media-breakpoint-down(md) {
          span {
            text-transform: none;
            font-size: 22px;
            font-weight: 700;
            color: #003899;
            border-bottom: none;
            white-space: break-spaces;
          }
        }
      }
    }

    & .SiteLogin {
      @include media-breakpoint-down(md) {
        max-width: 100%;
        background-color: white;

        app-efa-login {
          max-width: 100%;

          app-efa-navigation {
            max-width: 100%;
          }
        }

      }
    }

    app-efa-category-navigation {
      nav {
        cx-generic-link {
          border-bottom: none !important;

          a {
            text-transform: none;
            font-size: 22px;
            font-weight: 700;
            color: #003899;
          }
        }
      }
    }
  }

  & .SiteLinks {
    align-items: center;
    flex-basis: calc(100% - #{$site-context-basis});
    padding-right: 40px;

    @include media-breakpoint-down(md) {
      background-color: white;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: start;

      app-b2b-unit-summary {
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        border-bottom: 1px solid #B8BCC3;

        p {
          padding: 10px 45px 10px 45px;
        }
      }

      app-efa-header-telephone {
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        border-bottom: 1px solid #B8BCC3;

        cx-generic-link {
          display: block;
          padding: 10px 45px 10px 45px;

          a {
            min-height: auto;
          }
        }
      }

      app-efa-link {
        width: 100%;
        border-bottom: 1px solid #B8BCC3;

        a {
          color: #003899;
          font-size: 16px !important;
          font-weight: 700;
          padding: 10px 45px 10px 45px;

          cx-icon {
            &.small {
              svg {
                height: 20px;
                width: 20px;
              }
            }
          }
        }
      }
    }
  }

  & .SiteContext {
    flex-basis: $site-context-basis;

    @include media-breakpoint-down(md) {
      background-color: white;
      padding: 0;
      width: 100%;

      app-efa-site-context-selector {
        width: 100%;
        margin-bottom: 20px;
        border-bottom: 1px solid #B8BCC3;
        padding: 0 45px 10px 45px;
        label {
          select {
            font-size: 16px;
          }

          cx-icon {
            &.small {
              svg {
                height: 20px;
                width: 20px;
              }
            }
          }
        }

      }
    }
  }

  & .MiniCart.has-components {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-basis: $mini-cart-bases;
    }
  }

  &:has(.MiniCart.has-components) {
    @include media-breakpoint-up(lg) {
      & .SiteLinks {
        flex-basis: calc(100% - #{$site-context-basis + $mini-cart-bases + 25px});
      }
    }
  }

  & .NavigationBar {
    flex: 0;

    @include media-breakpoint-down(md) {
      padding: 0 20px;
    }
  }
}

.header {
  @include media-breakpoint-down(md) {
    background-color: white;
  }
}

header.is-expanded {
  @include media-breakpoint-down(md) {
    box-shadow: none;
    z-index: 3000;

    .header {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      padding: 10px 0;

      cx-hamburger-menu {
        .hamburger-inner {
          background-color: #003899;
        }

        .hamburger-inner::after {
          background-color: #003899;
        }
      }

      .SearchBox {
        .searchbox {
          button.search {
            .fa-search:before {
              color: #003899
            }
          }
        }
      }

      .SiteLogo {
        margin-left: 10px;

        img {
          height: 65px;
        }
      }
    }
  }
}

header.is-expanded:after {
  @include media-breakpoint-down(md) {
    z-index: -1;
    background-color: #3c3c3c;
  }

}

footer {
  cx-paragraph {
    display: none;
  }
}
