/* ==========================================================================
   # Org User Details Component
========================================================================== */

app-efa-org-user-details {
    cx-org-card {
        cx-view {
            .header {
                .title-bar {
                    .title {
                        h3 {
                            text-transform: capitalize;
                        }
                    }
                    .actions {
                        a {
                            text-transform: capitalize;
                        }

                        button {
                            text-transform: capitalize;
                        }

                        cx-org-toggle-status {
                            button {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }
}

app-efa-user-group-details {
    cx-org-card {
        cx-view {
            .header {
                .title-bar {
                    .title {
                        h3 {
                            text-transform: capitalize;
                        }
                    }
                    .actions {
                        a {
                            text-transform: capitalize;
                        }

                        button {
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }
}