/* ==========================================================================
   # DELIVERY NOTES COMPONENT
   ========================================================================== */

$delivery-notes-spacing-base: $spacing-base !default;

app-delivery-notes-overview {
 @extend %cx-order-history;

  .cx-order-history-header {
    padding-top: 0;

    h2 {
      margin-bottom: $delivery-notes-spacing-base;
    }
  }

  .cx-order-history-body {
    h3 {
      margin-bottom: $delivery-notes-spacing-base;
    }
    .cx-order-history-sort{
      padding: 0px;
      cx-sorting{
        ng-select{
          .ng-select-container{
            background-image: url(../../../assets/icons/arrow-expand-down.svg);
            background-position-x: 175px;
            background-position-y: 11px;;
            background-repeat: no-repeat;
            border: none !important;
            box-shadow: none !important;
            .ng-placeholder{
              color:var(--cx-color-primary) !important;
              display: block !important;
            }
            .ng-value {
              display: none !important;
            }
            span.ng-arrow-wrapper{
              display: none !important;
            }
            &:hover{
              box-shadow: none !important;
              border: none !important;
            }
          }
          .ng-dropdown-panel.ng-select-top {
            border-bottom: 2px solid #ccc;
          }
          .ng-dropdown-panel.ng-select-bottom{
            border-top: 2px solid #ccc;
          }
        }
        ng-select.ng-select-focused{
          outline: none !important;
        }
      }
    }

    .download-box {
      cx-icon {
        margin-right:  -12px !important;
      }
    }
  }

  .cx-order-history-form-group {
    align-items: flex-start;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      padding: 1.25rem 0;
    }
  }

  .cx-order-history-pagination {
    @include media-breakpoint-up(md) {
      padding-right: 0;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .cx-order-history-sort.top .cx-order-history-pagination {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .cx-order-history-table {
    tr{
      border: 1px solid transparent;

      @include media-breakpoint-up(lg) {
        border-bottom: 1px solid #dee2e6;
      }
      td:last-child {
        margin-bottom: 25px !important;
      }
    }
    th,
    td {
      padding: 0.75rem !important;
    }
    th{
      font-size: 12px;
      line-height: 17px;
      vertical-align: bottom !important;
    }
    td.cx-order-history-number {
        a{
          text-decoration: underline;
          @include media-breakpoint-down(md) {
            text-decoration: none;
          }
        }
    }
  }

  @include media-breakpoint-up(lg) {
    .cx-order-history-table {
      td:last-child {
        text-align: start;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .cx-order-history-table {
      border: 1px solid transparent;
      box-shadow: none;

      thead {
        display: none;
      }

      tbody tr td {
        @include responsive-column;
        width: 100%;

        @include media-breakpoint-down(sm) {
          border-width: 1px 1px 0 1px;
        }
      }
    }
  }
}

app-delivery-notes-filter {
  @include c-accordion;

  .btn-filter {
    cx-icon svg {
      width: 24px;
      height: 24px;
      margin-bottom: 4px;
    }

    .btn-filter-text {
      font-size: 18px;
    }
  }
}

app-delivery-notes-details{
  .table{
    tbody tr{
      @include media-breakpoint-down(md) {
        border-bottom: 1px solid transparent;
      }
    }
  }
}
