/* ==========================================================================
   # TYPOGRAPHY SETTINGS
   ========================================================================== */

// Font families
$PRIMARY-FONT:                  "EFSans" !default;
$SECONDARY-FONT:                "Canela" !default;
$font-path:                     'fonts/';

// Additional font config
$PRIMARY-FONT-FALLBACKS:          Arial,sans-serif !default;
$SECONDARY-FONT-FALLBACKS:        Georgia !default;

$font-family-base: 'EFSans',$PRIMARY-FONT-FALLBACKS;
$font-family-secondary: 'Canela',$SECONDARY-FONT-FALLBACKS;

// Font sizes
//type — https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss#L225
$font-size-base: 1rem; //16px
// $h1-font-size: $font-size-base * 2.375; //38px
$h1-font-size: $font-size-base * 1.85; //36px
$h2-font-size: $font-size-base * 1.75; //28px
$h3-font-size: $font-size-base * 1.375; //22px
$h4-font-size: $font-size-base * 1.125; //18px
$h5-font-size: $font-size-base; //16px
$small-font-size: $font-size-base * 0.875; //14px

// Font weights
$FONT-WEIGHT-LIGHT:             300 !default;
$FONT-WEIGHT-REGULAR:           400 !default;
$FONT-WEIGHT-SEMI-BOLD:         600 !default;
$FONT-WEIGHT-BOLD:              700 !default;
$FONT-WEIGHT-BLACK:             900 !default;

$font-weight-light: $FONT-WEIGHT-LIGHT;
$font-weight-normal: $FONT-WEIGHT-REGULAR;
$font-weight-semi: $FONT-WEIGHT-SEMI-BOLD;
$font-weight-bold: $FONT-WEIGHT-BOLD;
$font-weight-black: $FONT-WEIGHT-BLACK;

$font-weight-base: $FONT-WEIGHT-REGULAR;
$headings-font-family: $font-family-base;
$headings-font-weight: $font-weight-black;

//update type size and weights
$type: (
  '1': $h1-font-size $font-weight-normal,
  '2': $h2-font-size $font-weight-semi,
  '3': $h3-font-size $font-weight-semi,
  '4': $h4-font-size $font-weight-bold,
  '5': $h5-font-size $font-weight-bold,
  '6': $h5-font-size $font-weight-semi,
  '7': $small-font-size $font-weight-normal,
  '8': $small-font-size $font-weight-bold,
);

// Font weights list for utility classes (utilities.typography)
// Add weights, which should be generated as util classes
$font-weights: (
  light: $font-weight-light,
  regular: $font-weight-normal,
  semi: $font-weight-semi,
  bold: $font-weight-bold,
  black: $font-weight-black
);
