/* ==========================================================================
   # DOWNLOAD ITEMS COMPONENTS
   ========================================================================== */

app-download-items {
  .add-horizontal-line{
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #B8BCC3;
  }
  .downloads-container {
    margin-top: 50px;

    @include media-breakpoint-down(md) {
      margin: 0 10px;
    }

    h3 {
      box-sizing: border-box;
      line-height: 1.22222;
      margin-bottom: 3rem;
      margin-top: 2rem;
      font-weight:$FONT-WEIGHT-BOLD;;
      font-size: 1.75rem;
    }
  }
}

app-download-item {
  display: flex;
  width: 100%;

  .download-item-container {
    display: flex;
    flex-direction: column;
    //align-items: center;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;

    .download-item-box {
      display: flex;
      flex-direction: column;
      align-items: start;

      .download-item-text-wrapper {
        padding-left: $spacing-small;
      }
    }

    .download-item-text-wrapper {
      flex: 1 0 auto;
      padding: $spacing-small;
    }

    .download-item-image img {
      width: 100%;
      //height: 300px;
      object-fit: contain;
      @include media-breakpoint-up(lg) {
        //height: 150px;
      }
      padding: 0;
    }
    .download-item-image.w-75{
      padding: 10px !important;
    }

    .download-item-link {
      width: 100%;
      margin-bottom: 15px;
      .btn {
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 90%;
        max-height: 80%;
        margin: auto;
        padding-top: 0.4rem !important;
        align-content: center;
      }
      .btn-primary {
        a {
          color: $secondary;
        }
      }
    }

    @include media-breakpoint-up(xs) {
      .download-item-image {
        display: flex;
        justify-content: center;

        img {
          width: auto;
          margin: auto;
        }
      }
    }

    .download-item-subtitle {
      font-size: $small-font-size;
    }

    .download-item-title {
      // height: 30px;
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      text-align: center;
      font-weight: $FONT-WEIGHT-BOLD;
      font-size: $font-size-base;
      color: $color-black;
      margin-top: $spacing-small;
    }

    .text-left-aligned {
      text-align: left !important;
      padding-bottom: 0.5rem ;
    }

    .download-item-title:hover {
      text-overflow: inherit;
      overflow: visible;
      display: inherit;
    }
    .download-item-brand {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      color: $color-blue;
      font-size: 0.75rem;
      font-weight: $FONT-WEIGHT-BOLD;
    }
  }
  .download-item-container.w-75{
    width: 85% !important;
    .download-item-link {
      margin-bottom: 10px;
    }
    .download-item-text-wrapper{
      margin-bottom: 15px;
    }
  }
}
