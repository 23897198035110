app-product-badge {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-clip: padding-box;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  text-align: center;
  transform: translate(20px, 60px);
  opacity: 1;
  padding-left: 2px;
  padding-right: 2px;

  > div {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    font-weight: $FONT-WEIGHT-BOLD;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 15px;
  }

  h3 {
    font-size: 13px;
  }

  h4 {
    font-size: 12px;
  }

  h5 {
    font-size: 9px;
  }

  h6 {
    font-size: 6px;
  }

  p,h1,h2,h3,h4,h5,h6 {
    margin-bottom: 0;
  }

  &.list-item-product-badge {
    position: relative;
    transform: translate(0, 0);
    padding-left: 0;
    padding-right: 0;
  }

  &.grid-item-product-badge {
    top: 0;
    left: 0;
    transform: translate(20px, 15px);
  }

  &.product-images-product-badge {
    width: fit-content;
    height: fit-content;
    transform: translate(10px, 30px);
    border-radius: 7px;

    > div {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    p {
      font-size: 12px;
      line-height: 17px;
      font-weight: $FONT-WEIGHT-BOLD;
    }

    h1 {
      font-size: 40px;
    }

    h2 {
      font-size: 30px;
    }

    h3 {
      font-size: 26px;
    }

    h4 {
      font-size: 24px;
    }

    h5 {
      font-size: 18px;
    }

    h6 {
      font-size: 12px;
    }
  }

  &.product-references-product-badge,
  &.product-carousel-product-badge {
    transform: translate(10px, 15px);
    p,h1,h2,h3,h4,h5,h6 {
      line-height: normal;
      padding: 0;
      margin: 0;
      min-height: fit-content;
    }
  }
}
