/* ==========================================================================
   # CART HEADER COMPONENTS
   ========================================================================== */

app-cart-header {
  .c-cart-header {
    padding-top: 2rem;
  }

  .c-cart-header__submit {
    @include media-breakpoint-up(md) {
      padding-top: 2rem;
    }
  }

  @include c-accordion;
}

app-cart-header-summary {
  .header-summary-text {
    font-weight: normal;
    @include media-breakpoint-up(md) {
      padding-left: 1rem;
    }
  }
}

/* style for bootstrap datepicker */

.ngb-dp-header {
  .ngb-dp-arrow-btn {
    height: auto;
    max-height: 2rem;
    min-width: 0;
    font-size: $font-size-base;

    &:focus {
      outline: none;
    }
  }
}

.label-shipping-type-hint {
  color: red;
}
