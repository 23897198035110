/* ==========================================================================
   # CART PRODUCT SEARCH COMPONENTS
   ========================================================================== */

app-cart-product-search {
  .c-product-search {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .c-product-search-container {
    padding-right: $spacing-base;
    padding-left: $spacing-base;

    @include media-breakpoint-up(lg) {
      flex-wrap: unset;
    }
  }

  .c-product-search__submit {
    padding-top: 0;

    @include media-breakpoint-up(md) {
      padding-top: 2rem;
      width: 165px;
    }
  }

  .c-product-search__input-85 {
    @include media-breakpoint-up(md) {
      width: 85px;
    }
  }

  .c-product-search__input-150 {
    @include media-breakpoint-up(md) {
      width: 150px;
    }
  }

  .form-group {
    margin-right: $grid-gutter-width;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .form-check {
    margin-right: $grid-gutter-width;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      padding-top: 1.5rem;
    }
  }

  .manufacturer-type-selection {
    margin-bottom: 10px;
    margin-left: 0px;
  }

  .manufacturer-type-selection-label {
    margin-right: 30px;
    margin-top: 3px;
  }

  .manufacturer-type-selection-check {
    padding-top: 0px;
  }

  @include c-accordion;
}
