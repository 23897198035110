/* ==========================================================================
   # DELIVERY NOTES HEADER COMPONENT
   ========================================================================== */

$dn-details-spacing-medium: $spacing-medium;

.c-review__container {
  margin: 0;
  padding: 0;
}

.c-review__summary-card {
  margin-bottom: $dn-details-spacing-medium;
  .delivery-note-number {
    .cx-card {
      .cx-card-body  {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 1rem 0 !important;
        .cx-card-title,.cx-card-label-container{
          font-size: 22px !important;
          font-weight: 700;
          margin: 0px !important;
          line-height: 20px !important;
        }
       h3.cx-card-title::after  {
            content: ':';
            position:absolute;
            top: 0px;
            right: -10px;
        }
        h3.cx-card-title{
          position: relative;
        }
        .cx-card-label-container{
          margin-left: 1rem !important;
        }
      }
    }

  }
  .date-section,.name-section{
    .cx-card {
      .cx-card-body  {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 1rem 0 !important;
        .cx-card-title,.cx-card-label-container{
          // font-size: 22px !important;
          // font-weight: 700;
          margin: 0px !important;
          line-height: 20px !important;
        }
        .cx-card-title{
          font-size: 1.125rem;
          font-weight: var(--cx-font-weight-bold);
        }
        .cx-card-label-container{
          margin-left: 1rem !important;
        }
      }
    }
  }
}

.address-section{
  .cx-card {
    .cx-card-body  {
      padding: 1rem 0 !important;
      .cx-card-title{
        font-size:1.125rem;
        font-weight: var(--cx-font-weight-bold);
      }
    }
  }
}

