/* ==========================================================================
   # TOP BORDER 
   ========================================================================== */

$border-color:         $color-grey-light !default;

@mixin border-top() {
  margin-top: 0;
  padding-top: 2rem;
  border-top: 1px solid $border-color;

  @include media-breakpoint-down(md) {
    margin-top: 1rem;
    padding-top: 1rem;
  }
}
