app-parts-link-access-form{
  #partsLinkAccessForm{
    margin-bottom: $spacing-wrapper;
  }
  .form-header{
    display: flex;
    justify-content: center;
    margin-bottom: $spacing-huge;

    p {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: 0px;
      color: #000000;
      flex: none;
      order: 4;
      flex-grow: 0;
    }
  }
  .line1{
    border: 0.5px solid #B8BCC3;
   //  margin-bottom:$spacing-small ;
   }
   form fieldset {
     margin-bottom: $spacing-wrapper;
   }
    legend {
     padding-top:$spacing-small ;
     font-size: $h3-font-size;
     font-weight:$FONT-WEIGHT-BOLD ;
   }
   .partsLinkSubmit{
    margin-bottom: 2rem;
   }

   .partsLinkReset{
    button {
      border: 1px solid transparent;
      color: $color-blue;
    }
    button:hover{
      color: $color-blue;

    }

   }

   .col-dealer-data-country {
    @include media-breakpoint-up(md) {
      padding-right: 0;
    }
   }

   .col-dealer-data-postal-code {
    @include media-breakpoint-up(md) {
      padding-right: 0;
    }
   }
}
