/* ==========================================================================
   # CART ITEM COMPONENTS
   ========================================================================== */

$table-media-size: 65px;
$cart-item-consignment-info-spacing-small: $spacing-small;

app-efa-cart-item-list {
  @extend %cx-cart-item-list;

  app-efa-cart-item{
    .searched-article-info{
      display:flex;
      align-items: center;
      flex-wrap: wrap;
      .substitutedOemNumber{
        color: #139053;
      }
      button{
        display: inline-block;
        width: 30px;
        height: 30px;
        background-color: transparent;
        border: none;
        background-image: url(../../../assets/icons/greenInfoCircleOutline.svg);
        background-position-x: 5px;
        background-position-y: 3px;;
        background-repeat: no-repeat;
      }
    }

    @include media-breakpoint-up(lg) {
      &.has-subentries {
        > td {
          padding-bottom: 0 !important;

          &.article-parent {
            padding-left: 30px;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      .cx-name{
        margin-top: 0px;
        a{
          min-height: unset;
        }
      }
      .cx-code.article-info{
        margin-top: 0px;
      }
    }
  }
  cx-media,
  cx-media.is-missing {
    width: $table-media-size;
    height: $table-media-size;
  }

  .fields-required {
    font-size: 0.75rem;
    border-top: 0;
    padding-top: 0;
  }

  @include media-breakpoint-up(lg) {
    .table{
      margin: 0px;
      thead{
        tr{
          border-top: none;
          th{
            padding: .75rem;
          }
        }
      }
    }
    .table td {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      border-top: 1px solid #dee2e6;

      &.article{
        .cx-name{
        }
        .article-info{
          margin-top: 0px;
        }
      }
      &.align-center {
        text-align: center;
      }

      &.quantity {
        width: 150px;
        text-align: center;

        app-efa-item-counter.readonly input {
          width: 35px;
        }
      }

      &.status {
        text-align: center;
      }

      .cx-value {
        padding-inline-start: 0;
      }

      &.price,
      &.total {
        width: 150px;
        white-space: nowrap;
      }

      &.warehouse {
        white-space: nowrap;
        text-align: center;
      }

      &.article {
        width: 365px;
      }
    }
    .table th {
      font-size: var(--cx-font-small, 0.8rem);
      white-space:unset;
    }
    .table th:first-child{
      width: 24%;
    }
  }

  .consignment-info {
    display: inline-block;
    margin-top: $cart-item-consignment-info-spacing-small;
  }
}

/* style for responsive table in mobile Viewport */

@include media-breakpoint-down(md) {
  .table--responsive {
    display: table;
    border: 1px solid transparent;
    box-shadow: none;

    thead {
      display: none;
    }

    tbody {
      app-cart-sub-entry,
      app-efa-cart-item,
      app-order-info-fields,
      .mandatory-message {
        td {
          @include responsive-column;
          margin-inline-start: 0rem;
          padding: 12px;
          border-top: 1px solid #dee2e6;
        }
      }

      app-cart-sub-entry td {
        border-left: 0;
        border-right: none;
      }

      app-cart-sub-entry td.col-empty {
        display: none;
      }

      .mandatory-message {
        td:first-child {
          display: none;
        }
      }


      app-order-info-fields + .mandatory-message,
      app-order-info-fields + app-order-info-fields {
        position: relative;
        top: -20px;
      }

      app-order-info-fields + app-order-info-fields + .mandatory-message{
        top: -32px;
      }
    }
  }

  .table--responsive tbody .mandatory-message td:last-child {
    padding-top: $table-spacing-small;
  }
}

/* fix for item counter component on cart page */
%cx-item-counter {
  width: auto;
}
