/* ==========================================================================
   # CART DETAILS COMPONENTS
   ========================================================================== */

app-efa-cart-details {
  @extend %cx-cart-details;

  .cx-total {
    @include type('4');
    margin: 0 0 1rem;
  }
  .cart-details-wrapper {
    padding-top: 0;
    padding-bottom: 0;

    .cart-name {
      padding-bottom: 0.75rem;
      border-bottom: 1px solid $color-grey-light;
      margin-bottom: 1.5rem;
      @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }
    }
  }
}
