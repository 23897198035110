app-efa-update-profile{
  form {
    .title-container{
      input {
        background-color: #e9ecef;
      }
      input[type=radio]:checked{
        background-color: #e9ecef;
        border: 2px solid var(--cx-color-light) !important;
      }
      input[type=radio]:checked::after {
        background-color: var(--cx-color-light) ;
      }
    }
  }
}
