/* ==========================================================================
   # WARRANTY PROCESS COMPONENT
   ========================================================================== */

app-warranty-process-formular {
  .required .label-content::after {
    content: "*";
    color: black;
  }
  fieldset{
    margin-bottom: $spacing-huge;
    legend {
      padding-left: 5px !important;
      .warranty-process_fieldset-legend {
        border-top: 1px solid $color-grey-light;
        padding-top: 0.75rem;
        margin-bottom: $spacing-medium;

        font-size: 22px;
        font-weight: 700;
        line-height: 1.4em;
        display: flex;
        white-space: pre-wrap;
      }
    }
    textarea{
      @include media-breakpoint-down(sm) {
        min-height: 100px;
      }
    }
  }

}

