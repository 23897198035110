cx-split-view{
  @extend %cx-split-view !optional;
}
app-efa-manage-users-list{
  @extend %cx-table !optional;
  .vertical-stacked tbody {
    // border-top: solid 1px transparent !important;
    // border-bottom: solid 1px var(--cx-color-light) !important;

  }
  .list{
    .header{
      padding: 0px !important;
      margin-bottom: 1rem;
      .title{
        h3{
          text-transform: none !important;
          margin: 0 0 20px 0;
        }
      }
      .actions{
        a{
          width: 350px;
          padding-top: 12px;
          border-radius: 30px;
          text-transform: none !important;
          @include media-breakpoint-down(sm) {
          width: 75%;
          }
        }
        @include media-breakpoint-down(sm) {
          justify-content: center !important;
          }
      }
    }
    cx-table{
      table {
        border-top: none !important;
        tbody{
          display: block;
          margin-bottom: 2rem;
          border: 1px solid #DEE2E6;
          border-bottom: 1px solid #DEE2E6;
          padding-bottom: 40px;

          @include media-breakpoint-down(md) {
            background-image: url("../../../assets/icons/arrow-forward.png") !important;
            background-position: calc(100% - 26px) 96%;
            background-repeat: no-repeat;
            background-size: 24px !important;

          }

        }
        tr {
          th {
            vertical-align: bottom;
            border-bottom: 1px solid #DEE2E6;
            padding: 0px;
            @include media-breakpoint-down(md) {
              padding-inline-start: 20px;
            }
          }
          td{
            border-bottom: 1px solid #DEE2E6;
            padding-bottom: 0px !important;
            padding-top: 0px !important;
            a{
              @include media-breakpoint-down(md) {
                min-height: 40px;
                justify-content: end;
                }
                span {
                  @include media-breakpoint-down(md) {
                    padding-inline-end: 0px;
                    }
                }
            }

          }

          cx-org-status-cell{
            a{
              span{
                color: white;
                background-color: #FB4764;
                width: 50px;
                height: 24px;
                border-radius: 3px;
                text-align: center;
                font-size: 12px;
                padding: 2px;
                @include media-breakpoint-down(md) {
                  width: 85px;
                  }
              }
              span.is-active{
                color: white;
                background: #139053;
                width: 42px;
                height: 24px;
                border-radius: 3px;
                text-align: center;
                font-size: 12px;
                padding: 2px;
                @include media-breakpoint-down(md) {
                  width: 85px;
                  }
              }
            }
          }
          app-efa-active-link-cell{
            a {
              text-decoration: none;
              span{
                border-bottom: 1px solid var(--cx-color-primary);
                display: block;
                margin-bottom: 0px;
                line-height: 18px;
                padding-inline-end: 0px;
                @include media-breakpoint-down(md) {
                  border-bottom: 1px solid transparent;
                  }
              }
            }
          }
          app-efa-list-cell{
            position: relative;
            a{
              background-image: url("../../../assets/icons/arrow-forward.png") !important;
              background-position: calc(100% - 26px) 50%;
              background-repeat: no-repeat;
              background-size: 24px !important;
              @include media-breakpoint-down(md) {
                background-image: none !important;

              }
            }
          }
        }
      }
    }
  }

  cx-split-view:not([active-view='1']) {
    cx-view{
      // padding-inline-end: 0px !important;
      border-inline-end: none !important;

    }
    cx-table{
      table {
        thead {
          th{
            width: 15%;
            font-size: 12px;
          }
          th:nth-child(1){
            width: 20% !important;
            cx-table-header-cell{
              margin-inline-start: 10px;
            }
          }
          th:nth-child(2){
            width: 8% !important;
          }
          th:nth-child(3){
            width: 13% !important;
            cx-table-header-cell{
              text-wrap:wrap;
            }
          }
          th:nth-child(4){
            width: 10% !important;
            cx-table-header-cell{
              text-wrap:wrap;
            }
          }
          th:nth-child(5){
            width: 17% !important;

          }
        }
        tr {
          td {
            a{
              width: 90%;
            }
          }
          td:first-child{
            app-efa-active-link-cell{
              a{
                span{
                  margin-inline-start: 10px;
                }
              }
            }
          }
          td:last-child{
            a{
              padding-inline-end: 10px;
            }
          }
        }
        app-efa-list-cell{
          a{
            background-position: calc(100% - 0px) 50%;
          }
        }
      }
    }
  }

  cx-split-view:not([active-view='2']) {
    cx-table{
      table {
        thead {
          th{

          }
          th:nth-child(1){
            width: 25% !important;
          }
          th:nth-child(2){
            width: 10% !important;
          }
          th:nth-child(3){
            width: 15% !important;
          }
          th:nth-child(4){
            width: 25% !important;
          }
          th:nth-child(5){

          }
        }
      }
    }
  }

  .horizontal-line-devider {
    width: 100%;
    margin: 1rem 0px;
    border-bottom: 1px solid #B8BCC3;
  }
}
