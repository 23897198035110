/* ==========================================================================
   # CART IMPORT SELECTION COMPONENTS
   ========================================================================== */

app-cart-import-selection {
  overflow-y: auto !important;
  .modal-xl {
    width: 1500px;
  }

  .table thead th {
    text-align: left;
  }

  .table tr {
    border-bottom: 0;
  }

  .cx-modal-content{
    .modal-body{
      height: 510px;
    }
  }

  .btn-close {
    min-width: 120px;
  }

  .btn-import {
    min-width: 130px;
  }

  .duplicate-results-hint {
    @include media-breakpoint-up(lg) {
      padding-left: 0;
    }
  }
}
