$caretSize: 9px;

app-efa-site-context-selector {
  @extend %cx-site-context-selector;

  margin-bottom: 16px;
  margin-inline-end: 8px;

  label {
    color: $primary;
    span {
      @include sr-only();
    }
  }

  cx-icon {
    color: inherit;
  }

  select {
    color: inherit;
    font-size: $small-font-size;
    font-weight: 700;
    text-transform: uppercase;

    padding-right: $caretSize * 2;

    background-color: transparent;
    @include media-breakpoint-up(lg) {
      background-color: transparent;
    }
  }

  cx-icon {
    pointer-events: none;
    display: flex;
    align-items: center;

    &[type="GLOBE"] {
      margin-inline-end: $spacing-tiny;
      margin-inline-start: 0;
    }
  }
}
