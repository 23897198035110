/* ==========================================================================
   # PRODUCT COMPONENTS
   ========================================================================== */

app-efa-product-list-item,
app-efa-product-grid-item {
  .manufacturerAID {
    &:hover {
      text-decoration: none;
    }
    text-align: center;
    color: black;
  }

  .product-no-price {
    @include type('4');
    margin: 1rem 0;
  }
}

app-efa-product-list-item {
  .manufacturerAID {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
