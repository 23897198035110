/* ==========================================================================
   # IMPORT FILE OBJECTS
   ========================================================================== */

.o-import-file {
  display: flex;
  align-items: center;
}

.o-import-file__input {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 95%;
}

.o-import-file__remove {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 5%;
  padding-left: $spacing-base;
  font-size: $h3-font-size;
}
