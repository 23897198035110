/* ==========================================================================
   # ADD TO CART COMPONENTS
   ========================================================================== */

app-efa-added-to-cart-dialog {
  @extend %cx-added-to-cart-dialog;

  .cx-dialog-row {
    padding-inline-start: 0.875rem;
  }
}
