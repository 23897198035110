/* ==========================================================================
   # CART PRODUCT SEARCH RESULT COMPONENTS
   ========================================================================== */

$table-spacing-tiny: $spacing-tiny !default;
$outer-container-size: 250px;
$max-height-modal-body: 426px;
$min-height-modal-body: 350px;

app-cart-product-search-result {
  .table th, .table td {
    vertical-align: middle;
    text-align: left;
  }

  .table td {
    line-height: 1;
  }

  .table tr.has-message {
    border-bottom: 0;
    background-color: $color-grey-light;

    @include media-breakpoint-down(md) {
      td:first-child {
        display: none;
      }
      
      td:last-child {
        padding-bottom: 0;
        margin-bottom: 0 !important;
      }
    }
  }

  .table tr.message-row {
    td {
      border-top: 0;
    }

    background-color: $color-grey-light;
    color: $primary;
    font-size: $small-font-size;
    font-weight: $FONT-WEIGHT-BOLD;

    @include media-breakpoint-down(md) {
      td:first-child {
        display: none;
      }

      td:last-child {
        padding-top: 0;
        margin-bottom: 0 !important;
        text-align: left;
      }
    }
  }

  input[type=checkbox],
  input[type=radio] {
    position: relative;
    margin-top: 0;
    margin-inline-start: 0;
  }

  .form-check {
    margin-bottom: 0;
    padding-inline-start: 0;
    padding-left: 0;
  }

  .modal-body {
    max-height: $max-height-modal-body;
    min-height: $min-height-modal-body;
    padding: 1rem;
  }

  .outer-container {
    overflow-y: auto;
    min-height: $outer-container-size;
  }

  thead th {
    position: sticky;
    top: 0;
    background-color: var(--cx-color-inverse);
    z-index: 1;
  }
}
