/* ==========================================================================
   # COLORS SETTINGS
   ========================================================================== */

// Colors Brand
$__blue__:                                #003899;
$__blue__light:                           #1D63DE;
$__black__:                               #000000;
$__black-light__:                         #0000000D;
$__black-dark-semi__:                     #00000014;
$__white__:                               #FFFFFF;

$__yellow__:                              #D8CA00;
$__yellow-lighter__:                      #FFFF00;
$__yellow-light__:                        #F8E700;
$__yellow-light-semi__:                   #FAD246;
$__red__:                                 #E64545;
$__red-light-semi__:                      #FB4764;
$__red-light__:                           #FF7F76;
$__purple__:                              #496CF6;
$__purple-light__:                        #7BA9FF;
$__green__:                               #00B373;
$__green-light__:                         #69D2AD;
$__green-dark__:                          #139053;

$__grey__:                                #9E9E9E;
$__grey-dark__:                           #3C3C3C;
$__grey-dark-semi-third-degree__:         #7E8490;
$__grey-dark-semi-second-degree__:        #4F596C;
$__grey-dark-semi__:                      #808080;
$__grey-light__:                          #B8BCC3;
$__grey-light-semi__:                     #C3C5C8;
$__grey-light-super-semi__:               #DEE1E7;
$__grey-light-super-second-degree__:      #DEE2E6;
$__grey-light-super__:                    #EAECF1;
$__grey-lighter__:                        #EBEBEB;
$__grey-lightest__:                       #F5F5F5;

// Colors Semantic
$color-blue:                              $__blue__;
$color-blue-light:                        $__blue__light;
$color-white:                             $__white__;
$color-black:                             $__black__;
$color-black-dark-semi:                   $__black-dark-semi__;
$color-black-light:                       $__black-light__;

$color-yellow:                            $__yellow__;
$color-yellow-light:                      $__yellow-light__;
$color-yellow-lighter:                    $__yellow-lighter__;
$color-yellow-light-semi:                 $__yellow-light-semi__;
$color-red:                               $__red__;
$color-red-light-semi:                    $__red-light-semi__;
$color-red-light:                         $__red-light__;
$color-purple:                            $__purple__;
$color-purple-light:                      $__purple-light__;
$color-green:                             $__green__;
$color-green-light:                       $__green-light__;
$color-green-dark:                        $__green-dark__;

$color-grey:                              $__grey__;
$color-grey-dark:                         $__grey-dark__;
$color-grey-dark-semi-third-degree:       $__grey-dark-semi-third-degree__;
$color-grey-dark-semi-second-degree:      $__grey-dark-semi-second-degree__;
$color-grey-dark-semi:                    $__grey-dark-semi__;
$color-grey-light:                        $__grey-light__;
$color-grey-light-semi:                   $__grey-light-semi__;
$color-grey-light-super-semi:             $__grey-light-super-semi__;
$color-grey-light-super-second-degree:    $__grey-light-super-second-degree__;
$color-grey-light-super:                  $__grey-light-super__;
$color-grey-lighter:                      $__grey-lighter__;
$color-grey-lightest:                     $__grey-lightest__;


