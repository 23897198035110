/* ==========================================================================
   # WISH LIST COMPONENT
   ========================================================================== */

$table-header-border: 1px solid $color-grey-light;
$table-row-border: 1px solid $color-grey-light-super-semi;
$table-row-border-small-screen: 1px solid $color-grey-light-super-second-degree;

app-efa-wish-list {
  .c-wish-list-header {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom: $table-header-border;
    @include media-breakpoint-up(lg) {
      font-size: 12px;
    }
  }

  .c-wish-list {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
    border-bottom: $table-row-border;
  }

  .c-wish-list__info-container {
    align-items: center;

    * {
      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: $spacing-tiny;
      }
    }

    .btn-remove {
      justify-content: center;
      text-decoration: none;
      color: $color-black;
      &:hover {
        color: $primary;
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 0px !important;
      }
    }

    .data-row {
      @include media-breakpoint-down(sm) {
        border: $table-row-border-small-screen;
        align-items: center;
        .cx-value {
          align-items: center;
        }

        &.data-row-last {
          margin-bottom: 15px;
        }

        padding-bottom: 0px;
      }
    }
  }

  .label-content {
    align-self: center;
  }

  .cx-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(sm) {
      margin-left: $spacing-small;
    }
  }

  .active-filter-items {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;

    .btn-item {
      display: flex;
      align-items: center;
      transition: all 0.3s;
      font-weight: $font-weight-light;
      font-size: $small-font-size;
      min-width: 0;
      border-radius: 10px;
      border: 1px solid $color-grey-light-super;
      background-color: $color-grey-light-super;
      color: $primary;
      margin-top: 0;
      margin-inline-end: 10px;
      margin-bottom: 10px;
      margin-inline-start: 0;

      > span {
        margin-inline-start: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      cx-icon {
        padding: 10px 0px 10px 10px;
      }

      @include media-breakpoint-up(md) {
        font-size: 12px;
        line-height: 16px;
        max-height: 30px;
      }
    }
  }

  app-efa-add-to-cart {
    @include media-breakpoint-down(sm) {
      width: 100%;
      form {
        width: 100%;
        button[type=submit] {
          justify-content: center;
          min-width: 100%;
        }
      }
    }
  }

  .back-to-top-box{
    display: flex;
    justify-content: center;
    margin-right: -15px;
    margin-left: -15px;
    // border-top: 1px solid #dee2e6;
    .wishlist-back-to-top{
      width: 250px;
      color: $color-blue;
      margin: 40px auto 80px auto;
      padding: 11px 0px;

      @include media-breakpoint-down(sm) {
        width: 75%;
      }
    }
  }

  .empty-wish-list {
    display: flex;
    border-bottom: 1px solid $color-grey-light;
    justify-content: flex-start;
    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }
}
