/* ==========================================================================
   # Elements HEADING
   ========================================================================== */

h1, .h1 {
  font-family: $font-family-base;
}

@include media-breakpoint-up(lg) {
  cx-navigation-ui {
    flex-flow: nowrap;

    > nav {
      padding-right: 20px;

      > ul {
        display: flex;

        > li {

          padding-right: 20px;

          > cx-generic-link > a {
            padding-top: 20px;
            padding-inline-end: 15px;
            padding-bottom: 22px;
            padding-inline-start: 0;
          }
        }
      }
    }

  }

  nav > div > cx-generic-link {
    padding: 10px 0;
  }
  div.childs > nav > cx-generic-link > a {
    padding: 5px 0;
  }
}
