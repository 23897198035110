/* ==========================================================================
   # UTILITIES COLORS
   ========================================================================== */

/**
 * Utility classes to put specific color and background values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-color-primary {}
 *   .u-color-secondary {}
 *   .u-color-green {}
 *   .u-bg-primary {}
 *   .u-bg-secondary {}
 *   .u-bg-green {}
 */

@each $color-name, $color-value in $theme-colors {
  .u-color#{-#{$color-name}} {
    color: #{$color-value} !important;
  }
}

@each $color-name, $color-value in $theme-colors {
  .u-bg#{-#{$color-name}} {
    background-color: #{$color-value} !important;
  }
}
