/* ==========================================================================
   # BUTTONS COMPONENTS
   ========================================================================== */

.btn {
  font-size: 1rem;
  font-weight: 600;
  text-transform: none;
  border-radius: 500px;
  border: 1px solid #003899;
  padding: 11px 1rem;
  line-height: 1.34;
}

.input-group-append > .btn {
  border-radius: 3px;
}

.btn-secondary
{
  color: $primary;
}

.btn-link {
  border: none;
  color: $primary;
  padding-inline: 0;

  &:focus {
    box-shadow: none;
  }
}

