/* ==========================================================================
   # CONSENT MANAGEMENT COMPONENTS
   ========================================================================== */

app-efa-consent-management {
  @extend %cx-consent-management;

  cx-consent-management-form{
    .form-check {
      margin-bottom: 2.5rem;
      input[type=checkbox][disabled] {
        background: gray;
        border: 1px solid transparent !important;
      }

      input[type=checkbox][disabled] + .form-check-label {
        color: var(--cx-color-light) !important;
      }

      .form-check-label{
        color: $color-black !important;
      }
    }
  }
}

app-efa-anonymous-consent-management-banner {
  @extend cx-anonymous-consent-management-banner;
}

app-efa-anonymous-consent-dialog {
  @extend cx-anonymous-consent-dialog;
}

app-efa-consent-management-form {
  @extend cx-consent-management-form;
}
