ngb-datepicker{
  .ngb-dp-header{
    .ngb-dp-arrow{
      align-items: center;
    }
    .ngb-dp-navigation-select{
      .form-select{
        display: inline-block;
        width: 100%;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        vertical-align: middle;
        // background: #fff url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e) right .75rem center/8px 10px no-repeat;
        border: 1px solid #b8bcc3;
        border-radius: .25rem;
        appearance: none;
        background-image: url(../../../assets/icons/datepickerIArrow.svg);
        background-position-x: calc(100% - 10px);
        background-position-y: center;
        background-size: 8px;
        background-repeat: no-repeat;
        &:focus{
          outline-offset: 0px;
        }
      }
    }
  }
}
