/* ==========================================================================
   # CHECKOUT TEMPLATE ELEMENTS
   ========================================================================== */

/*
 * [1] deep overwriting of the spartacus styling to get the BodyContent on full width
 */

%MultiStepCheckoutSummaryPageTemplate {
  max-width: var(--cx-page-width-max);
  padding: 0 1.5rem;
  margin: auto;
  display: flex;
  flex-wrap: wrap;

  .TopContent {
    --cx-flex-basis: 68%;
    flex-direction: column;
    flex-wrap: nowrap;

    @include media-breakpoint-down(md) {
      --cx-flex-basis: 100%;
    }
  }

  cx-page-slot {
    &.BodyContent {
      flex: 0 100% !important; // [1]
    }

    &.SideContent {
      display: none;
    }
  }
}
