/* ==========================================================================
   # CAROUSEL COMPONENTS
   ========================================================================== */

$default-product-carousel-indicator-background-color: $color-grey-light-super;
$default-product-carousel-active-indicator-background-color: $color-grey-dark-semi-second-degree;

app-efa-product-carousel {
  @extend %cx-product-carousel;

  div {
    a {
      flex-wrap: wrap;
      text-align: start;
      margin-left: $spacing-small;
      margin-right: $spacing-small;
      height: 100%;

      h3 {
        font-size: 16px;
      }
    }
  }

  cx-media {
    height: 230px;
    width: 230px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
      object-fit: contain;
    }

    &.is-missing {
      height: 230px;
      width: 230px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  cx-carousel {
    justify-content: center !important;

    .item.active {
      display: flex;
      flex-direction: row-reverse;
    }

    .slide.active {
      justify-content: flex-start !important;

      @include media-breakpoint-down(md) {
        justify-content: center !important;
      }
    }

    h2 {
      border-top: 1px solid $color-grey-light;
      text-align: start;
      padding-top: 2rem;
    }
  }
}

app-efa-product-references {
  @extend %cx-product-references;

  a {
    flex-wrap: wrap;
    text-align: start;
    margin-left: $spacing-small;
    margin-right: $spacing-small;
    height: 100%;

    h3 {
      font-size: 16px;
    }
  }

  cx-media {
    height: 230px;
    width: 230px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
      object-fit: contain;
    }

    &.is-missing {
      height: 230px;
      width: 230px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  cx-carousel {
    .item.active {
      display: flex;
      flex-direction: row-reverse;
    }

    .slide.active {
      justify-content: flex-start !important;

      @include media-breakpoint-down(md) {
        justify-content: center !important;
      }
    }

    h2 {
      border-top: 1px solid $color-grey-light;
      text-align: start;
      margin-left: 3rem;
      margin-right: 3rem;
      padding-top: 2rem;

      @include media-breakpoint-down(md) {
        margin-left: 2.25rem;
        margin-right: 2.25rem;
        padding-bottom: 2rem;
      }

      @include media-breakpoint-down(sm) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }
}

app-efa-product-carousel,
app-efa-product-references {
  cx-carousel {
    .item {
      &.active {
        padding-left: 8px;
        padding-right: 8px;
      }
    }


    .carousel-item-container {
      margin-bottom: 10px;
      box-shadow: 0px 5px 19.5px 0px $color-black-dark-semi;
      max-width: 261px;
      min-width: 261px;
      max-height: 458px;
      min-height: 458px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-name {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 240px;
        height: 40px;
        display: block;
      }

      .manufacturerAID {
        font-size: 14px;
        margin-bottom: 0.5rem;
      }

      .price {
        display: flex;
        justify-content: flex-start;
        font-size: 18px;
        line-height: 26px;
        font-weight: $FONT-WEIGHT-BOLD;
        margin-left: $spacing-small;
        margin-right: $spacing-small;
        padding-left: 8px;
        height: 30px;
      }

      app-efa-cart-item-add-to-wish-list {
        position: absolute;
        text-align: end;
        transform: translate(223px, 15px);
        z-index: 10;
      }

      .carousel-item-container-bottom-content {
        display: flex;
        justify-content: center;
        align-items: end;
        margin-bottom: 10px;

        @include media-breakpoint-down(md) {
          align-items: start;

          button {
            padding: 11px 1rem;
          }
        }

        app-efa-add-to-cart {
          width: 90%;

          button.submit-btn {
            height: 48px;
            max-height: 48px;
            text-align: start;
            padding: 11px 0.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            .btn-text {
              display: none;
              color: inherit;
              background-color: inherit;
            }

            @media only screen and (min-width: 1100px) {
              .btn-text {
                display: inline;
              }
            }

            @include media-breakpoint-down(sm) {
              .btn-text {
                display: inline;
              }
            }
          }
        }

        @include media-breakpoint-up(md) {
          margin-top: 10px;
        }
      }
    }

    .carousel-panel {
      @for $i from 1 through 10 {
        &.size-#{$i} .item {
          flex: 0 0 24%;
        }
      }
    }
  }

  .indicators {
    button {
      .cx-icon {
        &::before {
          display: none;
        }

        border: 4px solid $default-product-carousel-indicator-background-color;
        color: $default-product-carousel-indicator-background-color;
        background-color: $default-product-carousel-indicator-background-color;
        border-radius: 20px;
        padding: 2px;
      }

      &[disabled] {
        .cx-icon {
          border: 4px solid $default-product-carousel-active-indicator-background-color;
          color: $default-product-carousel-active-indicator-background-color;
          background-color: $default-product-carousel-active-indicator-background-color;
        }
      }

      padding-right: 0;
    }
  }
}
