/* ==========================================================================
   # PARAGRAPH COMPONENT
   ========================================================================== */

app-efa-paragraph {
    @extend cx-paragraph;

  .box-shadow {
      box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.0784313725);
    }
 }
