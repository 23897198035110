/* ==========================================================================
   # CANCEL ORDER COMPONENTS
   ========================================================================== */

$order-cancel-or-return-items-frame-border: 1px solid $color-grey-light;
$order-cancel-or-return-items-entries-table-border: 1px solid $color-grey-light;
$order-cancel-or-return-items-selection-hint-border: 1px solid $color-grey-light;
$order-cancel-or-return-items-header-border: 1px solid $color-grey-light-semi;

app-efa-cancel-or-return-items {
  .frame{
    @include media-breakpoint-up(lg) {
      border: $order-cancel-or-return-items-frame-border;
      border-top: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-bottom: 30px;
    }

    .cancel-or-return-list {
      @include media-breakpoint-up(md) {
        padding: $spacing-medium $spacing-medium 0;
        .cancel-or-return-list-selection-hint {
          .cancel-or-return-list-selection-hint-text {
            display: flex;
            justify-content: end;
            margin-bottom: 20px;
            margin-top: 1rem;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        .cancel-or-return-list-selection-hint {
          .cancel-or-return-list-selection-hint-text {
            display: flex;
            justify-content: center;
            border-bottom: $order-cancel-or-return-items-selection-hint-border;
            padding-bottom: 16px;
            margin-bottom: 8px;
          }
        }
      }
    }


    .c-list-items-header {
      .c-list-items-header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: $order-cancel-or-return-items-header-border;

        .c-list-items-header-text {
          font-weight: $FONT-WEIGHT-BOLD;
          margin-bottom: 0px;
          display: flex;
          align-self: center;
          padding-bottom: 12px;
          &--required::after {
            content: "*";
          }

          @include media-breakpoint-up(md) {
            padding-left: 4px;
          }
        }

        .c-list-items-header-check-box {
          display: flex;
          flex-direction: row-reverse;
          input[type=checkbox] {
            position: relative;
            margin-top: 0;
            margin-inline-start: 0;
          }

          .c-list-items-header-check-box-text {
            margin-right: 5px;
            color: $primary;
            cursor: pointer;
          }
        }

      }

      @include media-breakpoint-up(lg) {
        padding: $spacing-medium $spacing-medium 0;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .table {
      thead tr th {
        padding: 12px;
        font-size: 12px;
        border-bottom: $order-cancel-or-return-items-entries-table-border;
      }

      tbody tr td {
        border-bottom: $order-cancel-or-return-items-entries-table-border;
        padding: 12px;

        &.table-col-quantity {
          min-width: 220px;
        }

        &.table-col-consignment-info {
          min-width: 200px;
        }

        &.table-col-select {
          min-width: 20px;
          max-width: 40px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .table--responsive {
      border: 1px solid transparent;
      box-shadow: none;

      thead {
        display: none;
      }

      tbody tr td {
        @include responsive-column;
        width: 100%;
        border-top: 1px solid #dee2e6;
        @include media-breakpoint-down(sm) {
          border-width: 1px 1px 0 1px;
        }
      }
      tbody tr{
        border-bottom: 1px solid transparent
      }
    }
  }
}

app-efa-amend-order-actions {
  @extend %cx-amend-order-actions;

  @include media-breakpoint-up(md) {
    .col-cancel-btn {
      max-width: 356px;
    }
  }
}
