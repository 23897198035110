/* ==========================================================================
   # TERMS AND CONDITIONS CONFIRMATION COMPONENTS
   ========================================================================== */

app-terms-and-conditions-confirmation-action {
  @include media-breakpoint-down(sm) {
    .tac__btn-wrapper + .tac__btn-wrapper {
      margin-top: $spacing-base;
    }
  }
  
  .tac {
    margin-bottom: $spacing-large;
    
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      margin-bottom: $spacing-huge;
    }
  }
}
