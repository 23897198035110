/* ==========================================================================
   # CONTAINERS
   ========================================================================== */

$content-box-bg-color:         $color-grey-lightest !default;
$content-box-spacing-medium:   $spacing-medium !default;

@mixin content-box() {
  background-color: $content-box-bg-color;
  margin: 0 0 2rem 0;
  padding: $content-box-spacing-medium $content-box-spacing-medium 0;
}
