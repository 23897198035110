/* ==========================================================================
   # RETURN CREATION COMPONENT
   ========================================================================== */

$returnable-creation-input-width: 300px;

app-return-creation {
  .return-creation-container{
    border: 1px solid #B8BCC3;
    border-radius: 4px;
    padding: 2rem;
    margin-bottom: $spacing-huge;
    @include media-breakpoint-down(md) {
      border: 1px solid transparent;
      padding: 0px;
      margin-bottom: 0px;
    }
    .table-title{
      width: 100%;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      border-bottom: 1px solid #dee2e6;
      padding: 10px 1rem;
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
        padding: 2rem 1rem;
      }
    }
    .mandatory-text{
      overflow: hidden;
      @include media-breakpoint-down(md) {
        margin-bottom: 25px;
      }
      span{
        float: right;
        font-size: 1rem;
        font-weight: 400;
      }
    }

  }
  .c-delivery-note-details__table {
    width: 100%;
    thead th{
      text-align: start;
      border-bottom:  1px solid #dee2e6;
      font-size: 12px;
      font-weight: 700;
      padding: 32px 0 0 12px;
      @include media-breakpoint-down(md) {
        border-bottom: 1px solid transparent;
      }
    }
    tbody tr {
      @include media-breakpoint-down(md) {
        display: block;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    tbody td {
      vertical-align:middle;
      border-bottom:  1px solid #dee2e6;
      &:last-child {
        @include media-breakpoint-up(lg) {
          width: 23%;
        }
      }
      p{
        margin-bottom: 0rem !important;
      }
      @include media-breakpoint-down(md) {
        @include responsive-column;
        width: 100%;
        border-bottom: 1px solid transparent;
      }
    }
    td::before {
      margin-bottom: 10px;
    }
    td.reason-col{
      @include media-breakpoint-up(lg) {
        width: 26%;
      }
    }

    .table-line-column {
      text-align: left;
      margin-bottom: $spacing-small;
    }

    .form-control {
      // width: $returnable-creation-input-width;
    }
  }

  .form-footer {
    justify-content: space-between;
  }

  .form-footer__btn-container {
    margin-top: $spacing-small;
    @include media-breakpoint-down(md) {
      margin-top: $spacing-small;
    }
  }

  tr.rejected-position {
    td:not(:last-child) {
      @include media-breakpoint-down(md) {
        background-color: $danger;
      }

      P {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        p {
          background-color: $danger;
        }
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

