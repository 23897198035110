cx-skip-link {
    @extend %cx-skip-link;
}

cx-storefront {
    @extend %cx-storefront;
}

cx-media {
    @extend %cx-media;
}

cx-spinner {
    @extend %cx-spinner;
}

cx-carousel {
    @extend %cx-carousel;
}

cx-product-view {
    @extend %cx-product-view;
}

cx-anonymous-consent-management-banner {
    @extend %cx-anonymous-consent-management-banner;
}

cx-anonymous-consent-dialog {
    @extend %cx-anonymous-consent-dialog;
}

cx-anonymous-consent-open-dialog {
    @extend %cx-anonymous-consent-open-dialog;
}

cx-consent-management-form {
    @extend %cx-consent-management-form;
}

:root {
    --cx-buttons-border-radius: 0.25rem;
}
