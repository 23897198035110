/* ==========================================================================
   # DELIVERY NOTE RETURNABLE COMPONENT
   ========================================================================== */

$returnable-positions-input-width:    100px;

app-delivery-note-returnable-positions{
  .delivery-note-returnable-container{
    border: 1px solid #B8BCC3;
    border-radius: 4px;
    padding: 2rem;
    margin-bottom: $spacing-huge;
    @include media-breakpoint-down(md) {
      border: 1px solid transparent;
      margin-bottom: 0px;
    }
    app-delivery-notes-header{

    }

    app-returnable-positions {
      .table-header {
        align-items: end;
        border-bottom: 1px solid #C3C5C8;
        margin: 0px;
        @include media-breakpoint-down(md) {
          border-bottom: 1px solid transparent;
        }
        .table-title{
          border-bottom: 1px solid transparent;
          @include media-breakpoint-down(md) {
          border-bottom: 1px solid #C3C5C8;
        }
          h5{
            width: 100%;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            color: $color-black;
            padding-top: 0px;

          }
        }
        .table-selectAll{
          display: flex;
          justify-content: end;
          @include media-breakpoint-up(lg) {
            // justify-content: center;
          }

          button {
            text-decoration: none;
            padding-bottom: 0px;

          }
        }
      }
      .c-delivery-note-details__table {

        thead th {
          text-align: start;
          border-bottom:  1px solid #C3C5C8;
          font-size: 12px;
          font-weight: 700;
          padding: 2rem 0 0 1rem;
          @include media-breakpoint-down(md) {
            border-bottom: 1px solid transparent;
          }
          thead th:last-child{
          @include media-breakpoint-down(md) {
            text-align: end !important;
          }
        }
        }
        tbody tr {
          border-bottom:  1px solid #C3C5C8;
          @include media-breakpoint-down(md) {
            border-bottom: 1px solid transparent;
            display: block;
            margin-bottom: 25px !important;
          }
        }
        tbody td {
          padding-left: 1rem;

          app-efa-item-counter{
            @include media-breakpoint-down(md) {
              margin-left: auto;
            }
            button{
              margin: 0px;
            }
          }

          @include media-breakpoint-up(lg) {
            border: none;
          }
          @include media-breakpoint-down(md) {
            @include responsive-column;
            border-bottom: 1px solid transparent;
          }
        }
        // th:last-child,td:last-child{
        //   @include media-breakpoint-down(md) {
        //     text-align: end !important;
        //   }
        // }
        tbody td:last-child {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          @include media-breakpoint-up(lg) {
            align-items: center;
          }
        }

        .form-control {
          width: $returnable-positions-input-width;
          text-align: center;
        }
      }
      .noPositions{

        .horizontal-line{
          width: 100%;
          border-bottom: 1px solid #C3C5C8;
          margin-bottom: 15px;
          margin-left: 10px;
        }
        h2 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 30px;
          padding-left: 15px;
          // border-top: 1px solid #C3C5C8;

        }
      }
    }
  }
}


