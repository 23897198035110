/* ==========================================================================
   # FLOAT UTILITIES
   ========================================================================== */

.u-float-right {
  float: right !important;
}

.u-img-fluid {
  max-width: 100%;
  height: auto;
}
