/* ==========================================================================
   # LOGIN REGISTRATION COMPONENTS
   ========================================================================== */

app-efa-login-form {
  --cx-max-width: 100% !important;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    gap: 3rem;
  }

  .user-form-wrapper form.user-form {
    width: 100%;
    display: flex;
    gap: 0.75rem;

    .btn-link {
      font-size: 1rem;
    }
  }

  .register {
    text-align: center;
    p {
      font-size: $small-font-size;
      margin-bottom: 0.75rem;

      &.first-login {
        margin-top: 3rem;
        font-size: $font-size-base;
        font-weight: 700;
      }
    }

    .btn-link {
      font-weight: 600;
    }
  }

  .user-form-wrapper,
  .register {
    width: 100%;
    flex: 1;

    h3 {
      text-align: center;
      margin-bottom: 1.5rem;
    }

    button,
    a {
      flex: 1 1 100%;
    }
  }

  .separator {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    height: 433px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      display: none;
    }

    span {
      text-transform: uppercase;
      color: $color-grey-light;
      background-color: var(--cx-color-background);
      z-index: 1;
    }

    .line {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      border-left: 1px solid $color-grey-light;
    }
  }
}

app-registration-start {
  .registration-start__title {
    margin-bottom: $spacing-medium;
  }

  h2 {
    @include type("3");
    font-weight: 700;
    line-height: 1.4em;
  }

  .registration-start__text1 {
    p {
      display: flex;
      align-items: flex-start;
    }
  }

  .registration-start__text2 {
    margin-bottom: $spacing-large;

    p {
      margin-bottom: $spacing-base;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .registration-start__links {
    ul {
      list-style: none;
    }
  }

  .registration-start__btn-container {
    @include media-breakpoint-up(md) {
      align-self: flex-end;
    }
  }

  .registration-start__link {
    color: var(--cx-color-primary);
  }
}

app-registration-form {
  select {
    padding-right: 2rem !important;
    background: url("data:image/svg+xml,<svg height='16' width='16' viewBox='0 0 24 24' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z'/></svg>")
      no-repeat;
    background-position: calc(100% - #{$input-padding-x}) center !important;

    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
  }

  .required .label-content::after {
    content: "*";
    color: black;
  }

  .cx-page-section {
    counter-reset: list-counter 0;
    padding-top: 0;
  }

  .register_fieldset {
    position: relative;
    margin-bottom: $spacing-huge;
  }

  .register_fieldset-legend {
    counter-increment: list-counter 1;

    border-top: 2px solid var(--cx-color-light);
    padding-top: 0.75rem;
    margin-bottom: 0;

    font-size: 22px;
    font-weight: 700;
    line-height: 1.4em;

    &.numbered::before {
      content: counter(list-counter) ". ";
    }

    &.small {
      font-size: 1rem;
    }
  }

  .register_fieldset-content {
    padding-block: $spacing-medium $spacing-tiny;

    .small {
      font-size: 0.75rem;
      color: var(--cx-color-dark);
    }
  }

  .form-group {
    margin-bottom: 1.125em;

    > .d-inline-flex {
      gap: 1.5em;
    }
  }

  cx-icon svg,
  .cx-icon svg {
    width: 24px;
    height: 24px;
  }

  .caret-right svg {
    vertical-align: top;
  }
}

app-registration-confirmation {
  .registration-confirmation__text {
    text-align: center;

    h2 {
      @include type("3");
      font-weight: 700;
      margin-bottom: $spacing-base;
    }

    p {
      margin-bottom: $spacing-small;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

app-registration-progress {
  .registration-step-title {
    font-size: $small-font-size;
  }

  .registration-step-index-divider {
    border-bottom: 2px solid $color-grey-light;
    width: 45%;
    height: 50%;

    &.first,
    &.last {
      border: none;
    }
  }

  .registration-step-highlight {
    color: $color-blue-light;
  }

  cx-icon svg,
  .cx-icon svg {
    height: 40px;
    width: 41px;
  }

  .c-col-2 {
    max-width: 20%;
    flex: 0 0 20%;
  }
}

%app-efa-registration-step {
  margin-top: 5%;
  select {
    padding-right: 2rem !important;
    background: url("data:image/svg+xml,<svg height='16' width='16' viewBox='0 0 24 24' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z'/></svg>")
      no-repeat;
    background-position: calc(100% - #{$input-padding-x}) center !important;

    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
  }

  .required .label-content::after {
    content: "*";
    color: black;
  }

  .cx-page-section {
    counter-reset: list-counter 0;
    padding-top: 0;
  }

  .register_fieldset {
    position: relative;
    margin-bottom: $spacing-huge;
  }

  .register_fieldset-legend {
    counter-increment: list-counter 1;

    border-top: 2px solid var(--cx-color-light);
    padding-top: 0.75rem;
    margin-bottom: 0;

    font-size: 22px;
    font-weight: 700;
    line-height: 1.4em;

    &.small {
      font-size: 1rem;
    }
  }

  .register_fieldset-content {
    padding-block: $spacing-medium $spacing-tiny;

    .small {
      font-size: 0.75rem;
      color: var(--cx-color-dark);
    }
  }

  .form-group {
    margin-bottom: 1.125em;

    > .d-inline-flex {
      gap: 1.5em;
    }
  }
}

app-registration-bank-and-delivery,
app-registration-company,
app-registration-contact-persons,
app-registration-documents,
app-registration-submission {
  @extend %app-efa-registration-step;
  .attentionReasonInfos{
    @include media-breakpoint-up(lg) {
      justify-content: space-between;
    }
  }
}
app-registration-contact-persons{
  form{
    fieldset{
      .add-contact-person{
          height: auto;
          max-height: fit-content;
      }
    }
  }
}

