/* ==========================================================================
   # CATEGORY NAVIGATION COMPONENT
   [1] Customized placeholders for My Account navigation UI.
   ========================================================================== */


app-efa-category-navigation span,
app-efa-category-navigation app-category-navigation-ui > nav > cx-generic-link {
  text-transform: capitalize;
}

%efa-nav-wrapper {
  .wrapper {
    cursor: default;
  }
  // create width of wrapper
  .wrapper[attr='1'] {
    width: 200px;
  }
  .wrapper[attr='2'] {
    width: 400px;
  }

  &.flyout {
    .wrapper {
      @include media-breakpoint-down(md) {
        height: 0;
        overflow: hidden;
      }
      color: #000;
    }
    @include media-breakpoint-up(lg) {
      .wrapper {
        // we add a z-index since some of the elements on the page have (surprisingly) a z-index rule
        // we cannot make the z-index too high as it would conflict with the searchbox flyout.
        z-index: 20;
      }
      > nav > .wrapper {
        padding: 15px 20px;
        background: #fff;
      }
    }
  }
}

%efa-nav-back-button {
  &.flyout {
    .back {
      display: none;
    }
  }
}

%efa-nav-heading {
  nav {
    &:focus {
      color: var(--cx-g-color-primary);
    }
  }

  span {
    margin: 0;
  }

  &.flyout {
    span,
    cx-generic-link {
      display: flex;
      align-items: center;
      white-space: nowrap;

      color: currentColor;

      &:hover {
        color: var(--cx-color-primary);
      }

      &:focus {
        z-index: 1;
        position: relative;
      }

      a {
        display: block;
        width: 100%;
        &:focus {
          z-index: 1;
          position: relative;
        }
      }
    }

    @include media-breakpoint-down(md) {
      // make all first level hedings uppercase in mobile view
      > nav {
        > span,
        > cx-generic-link {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      span,
      cx-generic-link {
        display: flex;
        justify-content: space-between;

        cursor: pointer;
      }

      span{
        padding: 1rem;
      }
    }

    @include media-breakpoint-up(lg) {
      > nav {
        cursor: pointer;
        // top level headings
        > span {
          padding-top: 20px;
          padding-inline-end: 15px;
          padding-bottom: 22px;
          padding-inline-start: 0;
        }
        // first level headings will be bold in desktop
        nav > span {
          @include type('5');
          cursor: default;

          &:hover {
            color: currentColor;
          }
        }
      }
    }
  }
  cx-generic-link.all {
    text-decoration: underline;
  }
}

@include media-breakpoint-up(lg) {
  cx-navigation-ui > nav > cx-generic-link > a {
    padding-top: 20px;
    padding-inline-end: 15px;
    padding-bottom: 22px;
    padding-inline-start: 0;
  }
  nav > div > cx-generic-link {
    padding: 10px 0;
  }
  div.childs > nav > cx-generic-link > a {
    padding: 5px 0;
  }
}

%efa-nav-icon {
  cx-icon {
    &:before {
      font-size: 1.5rem;
    }
  }
  @include media-breakpoint-up(lg) {
    cx-icon {
      padding-inline-start: 7px;
      &:before {
        font-size: 1rem;
      }
    }
  }
}

%efa-nav-childs {
  &.flyout {
    // the last level of navigations is tyically displayed in a column
    .childs[depth='1'] {
      flex-direction: column;
    }
    .childs {
      @include media-breakpoint-up(lg) {
        .childs {
          padding: 0;
        }
      }
    }
  }
}

%efa-nav-links {
  a {
    color: currentColor;
  }
  &.flyout {
    a {
      display: block;
      text-decoration: none;
      white-space: nowrap;
    }
  }
}

%efa-nav-wrap {
  @for $column from 1 through 10 {
    &.flyout .childs[columns='#{$column}'] {
      display: block;

      @include media-breakpoint-up(md) {
        column-count: $column;
      }
      @include media-breakpoint-down(sm) {
        column-count: ceil($column / 3);
      }
    }
  }
}

%efa-default-my-account-navigation-ui {
  display: flex;

  @extend %efa-nav-heading;
  @extend %efa-nav-icon;
  @extend %efa-nav-wrapper;
  @extend %efa-nav-childs;
  @extend %efa-nav-links;
  @extend %efa-nav-back-button;
  @extend %efa-nav-wrap;

  nav {
    outline: none;
  }

  &.flyout {
    > nav:hover > span > cx-icon {
      svg {
        transform: rotate(180deg);
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      color: #000;
      > nav > span {
        padding: 0px;
        cx-icon{
          margin-right: 1.25rem;

          svg {
            transform: none !important;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      &.is-open {
        nav[aria-expanded="true"] {
          display: initial;
          cx-generic-link.all {
            display: initial;
          }
          > .wrapper {
            height: auto;
          }
        }
      }
      &.is-opened {
        nav[aria-expanded="true"] {
          display: initial;
          cx-generic-link.all {
            display: initial;
          }
          > .wrapper {
            height: auto;
            // border-bottom: 1px solid #9E9E9E;
            box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
          }
        }
        > nav[aria-expanded="true"] {
          display: initial;
          > .wrapper > .childs[depth="2"] > nav {
            display: none;
          }
          > .wrapper > .childs[depth="2"] > nav[aria-expanded="true"]{
            display: initial;
          }
          > span {
            display: none;
          }
          > .wrapper {
            height: auto;
            // border-bottom: 1px solid #9E9E9E;
            // box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .childs[depth='2'] {
        > nav {
          &:not(:only-child):not(:last-child) {
            padding-inline-end: 20px;
          }
        }
      }
      // Show dropdowns via hover when no nav is focused
      > nav {
        > .wrapper {
          position: absolute;
          margin-inline-start: -10px;
          border: 1px solid #ccc;
          @include hideWrapperDesktop();
        }

        // We only treat the first wrapper as a collapsible container
        &:hover {
          > .wrapper {
            @include showWrapperDesktop();
          }
        }
      }

      // Show dropdowns via focus only once a nav is clicked
      &:focus-within {
        > nav {
          &:focus,
          &.is-open:focus-within {
            > .wrapper {
              @include showWrapperDesktop();
            }
          }
        }
      }

      .childs {
        display: flex;
      }

      .wrapper cx-icon {
        display: none;
      }

      > nav {
        > .wrapper {
          transition: opacity 0.4s;
        }
      }
    }
  }
}

app-my-account-navigation-ui {
  @extend %efa-default-my-account-navigation-ui;

  cx-icon {
    &.small {
      @include media-breakpoint-down(md) {
        svg {
          height: 16px !important;
          width: 16px !important;
        }
      }
    }
  }

  .wrapper cx-icon {
    display: block !important;
    @include media-breakpoint-down(md) {
      display: flex !important;
    }
    svg {
      height: 16px;
      width: 16px;
      align-self: center;
    }
  }

  &.flyout {
    .is-parent {
      color: $primary;
      font-weight: $FONT-WEIGHT-BOLD;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        span:last-child{
          padding: 0px;
        }

        padding-top: 0;
        padding-bottom: 0;
      }

      .parent-title-expanded {
        display: flex;
        width: 100%;
        padding-bottom: 15px;
        margin-bottom: 5px;
        box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
        
        @include media-breakpoint-down(md) {
          justify-content: flex-start;
          padding-left: 0;
          width: 100vw !important;
        }
      }

      .root-title-expanded {
        @include media-breakpoint-down(md) {
          display: flex;
          padding-bottom: 15px;
          margin-bottom: 5px;
          box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
          justify-content: flex-start;
          width: 100vw !important;

          cx-icon {
            svg {
              height: 16px;
              width: 16px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    .logout {
      margin-top: 10px;
      border-top: 1px solid $color-grey;
      span {
        margin-top: 10px;
      }
      cx-icon svg {
        height: 24px;
        width: 24px;
      }
    }

    @include media-breakpoint-up(lg) {
      .childs {
        nav {
          padding-right: 0 !important;
          .is-parent {
            min-height: 40px;
          }

          .wrapper {
            min-height: 325px;
          }
        }

        min-width: 320px;
      }
    }

    @include media-breakpoint-down(md) {
      div.childs {
        nav {
          span {
            padding-left: 10px;
          }
        }
      }
    }

    div.childs > nav > cx-generic-link > a > span.expand-flyout {
      margin-bottom: 0px;
      font-weight: $FONT-WEIGHT-BOLD;
      display: flex;
      justify-content: start;
      font-size: 16px;
      &--nested {
        display: flex;
        justify-content: start;
      }

      @include media-breakpoint-down(md) {
        padding-top: 0;
        padding-bottom: 0;

        &:not(.is-parent) {
          > span {
            padding-left: 1rem;
          }
          
        }
      }
    }
  }
}
