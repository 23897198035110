app-efa-product-facet-navigation {
  @extend %cx-product-facet-navigation;

  .filter-title {
    padding: 0px 10px 0px 0px;

    .filter-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      border-bottom: 1px solid $color-grey-light;

      p{
        margin: 12px 0px 12px 0px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .filter-title {
      display: none
    }
  }


  cx-active-facets {
    @extend %cx-active-facets;

   #cx-active-facets-groupName {
      margin-bottom: 0px;
      font-weight: $FONT-WEIGHT-BLACK;
    }

    a{
      color: var(--cx-color-primary);
      background-color: $color-grey-light-super;
      font-size: 12px;

      cx-icon {
        font-size: 12px;
      }
    }
  }

  cx-facet-list {
    @extend %cx-facet-list ;

    .inner {
      @include media-breakpoint-up(lg) {
        padding-inline-start: 0;
      }
    }

    .list-header{
      display: none;
    }

    cx-facet {
      @extend %cx-facet;
      button {
        color: $color-blue;

        &.cx-action-link {
          font-size: var(--cx-font-size, 0.875rem);
          font-weight: var(--cx-font-weight-normal);
        }

        &.heading {
          border-bottom: 1px solid $color-grey-light;
          padding: 0.4rem 0.4rem 0.4rem 0;
        }
      }

      a {
        &.value {
          padding: 0.4rem 0.4rem 0.4rem 0;

          &.selected {
            font-weight: $FONT-WEIGHT-BOLD;

            &::before {
              width: 20px;
              height: 20px;
              border-radius: 4.5px;
              padding-bottom: 3px;
              content: url(../../../assets/icons/checkbox.svg);
            }
          }
        }
      }
    }

    &.dialog {
      > .inner {
        max-height: 50vh;

        .list-header {
          padding-left: 0;
        }
      }
    }
  }
}


