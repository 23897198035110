/* ==========================================================================
   # PRODUCT SEARCH COMPONENTS
   ========================================================================== */

$product-image-size:        255px;
$product-media-size-list:   50px;

app-efa-product-grid-item {
  @extend %cx-product-grid-item;

  app-efa-cart-item-add-to-wish-list {
    position: absolute;
    top:0;
    right: 0;
    transform: translate(-20px, 15px);
    z-index: 1;
  }

  margin-bottom: 50px;
  padding-right: 10px !important;
  padding-left: 10px !important;

  .item-container {
    border-radius: 8px;
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow: 0px 5px 19.5px 0px $color-black-dark-semi;

    a cx-media {
      margin-top: 0;
    }

    .cx-product-price-container {
      .cx-product-price {
        text-align: left;
        font-size: 18px;
        font-weight: $FONT-WEIGHT-BOLD;
      }
    }
  }
}

app-efa-product-list {
  @extend %cx-product-list;

  cx-product-view .cx-product-layout .fas {
    color: var(--cx-color-primary);
  }
  .cx-product-search-list
  {
    margin-bottom: 10px;
    border: none;

    &:last-of-type {
      border-style: solid;
      border-color: $color-grey-light;
      border-width: 0 0 1px 0;
      margin-bottom: 1rem;
    }
  }

  .banner-container {
    margin-top: 3%;
    margin-bottom: 0;

    app-efa-banner {
      img {
        width: 100%;
      }
    }
  }
}

app-efa-product-list-item {
  @extend %cx-product-list-item;

  .product-price-column-wrapper {
    @include media-breakpoint-up (lg) {
      padding-right: 0;
    }
    
    .cx-product-price-container {
      .cx-product-price {
        display: flex;
        flex-direction: column;
        text-align: right;
        font-size: 18px;
        font-weight: $FONT-WEIGHT-BOLD;
      }
    }
  }
}

app-efa-product-grid-item .cx-product-image,
cx-product-grid-item .cx-product-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $product-image-size;
  margin: 3rem 0 1rem 0;
}
app-efa-product-grid-item .cx-product-name,
cx-product-grid-item .cx-product-name{
text-align: left;
width: 75%;
}
app-efa-product-grid-item .manufacturerAID{
  font-size: 14px;
  margin: $spacing-tiny 0px;
}
app-efa-product-grid-item .action-box{
  display: flex;
  justify-content: center;
  align-items:end;
  margin-bottom: 10px;
  @include media-breakpoint-down(md) {
    align-items: start;
    button{
      padding: 11px 1rem;
    }
  }
  app-efa-add-to-cart{
    width: 95%;
    button.submit-btn{
      height: 48px;
      max-height: 48px;
      text-align: start;
      padding: 11px 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      .btn-text{
        display: none;
        color: inherit;
        background-color: inherit;

      }

      @media only screen and (min-width: 1100px) {
        .btn-text{
          display: inline;
        }
      }

      @include media-breakpoint-down(sm) {
        .btn-text{
          display: inline;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 10px;
  }
}

app-efa-product-list-item,
cx-product-list-item {
  cx-media,
  cx-media.is-missing {
    min-height: $product-media-size-list;
    max-height: $product-media-size-list;
  }
  .cx-product-name {
    text-align: left;
    height: auto;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }
}
app-efa-product-list-item .manufacturerAID{
  font-size: 14px;
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}
app-efa-product-list-item .action-box{
  display: flex;
  align-items:end;
  justify-content: end;
  @include media-breakpoint-down(md) {
    button{
      padding: 11px 1rem;
    }
  }
  app-efa-add-to-cart{
    button.submit-btn{
      height: 52px;
      max-height: 52px;
      text-align: start;
      padding: 11px 1rem;
      .btn-text{
        color: inherit;
        background-color: inherit;

      }

      @include media-breakpoint-down(md) {
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        span:last-child{
          display: none;
        }
      }

      @include media-breakpoint-down(xs) {
        width: 75px;
      }
    }
  }
  app-efa-cart-item-add-to-wish-list{
    margin-left: 10px;
    padding: 12px 8px 8px 12px;
  }
}

