/* ==========================================================================
   # FAQ ITEMS COMPONENTS
   ========================================================================== */

app-faq-overview {
  @include c-accordion;

  .accordion {
    .accordion-item {
      .accordion-body {
        padding-top: 0;
      }

      border-width: 0 0 1px;
      border-radius: 0;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 5rem;
    }
  }

  .faq-item {
    a {
      cx-icon svg {
        height: 10px;
        width: 10px;
      }

      text-decoration: none;
      font-size: $small-font-size;

      @include media-breakpoint-down(sm) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

app-faq-overview-search-box {
  .searchbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $color-grey-lightest;
    padding-top: 2rem;
    padding-bottom: 2rem;

    label {
      &.searchbox {
        background: $color-white;
        display: flex;
        align-content: stretch;
        border: 1px solid var(--cx-color-medium);
        border-radius: var(--cx-buttons-border-radius);
        font-size: 14px;
        color: $color-grey-dark-semi-third-degree;
        margin-bottom: 2px;

        input {
          background: none;
          border: none;
          outline: none;
          display: block;
          height: 35px;
          padding: 10px;
          min-width: 300px;
          text-overflow: ellipsis;
        }

        div.search-icon {
          flex-basis: 48px;
          text-align: center;
          background: none;
          padding: 6px;
          color: var(--cx-color-medium);
          border-left: 1px solid var(--cx-color-light);
          border-radius: 0 var(--cx-buttons-border-radius) var(--cx-buttons-border-radius) 0;
        }

        .search-icon.searchbox-empty {
          background-color: var(--cx-color-inverse);
        }

        .search-icon.searchbox-filled {
          background-color: var(--cx-color-primary);
          cursor: pointer;
        }

        cx-icon.searchbox-empty {
          color: var(--cx-color-primary);
        }

        cx-icon.searchbox-filled {
          color: var(--cx-color-inverse);
        }
      }
    }
  }

  .results-dialog {
    position: absolute;
    width: 100%;
    left: 0;
    transform: translate(0, -2rem);

    .results-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .results,
      .no-match-text {
        width: 342px;
        padding: 0.5rem;
        z-index: 1;
        border: 1px solid var(--cx-color-medium);
        background-color: $color-white;
      }

      .results {
        .faq-item {
          a {
            cx-icon svg {
              height: 10px;
              width: 10px;
            }

            text-decoration: none;
            font-size: $small-font-size;

            @include media-breakpoint-down(sm) {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
    }
  }

}

app-faq-details {
  .faq-item-title {
    font-weight: $FONT-WEIGHT-BOLD;
  }

  .actions {
    a {
      &.btn-back {
        text-decoration: none;
      }

      cx-icon svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}