app-configurator-cart-confirmation-message.cx-dialog-popover-center {
    .modal-container {
        background-color: $color-white;
        max-width: 800px;
        .modal-header {
            max-height: 35px !important;
            border: 0;
            padding-top: 10px;
            justify-content: end;
        }
    
        .modal-body {
            max-height: 160px !important;
            padding-bottom: 0;
            padding-top: 0;
    
            .outer-container {
                text-align: center;
            
                .configurator-cart-confirmation-message-title {
                    font-weight: $FONT-WEIGHT-BOLD;
                    font-size: 22px;
                    margin-bottom: 10px;
                }
        
                .configurator-cart-confirmation-message-text {
                    font-weight: $FONT-WEIGHT-REGULAR;
                    font-size: 15px;
                    margin-bottom: 10px;
                }
            }
    
            .configurator-cart-confirmation-message-icon {
                cx-icon svg {
                    color: $primary;
                    height: 48px;
                    width: 48px;
                }
    
                margin-bottom: 40px;
            }
    
            @include media-breakpoint-down(sm) {
                max-height: 600px !important;
            }
        }
    
        .modal-footer {
            border: 0;
            justify-content: center;
    
            .btn-confirm {
                min-width: 208px;
            }
        }
    }
}