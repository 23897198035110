/* ==========================================================================
   # CART TOTAL COMPONENTS
   ========================================================================== */

app-efa-cart-totals {
  @extend %cx-cart-totals;
  padding-inline-start: 0;
  padding-bottom: 2rem;

  @include border-top;

  @include media-breakpoint-down(md) {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }

  .c-cart-tax-notice {
    padding: 0.5rem 0;
    margin-bottom: 0;
    font-size: 0.75rem;
  }

  .c-configurator-cart-tax-notice-container {
    margin-bottom: 40px;

    .c-configurator-cart-tax-notice {
      padding: 0;
      font-size: 0.75rem;
    }
  }
}
