/* ==========================================================================
   # PROMOTION FLIP BOOK COMPONENTS
   ========================================================================== */

app-car-parts-promotion {
  .flip-book-iframe-container {
    position: relative;
    padding-top: max(60%, 326px);
    height: 0;
    width: 100%;

    .flip-book-iframe {
      position: absolute;
      border: none;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}
