app-process-navigation {
  .nav-control-btn{
    button {
      .cx-icon {
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .btn-text-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        i{
          font-size: 20px;
          padding-right: 15px;
        }
      }
    }
  }
}
