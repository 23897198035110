/* ==========================================================================
   # TILES COMPONENTS
   [1] Custom percentile classes for tiles in tiles container like:
   .tiles-w-lg-20 and .tiles-w-lg-100, to be configured in the backend.
   [2] Custom style classes configured in the backend for tile items.
   ========================================================================== */

app-efa-tiles-cms-container {
  --tile-container-background-color: var(--cx-color-background);
  --tiles-container-text-color: var(--cx-color-text);

  .addMoreSpace{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .add-horizontal-line{
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 0 !important;
    border-bottom: 1px solid #B8BCC3;
  }
  .tiles-container-wrapper {
    color: var(--tiles-container-text-color);
    background-color: var(--tile-container-background-color);
    position: relative;

    .inactiveContainer{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 75%;
      .inactiv-item-text-wrapper{
        flex: 1 0 auto;
        .text-title{
          font-weight: 700;
          margin-bottom: 0.25rem;
          overflow: hidden;
          text-align: center;
          color: #7E8490;
          font-size: $h2-font-size;

        }
        .text-description{
          @include type("7");
          margin-bottom: 1rem;
          display: block;
          display: -webkit-box;
          max-width: 100%;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          text-align: center;
          color: #7E8490;
          font-size: $h4-font-size;
        }
      }
    }

    .tiles-container {
      margin-bottom: 50px;
      max-width: var(--cx-page-width-max);
      margin-inline: auto;

      @include media-breakpoint-down(md) {
        margin: 0 9px;
      }

      h2 {
        margin-bottom: 1rem;
        margin-top: 1rem;
        text-align: center;
      }

      .link-show-more {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .tiles-row-justify-center {
    .tiles-container {
      .row {
        justify-content: center;
      }
    }
  }
  .tiles-container-wrapper.tiles-row-justify-center .tiles-container h2 {
    margin: 40px 0 40px 0;
  }
  .title-left-aligned {
    text-align: left !important;
  }

  .previous,
  .next {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    color: var(--cx-color-primary);

    &:disabled {
      opacity: 0.5;
    }

    &:not(:disabled):hover {
      color: var(--cx-color-primary-accent);
    }
  }

  cx-icon.tiles-container-slide-icon {
    color: $primary;
    cursor: pointer;
  }
}

app-efa-tile-cms-item{

  .custom-link-style{
    padding: 0 0 1.5rem 0 !important;
  }

  .partslink-24-Zugang-link{
    &:hover{
      text-decoration: underline;
    }

  }
  .partslink-24-Zugang-link-title{
    display: block !important;
    max-width: 100%;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 700;
    font-size: 0.875rem;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 0 !important;
    padding-left: 12px;
  }


  @include media-breakpoint-up(lg) {
    .add-space-help-contact-tiles{
      .tile-item-image{
        img {
          padding-bottom: 40px ;
          padding-top: 40px;
        }
      }
    }
  }
}
app-efa-tile-cms-item,
app-efa-pneu-configurator,
app-efa-tysys-catalog {
  --invert-image: invert(0);

  display: flex;
  width: 100%;
  min-height: 100%;

  .inactiveItemContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    .inactiv-item-text-wrapper{
      flex: 1 0 auto;
      .text-title{
        font-weight: 700;
        margin-bottom: 0.25rem;
        overflow: hidden;
        text-align: center;
        color: #7E8490;
        font-size: $h2-font-size;

      }
      .text-description{
        @include type("7");
        margin-bottom: 1rem;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        text-align: center;
        color: #7E8490;
        font-size: $h4-font-size;
      }
    }
  }

  .tile-item-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    box-shadow: 0px 5px 19.5px 0px $color-black-dark-semi;
    border-radius: 8px;
    padding: 0 0 0 0;

    &.no-border {
      border: none;
      box-shadow: none;
    }

    .tile-item-text-wrapper {
      flex: 1 0 auto;
      padding: 0.5rem 0.5rem 0 1rem;

    }

    .tile-item-image img {
      width: 100%;
      height: 88px;
      object-fit: contain;
      margin-bottom: 0.75rem;
      filter: var(--invert-image);
    }

    @include media-breakpoint-up(md) {
      .tile-item-image {
        display: flex;
        justify-content: center;

        img {
          height: auto;
          max-height: 175px;
          width: auto;
          margin: auto;
        }
      }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 2rem;
    }

    .tile-item-title {
      font-weight: 700;
      margin-bottom: 0.25rem;
      overflow: hidden;
    }

    .tile-item-subtitle {
      @include type("7");
      font-weight: 700;
      overflow: hidden;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .title-has-subtitle {
      font-size: $h2-font-size;
    }

    .tile-item-description {
      @include type("7");
      height: auto;
      overflow: hidden;
      margin-bottom: 0.5rem;
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      line-height: 1.5;
      min-height: 42px;
    }

    .tile-item-link {
      padding: 0 1rem 1rem 1rem;
      .btn-link {
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        color: #003899;
        font-size: 0.75rem;
        font-weight: 700;
        text-decoration: none;
        padding-left: 12px !important;
        &:hover {
          text-decoration:underline !important;
        }
      }
      .btn {
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        align-content: center;
      }
      .btn-primary {
        a {
          color: $secondary;
        }
      }
    }

    .tile-item-content {
      p {
        display: grid;
        align-content: center;
        margin: 0;
        height: 48px;
        text-align: center;
        font-weight: 600;
      }
    }
  }
}

.TopFooter app-efa-tiles-cms-container {
  h2 {
    @include type("3");
  }
  .add-horizontal-line{
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  .tiles-container {
    margin-bottom: 0 !important;

    > div {
      overflow: auto;
    }
  }
  @include media-breakpoint-down(md) {
    .tiles-container div.row {
      > * {
        flex: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}

.TopFooter app-efa-tile-cms-item {

  width: auto;
  margin-inline: auto;

  .tile-item-container {
    align-items: center;

    .tile-item-description {
      text-align: center;
      max-width: 24ch;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: auto;
    }
    .tile-item-image img {
      width: 48px;
      padding: 0;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 60px;
      .tile-item-description {
        max-width: 100%;
        min-height: auto;
      }
      .tile-item-title,
      .tile-item-description {
        font-size: 1rem;
        display: block;
      }
    }
  }
}

// [1]
@each $percentile-name, $percentile-value in $percentiles {
  app-efa-tiles-cms-container {
    .tiles-w-lg#{-#{$percentile-name}} app-efa-tile-cms-item {
      @include media-breakpoint-up(lg) {
        width: $percentile-value;
        .tile-item-container {
          .tile-item-description {
            max-width: 100%;
            text-overflow: ellipsis;
          }
          .tile-item-link {
            width: 100%;
          }
        }
      }
    }
  }
}

// [2]
app-efa-tile-cms-item,
app-efa-pneu-configurator,
app-efa-tysys-catalog {
  .tile-item-text-center {
    .tile-item-text-wrapper {
      .tile-item-title,
      .tile-item-description,
      .tile-item-subtitle {
        text-align: center;
      }
    }
  }

  .tile-item-description-w-100 {
    .tile-item-text-wrapper {
      .tile-item-description {
        max-width: 100%;

      }
    }
  }

  .tile-item-image-w-100 {
    .tile-item-image {
      img {
        width: 100% !important;
      }
    }

    @include media-breakpoint-up(lg) {
      .tile-item-image {
        img {
          width: 100% !important;
          padding-top: 40px !important;
          padding-bottom: 40px !important;
        }
      }
    }
  }
}
