/* ==========================================================================
   # DELIVERY NOTES DETAILS COMPONENT
   ========================================================================== */
   .c-delivery-note-details{
    .delivery-header-and-table{
      border: 1px solid #B8BCC3;
      border-radius: 4px;
      padding: 2rem;
      margin-bottom: $spacing-huge;
      @include media-breakpoint-down(md) {
        border: 1px solid transparent;
        padding: 0px;
        margin-bottom: 0px;
      }

      .c-delivery-note-details__table {
        tbody td {
          text-align: start;
          padding-left: 1rem;
          border-bottom:  1px solid #dee2e6;
          @include media-breakpoint-down(md) {
            @include responsive-column;
            border-bottom: 1px solid transparent;
          }

          &.form-control {
            width: 100px;
          }
        }
        thead th {
          text-align: start;
          border-bottom:  1px solid #dee2e6;
          font-size: 12px;
          font-weight: 700;
          padding: 2rem 0 0 1rem;
          @include media-breakpoint-down(md) {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }
    .table-title{
      width: 100%;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      border-bottom: 1px solid #dee2e6;
      padding: 10px 1rem;
      @include media-breakpoint-down(md) {
        border-bottom: 1px solid transparent;
      }
    }

   }



.c-delivery-note-details__bottom {
  margin-top: $spacing-medium;
  .btn-margin-top {
    @include media-breakpoint-down(sm) {
      margin-top: $spacing-medium;
    }
  }
}
