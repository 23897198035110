/* ==========================================================================
   # ACCORDEON
   [1] Customized bootstrap accordeon.
   ========================================================================== */

$accordion-card-header-color: $color-grey-lightest;

@mixin c-accordion() {
  // [1]
  .accordion {
    .accordion-item {
      border: 1px solid var(--cx-color-primary);
      border-radius: 5px;
      padding: 0 16px;
      margin-bottom: 20px;

      .accordion-header{
        background-color: var(--cx-color-background);
        padding: 0;
        // margin-bottom: 30px;

        .btn {
          border: none;
          padding: 5px 0;
          color: var(--cx-color-primary);
          line-height: 1 !important;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .btn-filter-text{
            display: inline-block;
            margin-left: .5rem;
          }
          &:focus {
            outline-style: none;
            box-shadow: none;
          }
        }
        .btn:after {
          float: right;
          font-size: 33px;
          bottom: 12px;
          // position: relative;
        }
        .btn[aria-expanded=false]:after {
          content: "+"
        }
        .btn[aria-expanded=true] {
          color: #003899
        }

        .btn[aria-expanded=true]:after {
          content: "–"
        }
      }
          .accordion-body{
            padding: 30px 0 20px;
            // transition: padding .5s;
            .input-group-append{
              .btn{
                padding: 9px 1rem;
              }
            }
          }
    }
  }
}
