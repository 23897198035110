.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.santorini-updated {
  --cx-color-background: #f4f4f4;
  --cx-color-text: #14293a;
  --cx-color-inverse: #ffffff;
  --cx-color-transparent: transparent;
  --cx-color-primary: #055f9f;
  --cx-color-secondary: #556b82;
  --cx-color-success: #256f3a;
  --cx-color-info: #0064d8;
  --cx-color-warning: #b44f00;
  --cx-color-danger: #aa0808;
  --cx-color-light: #f1f1f1;
  --cx-color-dark: #77818b;
  --cx-color-primary-accent: #0f4880;
  --cx-color-success-accent: #f0fbe4;
  --cx-color-danger-accent: #fff1f1;
  --cx-color-warning-accent: #fff5df;
  --cx-color-info-accent: #deeffe;
  --cx-color-medium: #d3d6db;
  --cx-color-background-dark: #212738;
  --cx-color-visual-focus: #1f3a93;
  --cx-color-background-focus: rgba(80, 176, 244, 0.1);
  --cx-color-border-focus: rgba(166, 204, 232, 0.6);
  --cx-page-width-max: 1140px;
  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;
  --cx-text-transform: capitalize;
  --cx-direction: ltr;
  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;
  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;
  --cx-border-style: solid;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-close-account-modal .cx-close-account-modal {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog, .modal.fade cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-suggested-addresses-dialog .modal.fade .cx-suggested-addresses-dialog, .modal.fade cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.fade .cx-close-account-modal {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog, .modal.fade cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-suggested-addresses-dialog .modal.fade .cx-suggested-addresses-dialog, .modal.fade cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.fade .cx-close-account-modal {
    transition: none;
  }
}
.modal.show .modal-dialog, .modal.show cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-suggested-addresses-dialog .modal.show .cx-suggested-addresses-dialog, .modal.show cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.show .cx-close-account-modal {
  transform: none;
}
.modal.modal-static .modal-dialog, .modal.modal-static cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-suggested-addresses-dialog .modal.modal-static .cx-suggested-addresses-dialog, .modal.modal-static cx-close-account-modal .cx-close-account-modal, cx-close-account-modal .modal.modal-static .cx-close-account-modal {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .modal-dialog-scrollable .cx-suggested-addresses-container, .modal-dialog-scrollable cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-dialog-scrollable .cx-close-account-modal-container {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body, .modal-dialog-scrollable cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .modal-dialog-scrollable .cx-suggested-addresses-body, .modal-dialog-scrollable cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .modal-dialog-scrollable .cx-close-account-modal-body {
  overflow-y: auto;
}

.modal-dialog-centered, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-close-account-modal .cx-close-account-modal {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog::before, cx-close-account-modal .cx-close-account-modal::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable, cx-suggested-addresses-dialog .modal-dialog-scrollable.cx-suggested-addresses-dialog, cx-close-account-modal .modal-dialog-scrollable.cx-close-account-modal {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content, .modal-dialog-centered.modal-dialog-scrollable cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .modal-dialog-centered.modal-dialog-scrollable .cx-suggested-addresses-container, cx-suggested-addresses-dialog .modal-dialog-scrollable.cx-suggested-addresses-dialog .modal-content, cx-suggested-addresses-dialog .modal-dialog-scrollable.cx-suggested-addresses-dialog .cx-suggested-addresses-container, .modal-dialog-centered.modal-dialog-scrollable cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-suggested-addresses-dialog .modal-dialog-scrollable.cx-suggested-addresses-dialog cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-dialog-centered.modal-dialog-scrollable .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal cx-suggested-addresses-dialog .modal-dialog-scrollable.cx-suggested-addresses-dialog .cx-close-account-modal-container, cx-suggested-addresses-dialog cx-close-account-modal .cx-close-account-modal .modal-dialog-scrollable.cx-suggested-addresses-dialog .cx-close-account-modal-container, cx-close-account-modal .modal-dialog-scrollable.cx-close-account-modal .modal-content, cx-close-account-modal .modal-dialog-scrollable.cx-close-account-modal cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog cx-close-account-modal .modal-dialog-scrollable.cx-close-account-modal .cx-suggested-addresses-container, cx-close-account-modal .modal-dialog-scrollable.cx-close-account-modal .cx-close-account-modal-container {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before, cx-suggested-addresses-dialog .modal-dialog-scrollable.cx-suggested-addresses-dialog::before, cx-close-account-modal .modal-dialog-scrollable.cx-close-account-modal::before {
  content: none;
}

.modal-content, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-close-account-modal .cx-close-account-modal {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content, .modal-dialog-scrollable cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .modal-dialog-scrollable .cx-suggested-addresses-container, .modal-dialog-scrollable cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .modal-dialog-scrollable .cx-close-account-modal-container {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog, cx-close-account-modal .cx-close-account-modal {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog::before, cx-close-account-modal .cx-close-account-modal::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: "bold";
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header {
  padding: 1rem 1rem;
}
.cx-theme-high-contrast-dark .modal-header, .cx-theme-high-contrast-light .modal-header {
  background-color: var(--cx-color-background);
}
.modal-body, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-body, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-body {
  padding: 16px 30px 30px;
}
.modal-content, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container {
  border-radius: 0;
  border: none;
}
.cx-theme-high-contrast-dark .modal-content, .cx-theme-high-contrast-dark cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-theme-high-contrast-dark .cx-suggested-addresses-container, .cx-theme-high-contrast-dark cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .cx-theme-high-contrast-dark .cx-close-account-modal-container, .cx-theme-high-contrast-light .modal-content, .cx-theme-high-contrast-light cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-theme-high-contrast-light .cx-suggested-addresses-container, .cx-theme-high-contrast-light cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container, cx-close-account-modal .cx-close-account-modal .cx-theme-high-contrast-light .cx-close-account-modal-container {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-text);
}
.cx-theme-high-contrast-dark .modal-content .close, .cx-theme-high-contrast-dark cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .close, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-theme-high-contrast-dark .cx-suggested-addresses-container .close, .cx-theme-high-contrast-dark cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .close, cx-close-account-modal .cx-close-account-modal .cx-theme-high-contrast-dark .cx-close-account-modal-container .close, .cx-theme-high-contrast-light .modal-content .close, .cx-theme-high-contrast-light cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .close, cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-theme-high-contrast-light .cx-suggested-addresses-container .close, .cx-theme-high-contrast-light cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .close, cx-close-account-modal .cx-close-account-modal .cx-theme-high-contrast-light .cx-close-account-modal-container .close {
  color: var(--cx-color-text);
}
.modal .close {
  font-size: 38px;
  font-weight: 100;
  bottom: 5px;
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  align-self: flex-end;
}
@media (max-width: 991.98px) {
  .modal .close {
    margin-bottom: 0;
  }
}
.cxFeat_a11yExpandedFocusIndicator .modal .cx-dialog-header button.close,
.cxFeat_a11yExpandedFocusIndicator .modal button.close {
  padding: 0;
  margin: 0 0 0 auto;
}

.modal[aria-hidden=false] {
  display: block;
}

.cx-modal-container {
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
  overflow-y: auto;
  max-width: 768px !important;
  min-width: 768px !important;
}
@media (max-width: 767.98px) {
  .cx-modal-container {
    margin: 0 !important;
    min-width: 100% !important;
    max-width: 100% !important;
    height: 100%;
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .cx-modal-container {
    max-width: 768px !important;
    min-width: 768px !important;
  }
}

.cx-modal-header {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-inline-end: 1.875rem;
  padding-bottom: 0;
  padding-inline-start: 1.875rem;
}

.cx-modal-content {
  background-color: var(--cx-color-inverse);
  width: 100%;
}
.cx-theme-high-contrast-dark .cx-modal-content, .cx-theme-high-contrast-light .cx-modal-content {
  color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  border: 1px solid var(--cx-color-text);
}
.cx-theme-high-contrast-dark .cx-modal-content .close, .cx-theme-high-contrast-light .cx-modal-content .close {
  color: var(--cx-color-text);
}

.cx-modal-footer {
  padding: 0px 27px 30px;
}

@media (min-width: 992px) {
  .cx-asm-dialog {
    max-width: 95% !important;
    min-width: 95% !important;
  }
}
.cx-asm-dialog .cx-modal-content {
  border-radius: 16px;
}

cx-login {
  color: var(--cx-color-text);
}
@media (max-width: 991.98px) {
  cx-login > a {
    padding: 1rem;
    display: block;
  }
}
cx-login .cx-login-greet {
  color: var(--cx-color-text);
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  font-weight: var(--cx-font-weight-semi);
}
@media (max-width: 991.98px) {
  cx-login .cx-login-greet {
    color: var(--cx-color-text);
    padding: 0.5rem 1rem 0;
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-normal);
    line-height: var(--cx-line-height, 1.6);
  }
}
@media (min-width: 992px) {
  cx-login #account-nav nav > ul > li > button {
    padding-top: 4px;
    padding-bottom: 0;
  }
  .cxFeat_a11yMyAccountLinkOutline cx-login #account-nav nav > ul > li > button {
    padding-top: 0;
    margin-top: 4px;
    outline-offset: 1px;
  }
}
.cx-theme-high-contrast-dark cx-login .cx-login-greet, .cx-theme-high-contrast-light cx-login .cx-login-greet {
  background-color: var(--cx-color-background);
}

cx-login-form.user-form cx-spinner {
  display: none;
}
cx-login-form.user-form button {
  flex: 100%;
}
.cx-theme-high-contrast-dark cx-login-form input, .cx-theme-high-contrast-light cx-login-form input {
  border-color: var(--cx-color-text);
  background-color: var(--cx-color-background);
  color: var(--cx-color-text);
}
.cx-theme-high-contrast-dark cx-login-form input:-webkit-autofill, .cx-theme-high-contrast-light cx-login-form input:-webkit-autofill {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

cx-otp-login-form.user-form cx-spinner {
  display: none;
}
cx-otp-login-form.user-form button {
  flex: 100%;
}

.cx-my-account-v2-user {
  border: 1px solid var(--cx-color-medium);
  width: 250px;
  padding: 20px 5px 5px 25px;
  gap: 40px;
  height: 120px;
  margin: 2rem 0rem 0rem 0rem;
}
.cx-my-account-v2-user .cx-name .cx-sign-out {
  text-decoration: underline;
}

cx-verification-token-form.user-form .resend-link-text {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
}
cx-verification-token-form.user-form .resend-link-text .left-text {
  padding: 0;
  width: 50%;
  text-align: start;
}
cx-verification-token-form.user-form .resend-link-text .right-text {
  padding: 0;
  width: 50%;
  text-align: end;
}
cx-verification-token-form.user-form .resend-link-text a.disabled-link {
  pointer-events: none;
  color: var(--cx-color-dark);
}
cx-verification-token-form.user-form .resend-link-text a {
  color: var(--cx-color-primary);
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
}
cx-verification-token-form.user-form .verify-container {
  width: 100%;
  margin-top: 2.5rem;
}
cx-verification-token-form.user-form cx-spinner {
  display: none;
}
cx-verification-token-form.user-form button {
  flex: 100%;
}

cx-verification-token-dialog .cx-modal-content {
  max-width: 32rem;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
cx-verification-token-dialog .cx-dialog-header {
  padding-top: 0.9rem;
  padding-inline-end: 1rem;
  padding-bottom: 0.9rem;
  padding-inline-start: 1rem;
  border-width: 0;
  display: flex;
  border-color: var(--cx-color-light);
}
cx-verification-token-dialog .cx-dialog-header .info-icon cx-icon {
  font-size: 1.4rem;
  color: var(--cx-color-info);
}
cx-verification-token-dialog .cx-dialog-header .title {
  font-size: var(--cx-font-size, 1.125rem);
  font-weight: var(--cx-font-weight-bold);
  line-height: var(--cx-line-height, 1.2222222222);
}
.cx-theme-high-contrast-dark cx-verification-token-dialog .cx-dialog-header, .cx-theme-high-contrast-light cx-verification-token-dialog .cx-dialog-header {
  background-color: var(--cx-color-background);
}
cx-verification-token-dialog .spliter {
  margin: 0;
}
cx-verification-token-dialog .cx-dialog-body {
  padding: 1rem;
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
}
cx-verification-token-dialog .cx-dialog-body .cx-dialog-row {
  margin: 0;
  display: flex;
  padding-top: 0;
  padding-inline-end: 0.875rem;
  padding-bottom: 0.85rem;
  padding-inline-start: 2.875rem;
  max-width: 100%;
  flex-wrap: wrap;
}
cx-verification-token-dialog .cx-dialog-body .cx-dialog-item {
  padding: 0.2rem;
}
.cx-theme-high-contrast-dark cx-verification-token-dialog .cx-dialog-body, .cx-theme-high-contrast-light cx-verification-token-dialog .cx-dialog-body {
  background-color: var(--cx-color-background);
}
cx-verification-token-dialog .cx-dialog-footer {
  padding-top: 0;
  padding-inline-end: 1rem;
  padding-bottom: 0;
  padding-inline-start: 1rem;
}

cx-close-account {
  display: flex;
  justify-content: center;
}
cx-close-account .cx-info {
  margin: 0 0 3em 0;
}
cx-close-account .cx-btn-group button {
  min-width: 100%;
  text-transform: lowercase;
}
cx-close-account .cx-btn-group button:first-line {
  text-transform: capitalize;
}
cx-close-account .cx-btn-group button:first-child {
  margin-inline-end: 1em;
}
@media (max-width: 767.98px) {
  cx-close-account .cx-btn-group a:first-child {
    margin: 0 0 1em 0;
  }
}
cx-close-account ul {
  padding-inline-start: 20px;
}

cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-header {
  display: flex;
  justify-content: space-between;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-header .cx-close-account-modal-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-confirmation {
  margin-bottom: 0px;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer {
  display: flex;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button {
  flex: 0 0 calc(50% - 0.5rem);
  text-transform: lowercase;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:first-line {
  text-transform: capitalize;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:first-child {
  margin-inline-end: 0.5rem;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:last-child {
  margin-inline-start: 0.5rem;
}
cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
.cxFeat_a11yImproveContrast cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:focus {
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--cx-color-inverse);
}

.cxFeat_a11yVisibleFocusOverflows cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:focus {
  z-index: 1000;
}

@media (max-width: 991.98px) {
  .cxFeat_a11yMobileVisibleFocus cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:focus {
    outline-offset: -2px;
  }
  .cxFeat_a11yMobileVisibleFocus cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:focus .ng-select-container {
    position: initial;
  }
  .cxFeat_a11yMobileVisibleFocus cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:focus[type=radio], .cxFeat_a11yMobileVisibleFocus cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:focus.btn-tertiary {
    outline-offset: 2px;
  }
  .cxFeat_a11yMobileVisibleFocus cx-close-account-modal .cx-close-account-modal .cx-close-account-modal-container .cx-close-account-modal-footer button:focus > cx-media {
    transition: none;
    padding: 2px;
  }
}

cx-register form a {
  text-decoration: underline;
}
cx-register form .cx-login-link {
  margin: 1rem 0 0;
}
cx-register form .input-hint {
  font-size: 0.875rem;
  color: var(--cx-color-secondary);
  margin-bottom: 0;
}

cx-my-account-v2-email .flex-line {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
cx-my-account-v2-email .headertext {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-bottom: 1.5rem;
  width: 95%;
}
cx-my-account-v2-email .editButton {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  color: var(--cx-color-primary);
  border: none;
  background-color: var(--cx-color-transparent);
}
cx-my-account-v2-email .text-head {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  min-width: 7.5rem;
  color: var(--cx-color-background-dark);
  width: 15%;
}
cx-my-account-v2-email .value {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
cx-my-account-v2-email .button {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  padding-top: 0.8rem;
  padding-inline-end: 1.5rem;
  padding-bottom: 0.8rem;
  padding-inline-start: 1.5rem;
}
cx-my-account-v2-email .button-cancel {
  color: var(--cx-color-primary);
}
cx-my-account-v2-email .email-editing-area {
  width: 100%;
  max-width: 35rem;
}
cx-my-account-v2-email .cx-message-info {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  border-color: var(--cx-color-visual-focus);
  background-color: var(--cx-color-info-accent);
}
cx-my-account-v2-email .cx-message-info .cx-message-icon cx-icon {
  color: var(--cx-color-primary-accent);
}
cx-my-account-v2-email .btn-group {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}
cx-my-account-v2-email form label {
  padding-bottom: 0.75rem;
}
cx-my-account-v2-email label {
  padding-bottom: 0.3rem;
}

cx-my-account-v2-profile .flex-line {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
cx-my-account-v2-profile .headertext {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-bottom: 1.5rem;
  width: 95%;
}
cx-my-account-v2-profile .editButton {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  color: var(--cx-color-primary);
  border: none;
  background-color: var(--cx-color-transparent);
}
cx-my-account-v2-profile .text-head {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  min-width: 7.5rem;
  width: 15%;
}
cx-my-account-v2-profile .value {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
cx-my-account-v2-profile .button {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  padding-top: 0.8rem;
  padding-inline-end: 1.5rem;
  padding-bottom: 0.8rem;
  padding-inline-start: 1.5rem;
}
cx-my-account-v2-profile .button-cancel {
  color: var(--cx-color-primary);
}
cx-my-account-v2-profile .myaccount-editing-area {
  width: 100%;
  max-width: 35rem;
}
cx-my-account-v2-profile .btn-group {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}
cx-my-account-v2-profile .ng-select .ng-select-container {
  background-color: var(--cx-color-background);
}
cx-my-account-v2-profile form label {
  padding-bottom: 0.75rem;
}
cx-my-account-v2-profile label {
  padding-bottom: 0.3rem;
}

@media (min-width: 575px) {
  cx-my-account-v2-password {
    --cx-max-width: 50%;
  }
}
cx-my-account-v2-password .myaccount-password-header {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
  padding-bottom: 1.5rem;
}
cx-my-account-v2-password .myaccount-password-button {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  padding-top: 0.8rem;
  padding-inline-end: 1.5rem;
  padding-bottom: 0.8rem;
  padding-inline-start: 1.5rem;
}
cx-my-account-v2-password .myaccount-password-button-cancel {
  color: var(--cx-color-primary);
}
cx-my-account-v2-password .myaccount-password-label {
  font-size: var(--cx-font-size, 1rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.6);
  min-width: 7.5rem;
  padding-top: 0;
  padding-inline-end: 1.2rem;
  padding-bottom: 0;
  padding-inline-start: 0;
}
cx-my-account-v2-password .myaccount-label-padding {
  padding-bottom: 0.75rem;
}
cx-my-account-v2-password .password-btn-group {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}
cx-my-account-v2-password .cx-message-info {
  font-size: var(--cx-font-size, 0.875rem);
  font-weight: var(--cx-font-weight-normal);
  line-height: var(--cx-line-height, 1.2222222222);
  border-color: var(--cx-color-visual-focus);
  background-color: var(--cx-color-info-accent);
}
cx-my-account-v2-password .cx-message-info .cx-message-icon cx-icon {
  color: var(--cx-color-primary-accent);
}

cx-address-book .cx-address-deck {
  padding: 1.25rem 0 0 0;
}
cx-address-book .cx-address-deck-spinner {
  padding: 5rem 0 5rem 0;
}
cx-address-book .cx-form {
  padding-top: 30px;
}
cx-address-book .cx-section-msg {
  margin-bottom: 1.5rem;
}
cx-address-book .cx-address-card {
  padding-bottom: 30px;
}

cx-address-form .cx-address-form-btns {
  padding: 1rem 0;
  justify-content: center;
}
@media (max-width: 991.98px) {
  cx-address-form .cx-address-form-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767.98px) {
  cx-address-form .cx-address-form-btns {
    padding: 1.25rem 0;
  }
}
@media (max-width: 991.98px) {
  cx-address-form .cx-address-form-btns .btn-secondary {
    margin: 0 0 1rem;
  }
}

cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-header {
  display: flex;
  justify-content: space-between;
}
cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-header .cx-suggested-addresses-title {
  font-size: var(--cx-font-size, 1.375rem);
  font-weight: var(--cx-font-weight-semi);
  line-height: var(--cx-line-height, 1.2222222222);
}
cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons:focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
.cxFeat_a11yImproveContrast cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons:focus {
  outline-offset: 2px;
  box-shadow: 0 0 0 2px var(--cx-color-inverse);
}

.cxFeat_a11yVisibleFocusOverflows cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons:focus {
  z-index: 1000;
}

@media (max-width: 991.98px) {
  .cxFeat_a11yMobileVisibleFocus cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons:focus {
    outline-offset: -2px;
  }
  .cxFeat_a11yMobileVisibleFocus cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons:focus .ng-select-container {
    position: initial;
  }
  .cxFeat_a11yMobileVisibleFocus cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons:focus[type=radio], .cxFeat_a11yMobileVisibleFocus cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons:focus.btn-tertiary {
    outline-offset: 2px;
  }
  .cxFeat_a11yMobileVisibleFocus cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons:focus > cx-media {
    transition: none;
    padding: 2px;
  }
}

@media (min-width: 768px) {
  cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons {
    flex: 0 0 calc(50% - 0.5rem);
  }
  cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons:last-child {
    margin-inline-start: 1rem;
  }
}
@media (max-width: 767.98px) {
  cx-suggested-addresses-dialog .cx-suggested-addresses-dialog .cx-suggested-addresses-container .cx-suggested-addresses-footer .cx-dialog-buttons {
    width: 100%;
    margin-bottom: 0.625rem;
  }
}

.user-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
@media (min-width: 768px) {
  .user-form {
    width: 50%;
    --cx-max-width: 50%;
  }
}
.user-form form {
  display: contents;
}
.user-form form label {
  all: unset;
  flex: 100%;
  margin-bottom: 0.375rem;
}
.user-form form .input-hint {
  font-size: 0.875rem;
  color: var(--cx-color-secondary);
  margin-bottom: 0;
}
.user-form button,
.user-form a {
  flex: 0 0 calc(50% - 10px);
  margin-top: 0.5rem;
}