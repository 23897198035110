$default-manufacturers-manufacturer-group-tab-border: 1px solid $color-grey-light;
$default-manufacturers-active-manufacturer-group-tab-border: 6px solid $color-blue-light;

app-default-manufacturers{
  .manufacturer-title{
    margin: auto;
    width: 60%;
  }
  @include media-breakpoint-down(sm) {
    .manufacturer-title{
      margin: auto;
      width: 90%;
    }
    .manufacturer-btn-box{
      button{
        width: 75% !important;
      }
    }
  }
  .manufactures-box{
    margin-left: 10%;

    .manufactures-group-box {
      margin-bottom: 30px;

      .manufacturer-group-tab {
        padding-left: 0;
        padding-right: 0;

        .btn-tab {
          border: 0;
          border-radius: 0;
          border-bottom: $default-manufacturers-manufacturer-group-tab-border;
          background-color: $color-white;
          font-weight: $FONT-WEIGHT-REGULAR;
          font-size: 14px;
          color: $color-black;
  
          &.btn-tab-active {
            border-bottom: $default-manufacturers-active-manufacturer-group-tab-border;
            font-weight: $FONT-WEIGHT-SEMI-BOLD;
          }
        }
      }
    }
  }

  .manufacturer-btn-box{
    margin-top: 3rem;
    display: flex;
    justify-content: center;
  }

  .form-check {
    display: flex;
    &.check-item {
      input[type=checkbox] {
        position: relative;
        margin-top: 0;
        margin-inline-start: 0;
        min-width: 22px;
      }
    }

    .check-item-text {
      margin-left: 12px;
    }
  }
}
