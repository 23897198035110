/* ==========================================================================
   # BREADCRUMB COMPONENTS
   ========================================================================== */

$breadcrumb-bg-color:     $color-grey-lightest !default;
$breadcrumb-text-color:   $color-grey-dark-semi !default;
app-efa-breadcrumb {
  @extend %cx-breadcrumb;
  background-color: $breadcrumb-bg-color;

  .crumb-label{
    span {
      font-size: 12px;
      font-weight: 400;
      color: $breadcrumb-text-color;
      margin-bottom: 5px;
    }

    .crumb-label-divider {
      &::after, &::before {
        content: ' ';
      }
    }
  }
  h1 {
    font-size: 36px;
    text-transform: none;
  }
  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}
