/* ==========================================================================
   # TRANSPORT DAMAGE COMPONENT
   ========================================================================== */

app-transport-damage-formular {
  .required .label-content::after {
    content: "*";
    color: black;
  }

  input[type=radio] {
    margin-top: 0.1rem;
  }

  .cx-page-section {
    counter-reset: list-counter 0;
    padding-top: 0;
  }

  .transport-damage_fieldset {
    position: relative;
    margin-bottom: $spacing-huge;
  }

  .transport-damage_fieldset-legend {
    counter-increment: list-counter 1;

    border-top: 1px solid $color-grey-light;
    padding-top: 0.75rem;
    margin-bottom: 0;

    font-size: 22px;
    font-weight: 700;
    line-height: 1.4em;
    display: flex;
    white-space: pre-wrap;

    &.numbered::before {
      content: counter(list-counter) ". ";
    }
  }

  .transport-damage_fieldset-content {
    padding-block: $spacing-medium $spacing-tiny;
  }

  .small {
    font-size: 12px;
  }

  .btn-hover-transform.btn-link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
