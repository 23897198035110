/* ==========================================================================
   # ORDER HISTORY COMPONENT
   ========================================================================== */

$order-history-spacing-base: $spacing-base !default;

app-efa-order-history {
  @extend %cx-order-history;

  .cx-order-history-header {
    padding-top: 0;

    h2 {
      margin-bottom: $order-history-spacing-base;
    }
  }

  .cx-order-history-body {
    h3 {
      margin-bottom: $order-history-spacing-base;
    }
  }

  .cx-order-history-form-group {
    align-items: flex-start;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      padding: 1.25rem 0;
    }
  }

  .cx-order-history-pagination {
    @include media-breakpoint-up(md) {
      padding-right: 0;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .cx-order-history-sort.top .cx-order-history-pagination {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .cx-order-history-table {
    th {
      padding: 0.75rem;
    }
    th:nth-child(3),th:nth-child(6),th:nth-child(8){
      @include media-breakpoint-up(lg) {
        width: 15%;
      }
    }
    td {
      padding: 0.75rem;
      @include media-breakpoint-up(lg) {
        width: 12%;
      }
    }
    th:nth-child(1){
      @include media-breakpoint-up(lg) {
        width: 10%;
      }
    }
    td.cx-order-history-code{
      @include media-breakpoint-up(lg) {
        width: 10%;
      }
      a {
        text-decoration: underline;
      }
    }
    td.c-order-history-orderType-name,
    td.c-order-history-consignment-info{
      span{
        text-wrap: wrap;
      }
    }
    td.c-order-history-orderType-name,
    td.c-order-history-consignment-info {
      .c-order-history-limited-text {
        @include media-breakpoint-up(lg) {
          max-width: 16ch;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @include media-breakpoint-down(md) {
          white-space: nowrap;
          display: block;
          max-width: 25ch;
          overflow: hidden;
          text-overflow: ellipsis;
          float: right;
        }

        @include media-breakpoint-down(sm) {
          max-width: 12ch;
        }
      }
    }

  }
  cx-pagination{
    a {
      font-weight: 600 ;
    }
  }

  @include media-breakpoint-down(md) {
    .cx-order-history-table {
      border: 1px solid transparent;
      box-shadow: none;

      thead {
        display: none;
      }
      tbody tr{
        border-bottom: 1px solid transparent;
      }
      tbody tr td {
        @include responsive-column;
        width: 100%;
        // padding: 12px;
        border-top: 1px solid #dee2e6;
        a{
          min-height: unset;
        }

        @include media-breakpoint-down(sm) {
          border-width: 1px 1px 0 1px;
        }
      }
      tbody tr td:last-child{
        margin-bottom: 25px
      }
    }
  }
}

app-order-overview-filter {
  @include c-accordion;

  .btn-filter {
    cx-icon svg {
      width: 24px;
      height: 24px;
      margin-bottom: 4px;
    }

    .btn-filter-text {
      font-size: 18px;
    }
  }

  .accordion{
    .card-header {
      h5{
        .btn[aria-expanded=false]:after{
          font-size: 35px;
        }
      }
    }
    form {
      .select-arrow{
        select {
          appearance: none;
          background-image: url(../../../assets/icons/downsellect-arrow.png);
          background-position-x: calc(100% - 10px);
          background-position-y: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

app-efa-order-sorting {
  width: 100%;
  display: relative;
  ng-select, .ng-select.ng-select-opened{
    .ng-select-container{
      background-image: url(../../../assets/icons/arrow-expand-down.svg);
      background-position-x: 195px;
      background-position-y: 11px;;
      background-repeat: no-repeat;
      border: none !important;
      box-shadow: none !important;
      .ng-placeholder{
        color:var(--cx-color-primary) !important;
        display: block !important;
      }
      .ng-value {
        display: none !important;
      }
      span.ng-arrow-wrapper{
        display: none !important;
      }
      &:hover{
        box-shadow: none !important;
        border: none !important;
      }
    }
  }
  ng-select.ng-select-focused{
    outline: none !important;
  }
  ng-dropdown-panel{
    width: 100%;
  }
}

app-no-display-result-notice {
  h2 {
    margin-bottom: 30px;
  }
}
