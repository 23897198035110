/* ==========================================================================
   # CART ITEM, SUB ENTRIES AND ODER INFO FIELD COMPONENTS
   ========================================================================== */

$cart-item-spacing-small: $spacing-small;
$cart-media-size: 100px;

app-efa-cart-item,
app-cart-sub-entry,
app-order-info-fields {
  @extend %cx-cart-item;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

app-efa-cart-item {
  vertical-align: top;

  cx-media.is-missing {
    width: $cart-media-size;
    height: $cart-media-size;
  }

  cx-item-counter.readonly input {
    background-color: transparent;
    color: var(--cx-color-text);
  }

  .article-info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include media-breakpoint-up(lg) {
      max-width: 250px;
    }

    color: var(--cx-color-text);
  }

  .input-block {
    display: flex;
  }

  .exchange-tax-required {
    font-weight: $FONT-WEIGHT-BOLD;
    font-size: 20px;
  }
}

app-cart-sub-entry {
  @include media-breakpoint-up(lg) {
    td {
      border-top: 0 !important;
      padding-top: 0 !important;
      
      &.align-center {
        text-align: center;
      }
    }
  }
}

app-efa-cart-item + app-cart-sub-entry,
app-efa-cart-item + app-order-info-fields {
  @include media-breakpoint-down(md) {
    position: relative;
    top: -10px;
  }
}

app-order-info-fields {
  .c-form-group-inline {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0;

    .label-value {
      margin-left: $cart-item-spacing-small;
      margin-bottom: $label-margin-bottom;
    }
  }

  .table-col {
    border-top: 0;
  }

  @include media-breakpoint-down(md) {
    .c-form-group-inline {
      margin-bottom: 0;
      justify-content: space-between;

      .label-content,
      .form-control {
        flex: 0 1 auto;
      }
    }

    .table-col:first-child {
      display: none;
    }
  }
}
