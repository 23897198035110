/* ==========================================================================
   # BANNER CAROUSEL COMPONENTS
   [1] This class (.visually-hidden) should be removed once we upgrade to bootstrap 5.
   [2] Custom style classes for banner carousel to be configured in the backend.
   ========================================================================== */

$default-banner-carousel-indicator-background-color: $color-grey-light-super;
$default-banner-carousel-active-indicator-background-color: $color-grey-dark-semi-second-degree;

%app-efa-banner-carousel {
  ngb-carousel {

    // [1]
    .visually-hidden {
      display: none;
    }

    &.carousel {
      &.slide {
        display: flex !important;

        &:focus {
          outline: none;
        }

        .carousel-control-next,
        .carousel-control-prev {
          width: 10%;

          @include media-breakpoint-down(md) {
            width: 15%;
          }
        }

        .carousel-control-prev {
          .carousel-control-prev-icon {
            background-image: url(../../../assets/icons/mini-caret-left.svg);
            filter: invert(100%);
          }
        }

        .carousel-control-next {
          .carousel-control-next-icon {
            background-image: url(../../../assets/icons/mini-caret-right.svg);
            filter: invert(100%)
          }
        }

        .carousel-indicators {
          display: flex;
          gap: 5px;
          margin-bottom: 10px;

          button {
            display: block;
            width: 0.8rem;
            height: 0.8rem;
            border: none;
            border-radius: 50%;
            background-color: $default-banner-carousel-indicator-background-color;

            &.active {
              background: $default-banner-carousel-active-indicator-background-color;
            }
          }
        }
      }
    }
  }
}

// [2]
app-efa-banner-carousel {
  @extend %app-efa-banner-carousel;

  margin-bottom: 20px;

  &.slider-banner-home-page {
    @extend .col-12;
    @extend .col-md-10;

    ngb-carousel {
      &.carousel {
        &.slide {

          .carousel-control-next,
          .carousel-control-prev {
            width: 5%;
          }

          .carousel-inner {
            margin-inline-start: 0;
            margin-inline-end: 0;
          }
        }
      }
    }
  }
}
