/* ==========================================================================
   # RESPONSIVE TABLE
   ========================================================================== */

$table-border: 1px solid #dee2e6;
$table-spacing-small: $spacing-small;
$table-min-height-column: 35px;
.table td, .table th {
  padding: .75rem;
}
@mixin responsive-column() {
  display: block;
  text-align: right;
  font-size: 1em;
  min-height: $table-min-height-column;
  border-left: $table-border;
  border-right: $table-border;
  border-top:$table-border ;

  &::before {
    content: attr(data-label);
    float: left;
    //text-transform: uppercase;
    font-weight: bold;
    font-size: 0.85em;
    width: auto;
  }

  &:last-child {
    border-bottom: $table-border;
    margin-bottom: $table-spacing-small;
  }
}
