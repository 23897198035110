/* ==========================================================================
   # SELECT MODEL
   // [1] Customized angular select.
   ========================================================================== */
@mixin c-select-model() {
  // [1]
  ng-select {
    &.ng-select {
       .ng-arrow-wrapper {
        cursor: default;
       }

       .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
          cursor: default;
          padding: 0px 0px 0px 5px;
       }

       .ng-clear-wrapper {
          display: none;
       }
    }
  
    &.ng-select-focused {
      &:not(.ng-select-opened){
          >.ng-select-container {
              box-shadow: none;
          }
      }
    }
    
    .ng-dropdown-panel {
      width: auto;
      border-radius: 5px 5px 5px 5px;

      &.ng-select-bottom {
        margin-top: 2px;
      }
      
      &.ng-select-top {
        margin-bottom: 2px;
      }

      .ng-dropdown-panel-items {
        border-style: solid;
        border-color: $color-grey-light;
        border-radius: inherit;
        border-width: 1.5px;
      }
    }
  }
}