/* We use these imports instead of those from the spartacus @import "@spartacus/cart" to avoid double imports of spartacus style */

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/_mixins';
@import '@spartacus/styles/scss/mixins';
@import '@spartacus/cart/index';
@import '@spartacus/styles/scss/cxbase/blocks/modal';

@mixin visible-focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}
