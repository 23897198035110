@import "@spartacus/organization";

cx-org-list {
    .list {
        .header {
            .title {
                h3 {
                    text-transform: capitalize;
                }
            }

            .actions {
                a {
                    text-transform: capitalize;
                }
            }
        }
    }

    cx-table {
        th {
            padding-inline-start: 0%;
        }
    }
}

cx-org-form {
    .required .label-content::after {
      content: "*";
      color: black;
    }
}

app-efa-manage-users-list,
app-efa-manage-user-groups-list {
  @extend %buttons !optional;
  @extend %organizationMessage !optional;

  @extend %organizationList !optional;
  @extend %organizationCard !optional;
  @extend %organizationItemDetails !optional;
  @extend %organizationItemForm !optional;
    // @extend cx-org-list;

}
app-efa-user-group-assigned-permission-list,
app-efa-user-group-permission-list,
app-efa-user-group-assigned-user-list,
app-efa-user-group-user-list,
app-efa-user-assigned-user-group-list,
app-efa-user-user-group-list{
  app-efa-org-card{
    .header{

    }
    .main{
      section{
        border: none;
        margin: 0px 30px;
        padding-bottom:2.5rem ;
        cx-table{
          table{
            thead{
              display: none;
            }
            tr{
              border-bottom: 1px solid #B8BCC3;
              td.code{
                app-efa-org-permission-details-cell{
                  button{
                    margin: 0px;
                    &:focus{
                      outline: none;
                    }
                  }
                  button.text{
                    color: #000000;
                  }
                  button.info-btn{
                    width: 30px;
                    height: 30px;
                    background-color: transparent;
                    border: none;
                    background-image: url(../../assets/icons/infoCircleOutline.svg);
                    background-position-x: 1px;
                    background-position-y: 8px;;
                    background-repeat: no-repeat;
                  }
                }
              }
              td.name{
                app-efa-user-group-details-cell{
                  button{
                    margin: 0px;
                    &:focus{
                      outline: none;
                    }
                  }
                  button.text{
                    color: #000000;
                  }
                  button.info-btn{
                    width: 30px;
                    height: 30px;
                    background-color: transparent;
                    border: none;
                    background-image: url(../../assets/icons/infoCircleOutline.svg);
                    background-position-x: 1px;
                    background-position-y: 8px;;
                    background-repeat: no-repeat;
                  }
                }
              }

              td.name{
                app-efa-org-user-details-cell{
                  button{
                    margin: 0px;
                    &:focus{
                      outline: none;
                    }
                  }
                  button.text{
                    color: #000000;
                  }
                  button.info-btn{
                    width: 30px;
                    height: 30px;
                    background-color: transparent;
                    border: none;
                    background-image: url(../../assets/icons/infoCircleOutline.svg);
                    background-position-x: 1px;
                    background-position-y: 8px;;
                    background-repeat: no-repeat;
                  }
                }
              }
              td.actions{
                width: 20%;
                button{
                  justify-content: right;

                  &:focus{
                    outline: none;
                    box-shadow: none !important;
                  }
                }

              }
            }
          }
        }
      }
    }

  }
}
app-efa-user-group-permission-list,
app-efa-user-group-user-list,
app-efa-user-user-group-list{
  app-efa-sub-list{
    app-efa-org-card{

      .main{
        .subtitle-and-actions{
          .action{
            a {
              background-color: #003899;
              color: white !important;
              font-weight: 600 !important;
            }
          }
        }
      }
    }
  }
}
app-efa-user-group-user-list{
  app-efa-sub-list{
    app-efa-org-card{

      .main{
        .subtitle-and-actions{
          .action{
            button{
              display: none;
            }
          }
        }
      }
    }
  }
}

app-efa-org-assign-cell{
  display: flex;
  justify-content: end;
  .form-check.check-item{
    margin-bottom: 30px;
    display: flex;
    input{
      position: relative;
      right: -25px;
      top: 14px;
    }
  }
}


app-efa-user-group-details,
app-efa-org-user-details{
  app-efa-org-card {
    cx-view {
        border: none !important;
        margin-bottom: 40px;
        .header {
          padding: 5px !important;
          height: 50px !important;
          margin-bottom: 40px;
            .title-bar {
                .title {
                    h3 {
                        text-transform: none;
                    }
                }
            }
        }
        .main{

          padding: 10px;
          .main-container{
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
          }
          .subtitle-and-actions{
            display: flex;
            align-items: center;
            justify-content: start;
            border-bottom: 1px solid #B8BCC3;
            padding: 20px 0px;
            margin: 0px 30px;
            .subtitle{
              font-size: 22px;
              font-weight: 700;
            }
            .action,.action-cancel{
              margin-left: auto;
              .close{
                opacity: 1 !important;
              }
              a{
                color: #003899 ;
                border: 1px solid #003899;
                font-weight: 600;
                border-radius: 30px;
                padding: 10px 15px;
              }
              a.link{
                line-height: 1rem;
              }
              a.link:focus{
                outline: none !important;
                box-shadow: none !important;
              }
            }
            .action{
              a{
                border: 1px solid transparent;
                font-size: 14px;
                font-weight: 700;
              }

            }
            .action-cancel{
              margin-left: 5px;
            }
            .action:focus{
              outline: none !important;
            }
          }
          .details{
            margin: 0px;
            border: none;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #B8BCC3;
            padding: 0px;
            margin: 30px;
          }
          .link-list{
            margin: 0px;
            border: none;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 0px 10px 30px 10px;
            a{
              color: #003899;
              border: 1px solid #003899 !important;
              border-radius: 30px;
              padding: 10px 15px !important;
              width: 40%;
              min-height: 0px;
              justify-content: center;
            }
            a.link{
              line-height: 1rem;
            }
            a.link:focus{
              outline: none !important;
              box-shadow: none !important;
            }
          }
        }
    }
  }
}

app-efa-org-user-details{
  app-efa-org-card {
    cx-view {
      .main{
        .subtitle-and-actions{
          .action{
            display: flex;
            a.link.edit{
              padding-top: 15px;
            }
          }
        }
        .details{
          div{
            .noProfilesAssigned{
              margin-bottom: 0px;
            }
          }
         div:last-child{
          margin-bottom: 0px;
          a{
            color: #003899;
            border: 1px solid #003899 !important;
            border-radius: 30px;
            padding: 10px 15px !important;
            width: 40%;
            min-height: 0px;
            justify-content: center;
          }
          a.link:focus{
            outline: none !important;
            box-shadow: none !important;
          }
         }
        }
        .link-list{
          a{
            padding: 15px 15px !important;
            width: 90%;
          }
        }
      }
    }
  }
}

app-efa-org-user-form{
  display: flex !important;
  flex-direction: column;
  // display: block !important;
  .top-title{
    font-size: 22px;
    font-weight: 700;
    width: 100%;
    margin-bottom: 80px;
    border-bottom: 1px solid #B8BCC3;
    h3{
      margin-bottom: 20px;
    }
  }
  cx-org-form{
    cx-org-card{
      display: block !important;
      padding: 10px;
      cx-view:not([position="0"]) {
        border: none;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        @include media-breakpoint-up(lg) {
          width: 95%;
        }
        .header{
          padding: 0px;
          margin: 0px 30px;
          height: 70px;
          .title-bar{
            .title{
              h3{
                text-transform: none;
                font-size: 22px;
                font-weight: 700;
              }
            }
            .actions{
              flex-direction: row-reverse;
              button{
                border-radius: 30px;
                text-transform: none;
                padding: 11px 16px 11px 16px;
                border: 1px solid #003899;

              }
              button:focus{
                outline: none;
              }
            }
          }
        }
        .main{
          section{
            border: none;
            margin: 0px;
            padding: 30px 10px 30px 30px;
            label{
              .label-content,.form-check{
                font-size: 16px;
                font-weight: 400;
              }

            }
          }
        }
      }
    }
  }
}
