app-homepage-greeting .homepage-greeting {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  background: $color-grey-lightest;
}

app-efa-banner {
  margin-bottom: 4rem;
}
