/* ==========================================================================
   # CART EXPORT DIALOG COMPONENTS
   ========================================================================== */

app-cart-export-dialog {
  .cx-modal-container {
    .cx-modal-content {
      @include media-breakpoint-up(md) {
        max-width: 500px;
      }    
    }

    justify-content: center;
  }
  
  .modal-body{
    height: 300px !important;
    padding: 27px 30px;
  }
  .filename-info-icon {
    padding-inline-end: 5px;
  }

  .required .label-content::after {
    content: "*";
    color: black;
  }
}
