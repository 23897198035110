/* ==========================================================================
   # REVIEW SUBMIT COMPONENTS
   ========================================================================== */

$skipComponentStyles: (cx-review-submit);

$review-bg-color: $color-grey-lightest !default;
$review-spacing-medium: $spacing-medium !default;

app-efa-order-overview,
app-efa-review-submit {
  .c-review__title,
  .cx-page-title {
    margin-bottom: 24px;
    font-weight: 700;
  }

  .c-review__cart-total {
    display: flex;
    justify-content: space-between;
  }

  .c-review__cart-total {
    @include type('4');
    margin-top: 2rem;
  }

  .c-review__summary-card,
  .c-review__place-order,
  .c-review__cart-item {
    padding-right: 0;
    padding-left: 0;

    &--row {
      cx-card .cx-card-body {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        .cx-card-title{
          font-size: 1.125rem;
          font-weight: var(--cx-font-weight-bold);
        }
      }
    }

    cx-icon {
      font-size: 22px;
    }
  }

  .c-review__summary-card,
  .c-review__place-order {
    margin-bottom: $review-spacing-medium;
    .cx-card-title{
      font-size: 1.125rem;
      font-weight: var(--cx-font-weight-bold);
      display: inline-block;
      margin-bottom: .5rem;
    }
  }

  .c-review__summary-card.c-review__summary-card-mandatory-hint {
    margin-top: 15px;
    font-size: $small-font-size;
    color: red;
    float: right;
  }

  cx-card .cx-card-body {
    padding: 0;
  }

  .col-md-12 {
    @include media-breakpoint-down(md) {
      padding: 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
  .c-mandatory-text
  {
    font-size: var(--cx-font-small, 0.8rem);
  }

  .c-configurator-order-tax-notice-container {
    margin-bottom: 40px;

    .c-configurator-order-tax-notice {
      font-size: 0.75rem;
    }
  }
}
