app-cart-calculate-mode {
  .custom-switch {
    display: inline-block;
    align-items: center;
    margin-top: -6px;
    padding-top: 0.875rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 3.75rem;

    .custom-control-input:checked {
      ~ .custom-control-label::before {
        background-color: $primary;
      }

      ~ .custom-control-label::after {
        transform: translateX(calc(1.5rem));
      }
    }

    .custom-control-label {
      line-height: 2rem;
      font-size: 1rem;
      font-weight: 400;
      color: $primary;

      &::before {
        width: 3rem;
        height: 1.5rem;

        left: unset;
        right: -3.75rem;
        border-radius: 30px;
        background-color: $color-grey-light;
      }

      &::after {
        width: calc(1.5rem - 4px);
        height: calc(1.5rem - 4px);
        left: unset;
        right: calc(-2.5rem + 6px);
        border-radius: 50%;
        background-color: $white;
      }
    }
  }
}
