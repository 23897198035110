/* ==========================================================================
   # HEADER COMPONENTS
   ========================================================================== */

header {
  @extend %header;
  background: none;

  .header {
    .SiteLinks {
      font-weight: inherit;
    }

    .SearchBox {
      @include media-breakpoint-up(lg) {
        margin: 0px 0px 14px 110px;
      }
    }
  }

  &.is-expanded {
    @include media-breakpoint-down(md) {
      .header {
        box-shadow: inset 0px -3px 0px 0px $color-black-light;
      }
    }
  }
}

app-efa-category-navigation {
  @extend %cx-category-navigation;

  app-category-navigation-ui {
    @extend %cx-navigation-ui;
    @extend cx-navigation-ui;

    background-color: transparent;

    > nav > ul {
      @include media-breakpoint-up(lg) {
        padding-left: 10px;
      }

      flex-wrap: nowrap;

      > li {
        padding-right: 20px;
        padding-inline-end: 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        @include media-breakpoint-down(md) {
          align-items: center;
        }

        > cx-generic-link {
          text-transform: none;

          > a {
            @include media-breakpoint-down(md) {
              padding-top: 12px !important;
              padding-bottom: 12px !important;
            }
          }
        }

        > span {
          &.opened-node-title {
            display: flex !important;
            justify-content: flex-start;
            color: var(--cx-color-primary);
            font-size: 22px;
            font-weight: $FONT-WEIGHT-BOLD;
            padding-top: 0;
            padding-right: 0;
            box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
            border-bottom: 0;
            text-transform: none;

            cx-icon {
              &.opened-node-back-icon {
                svg {
                  margin-right: 10px;
                }
              }
            }
          }
        }

        &:has(button) {
          > cx-generic-link {
            > a {
              padding-right: 0;
            }
          }

          > button {
            cx-icon {
              padding-left: 0;

              @include media-breakpoint-down(md) {
                svg {
                  color: var(--cx-color-primary);
                }
              }
            }

            @include media-breakpoint-down(md) {
              border: 0 !important;
              padding-top: 12px !important;
              padding-bottom: 12px !important;
            }
          }

          &:hover {
            button {
              + .wrapper {
                margin-inline-start: 0;
              }

              &:hover {
                cx-icon {
                  svg {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }

        div {
          &.wrapper {
            ul {
              &.childs {
                li {
                  cx-generic-link {
                    a {
                      color: $color-blue;
                      font-weight: $FONT-WEIGHT-BOLD;
                      font-size: 16px;

                      @include media-breakpoint-down(md) {
                        font-size: 22px;
                        padding-top: 12px;
                        padding-bottom: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.flyout {
      &.is-open {
        > nav {
          > ul {
            > li {
              &.is-open {
                background-color: transparent !important;
              }
            }
          }
        }
      }
    }

    cx-icon {
      &.small {
        svg {
          height: 16px !important;
          width: 16px !important;
        }
      }
    }
  }
}

app-b2b-unit-summary {
  margin-right: 3rem;

  display: flex;
  align-items: center;
  color: $color-black;

  p {
    margin-bottom: 0px;
  }
}
