/* ==========================================================================
   # PRODUCT DETAILS COMPONENTS
   ========================================================================== */

$pd-spacing-medium: $spacing-medium !default;

app-efa-product-images{
  @extend %cx-product-images;
  grid-column: 1;
  grid-row: 1 / span 6;

  lib-ngx-image-zoom{
    cursor: pointer;
    .ngxImageZoomFullContainer{
      img{
        background-color: white;
      }
    }
  }

  .back-nav{
    color: #003899;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 40px;
    margin-top: -25px;
    &:hover{
      cursor: pointer;
    }
    cx-icon{
      svg{
        padding-top: 4px;
        width: 20px;
      }
    }
  }
}

app-efa-product-intro{
  margin-top: 40px;
  margin-bottom: 1.5rem;
}

app-efa-product-summary {
  @extend %cx-product-summary;
  padding: 0px;
  .price{
    font-size: 36px;
    font-weight: 700;
    border-bottom: 1px solid #B8BCC3;
    padding-bottom: 0.5rem;
    margin-bottom: 4rem;

    .discounted-price {
      color: $color-red-light-semi;
    }
    
    .crossed-price-wrapper {
      margin-left: 0.5rem;
      font-size: 28px;
      font-weight: $FONT-WEIGHT-REGULAR;
      .crossed-price {
        text-decoration: line-through;
        text-decoration-thickness: 10%;
      }

      .crossed-price-text {
        margin-right: 0.25rem;
      }
    }
  }

  .no-price{
    border-bottom: 1px solid #B8BCC3;
    padding-bottom: 1.5rem;
    margin-bottom: 4rem;
  }

  .action-box{
    display: flex;
    align-items: end;
    margin-bottom: 4rem;
    app-efa-add-to-cart{
      width: 90%;
    }
    app-efa-cart-item-add-to-wish-list{
      margin-left: 10px;
      padding: 10px 6px 6px 10px;
      border: 1px solid #003899;
      border-radius: 50%;
    }
  }
  .summary-box{
    p.summary-title{
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      font-size: 22px;
      font-weight: 700;
      color: #003899;
      border-bottom: 1px solid #B8BCC3;
    }
  }
  .delivery-time-box{
    p.delivery-time-title{
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      font-size: 22px;
      font-weight: 700;
      color: #003899;
      border-bottom: 1px solid #B8BCC3;
    }
  }

  &,
  cx-product-summary {
    padding-left: 0;
    padding-right: 0;
  }

  .c-product-delivery-time {
    padding-top: $pd-spacing-medium;
    padding-bottom: $pd-spacing-medium;
  }

  app-efa-product-details-tab{
    .description-box{
      p.description-title{
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        font-size: 22px;
        font-weight: 700;
        color: #003899;
        border-bottom: 1px solid #B8BCC3;
      }
    }
  }
}

