/* ==========================================================================
   # CORE SETTINGS
   ========================================================================== */

$spacing-wrapper:                   50px;
$spacing-huge:                      40px;
$spacing-large:                     25px;
$spacing-medium:                    20px;
$spacing-base:                      15px;
$spacing-small:                     10px;
$spacing-tiny:                      5px;
