/* ==========================================================================
   # FONT-FACE
   ========================================================================== */

$FONT-PATH:                 "../../../fonts" !default;

@mixin font-face($font-family, $font-name, $font-path: $FONT-PATH, $font-face: null, $font-weight: normal, $font-style: normal) {
  @font-face {
    font-family: #{$font-family};
    font-style: $font-style;
    font-weight: $font-weight;
    src: url('#{$font-path}/#{$font-name}/#{$font-name}-#{$font-face}.woff') format('woff'),
         url('#{$font-path}/#{$font-name}/#{$font-name}-#{$font-face}.woff2') format('woff2'),
         url('#{$font-path}/#{$font-name}/#{$font-name}-#{$font-face}.ttf') format('truetype'),
         url('#{$font-path}/#{$font-name}/#{$font-name}-#{$font-face}.otf') format("opentype");
  }
}
