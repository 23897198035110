/* ==========================================================================
   # ORDER TYPE SELECTION COMPONENT
   ========================================================================== */

.HeaderLinks {
  flex-wrap: unset;

  cx-navigation-ui.flyout {
    > nav > h5 {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    > nav > ul > li {
      > button {
        padding: 0;
        line-height: 1.2222222222;
        font-weight: 900;
      }
      
      cx-icon {
        padding-inline-start: 0;
      }
    }
  }
}

.order-type-selection {
  @include media-breakpoint-down(md) {
    width: 35%;
    margin-top: $spacing-small;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
