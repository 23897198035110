app-order-info-fields {
  .required .label-content::after {
    content: "*:";
    color: black;
  }
  .optional .label-content {
    margin-right: 0.5rem;
    &::after {
      content: ":";
      color: black;
    }
  }

  .input-block {
    display: flex;
  }
}
