app-efa-manage-user-groups-list {
  @extend %cx-table !optional;
    .list .header {
        height: auto;
        border-bottom: 1px solid #B8BCC3;
        min-height: 0px !important;
        padding: 0px 6px !important;
        .title {
          h3 {
              text-transform: capitalize;

          }
      }
    }
    // Start user groups list table
    .user-groups-list-table{
      cx-table{
        table{
          thead th{
            padding-inline-start: 10px;
            font-size: 12px;
            vertical-align: bottom;
          }
          thead th:first-child{
            width: 20%;
          }
          tbody{
            @include media-breakpoint-down(md) {
              background-image: url("../../../assets/icons/arrow-forward.png") !important;
              background-position: calc(100% - 10px) 97% !important;
              background-repeat: no-repeat !important;
              background-size: 24px !important;
              border: 1px solid #B8BCC3;
              margin: 2rem;
              padding-bottom: 40px;
              display: block;
              tr{
                border-bottom:1px solid #B8BCC3; ;
              }
              tr:last-child{
                margin-bottom: 2rem;
              }
            }
          }
          tr {
            @include media-breakpoint-up(lg) {
              background-image: url("../../../assets/icons/arrow-forward.png") !important;
              background-position: calc(100% - 26px) 55% !important;
              background-repeat: no-repeat !important;
              background-size: 24px !important;
              border-bottom: solid 1px var(--cx-color-light);

            }
            td{
              padding-inline-start: 10px;
            }
          }
        }
      }
    }
    // End user groups list table

    cx-split-view{
      cx-view{
        border-inline-end:none !important;
      }
    }

    cx-split-view:not([active-view='2'])  {
        .list .header {
          padding-bottom: 0px;
          height: auto;
          @include media-breakpoint-down(md) {
            margin: 0px 2rem;
            padding-left: 0px;
          }
        }
        // Start user groups list table
        .user-groups-list-table{
          cx-table{
            table{
              thead th{}
              thead th:first-child{}
              tbody{
                tr.name{
                  td cx-org-active-link-cell{
                    a {
                      justify-content: right;
                      text-decoration: none;
                    }
                  }
                }
                tr.roles{
                  td{
                    app-efa-list-cell{
                      a{
                        span{
                            text-wrap: wrap !important;
                            padding: 25px 0px 25px 0px !important;
                            text-align: right;
                        }
                      }
                    }
                  }
                }

              }
              tr {
                td.roles{
                  app-efa-list-cell{
                    a{
                      span{
                          text-wrap: wrap !important;

                          padding: 25px 25px 25px 0px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }

}
